import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IconHistory, IconSwimming } from '@tabler/icons-react';
import AddTicket from './AddTicketInterface';
import CustomInput from '../../components/custom-input';
import PermissionCheck from '../../utils/PermissionCheck';
import { SubmitButton } from '../../components/buttons';
import { getPools, updatePool } from '../../api/pools';
import useAuth from '../../hooks/useAuth';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { closePort } from '../pools/serialPortManager';
import { cn } from '../../utils/cn.ts';
import { GridPattern } from './GridPattern';

function PoolsInterfaceList() {
  const { userPermissions } = useAuth();
  const [pools, setPools] = useState({});
  const [activePoolId, setActivePoolId] = useState(null);
  const printRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          if (pool.type === 'OUTDOOR') {
            acc[pool.type] = {
              id: pool.id,
              man: pool.tickets.find((ticket) => ticket.title === 'man').price,
              woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
              kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
            };
          }
          return acc;
        }, {});
        setPools(poolsData);
        if (poolsData.OUTDOOR) {
          setActivePoolId(poolsData.OUTDOOR.id);
        }
        toast.success('Pool data loaded successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error('Failed to fetch pools data', error);
        toast.error('Failed to load pool data', {
          position: 'top-right',
          autoClose: 5000,
        });
      }
    };
    fetchPools();
  }, []);

  const handleLinkClick = async (event, url) => {
    event.preventDefault();
    await closePort();
    navigate(url);
  };

  const handlePrint = async (item) => {
    try {
      const saleData = { product: 'Ticket', price: item.totalPrice };
      console.log('Sending data to generate INP file:', saleData);
      // await axios.post('http://localhost:3001/generate-sale', saleData)
      await axios.post('https://api-staff.lakesideks.com/generate-sale', saleData);
      printRef.current.setItem(item);
      setTimeout(() => {
        printRef.current.triggerPrint();
      }, 500);
      toast.success('Ticket printed successfully!', {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error) {
      toast.error('Failed to print ticket', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  if (!pools.OUTDOOR) return null;

  const poolPrices = pools.OUTDOOR;
  const disableInputs = !userPermissions.includes('edit_outdoor_pools');

  return (
    <div className="min-h-screen bg-gradient-to-b from-tealish-blue to-seashell py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <IconSwimming size={48} className="text-hazel-green" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-2">Pool Management System</h1>
          <p className="text-lg text-bluish-grey">Manage tickets and pricing for the outdoor pool</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white rounded-2xl shadow-xl p-8 border border-grey-goose">
            <div className="flex items-center justify-between mb-8">
              <h2 className="text-2xl font-bold text-gray-900">Pool Pricing</h2>
              <PermissionCheck action="read_outdoor_pools">
                <Link 
                  to={`/pools/history/${activePoolId}`} 
                  onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}
                  className="flex items-center text-hazel-green hover:text-sage transition-colors"
                >
                  <IconHistory size={24} className="mr-2" />
                  <span>View History</span>
                </Link>
              </PermissionCheck>
            </div>

            <Formik
              initialValues={{
                man: poolPrices.man,
                woman: poolPrices.woman,
                kid: poolPrices.kid,
              }}
              validationSchema={Yup.object().shape({
                man: Yup.number().required('Price is required*'),
                woman: Yup.number().required('Price is required*'),
                kid: Yup.number().required('Price is required*'),
              })}
              onSubmit={async (values, { setErrors, setSubmitting }) => {
                try {
                  const updatedData = {
                    manTicketPrice: values.man,
                    womanTicketPrice: values.woman,
                    kidTicketPrice: values.kid,
                  };
                  await updatePool(activePoolId, updatedData);
                  toast.success('Pool pricing updated successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    icon: '🏊‍♂️',
                  });
                  setSubmitting(false);
                } catch (error) {
                  setErrors({ submit: 'Failed to update pool data' });
                  toast.error('Failed to update prices', {
                    position: 'top-right',
                    autoClose: 5000,
                    icon: '❌',
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                values,
                touched,
                isSubmitting,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <CustomInput
                    type="number"
                    name="man"
                    label="Men's Ticket Price"
                    value={values.man}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.man && touched.man}
                    errorMessage={errors.man}
                    disabled={disableInputs}
                    className="bg-seashell border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                  />
                  <CustomInput
                    type="number"
                    name="woman"
                    label="Women's Ticket Price"
                    value={values.woman}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.woman && touched.woman}
                    errorMessage={errors.woman}
                    disabled={disableInputs}
                    className="bg-seashell border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                  />
                  <CustomInput
                    type="number"
                    name="kid"
                    label="Children's Ticket Price"
                    value={values.kid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.kid && touched.kid}
                    errorMessage={errors.kid}
                    disabled={disableInputs}
                    className="bg-seashell border-grey-goose focus:ring-hazel-green focus:border-hazel-green"
                  />
                  {!disableInputs && (
                    <div className="flex justify-end">
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        width="w-full sm:w-auto"
                        title="Update Prices"
                        className="bg-hazel-green hover:bg-sage text-white transition-colors"
                      />
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>

          <div className="bg-white rounded-2xl shadow-xl p-8 border border-grey-goose">
            <h2 className="text-2xl font-bold text-gray-900 mb-8">Issue Tickets</h2>
            <AddTicket poolType="OUTDOOR" poolId={activePoolId} onClick={handlePrint} />
          </div>
        </div>
      </div>
      <TicketPrintComponent ref={printRef} />
    </div>
  );
}

const TicketPrintComponent = React.forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const printContentRef = useRef();
  const printTriggerRef = useRef();

  useImperativeHandle(ref, () => ({
    setItem: (item) => setItem(item),
    triggerPrint: () => {
      if (printTriggerRef.current) {
        printTriggerRef.current.click();
      }
    },
  }));

  if (!item) return null;

  return (
    <>
      <ReactToPrint
        trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
        content={() => printContentRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintContent ref={printContentRef} item={item} />
      </div>
    </>
  );
});

const PrintContent = React.forwardRef(({ item }, ref) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-lg p-8 max-w-xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" />
          <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
        </div>
        <div className="text-gray-700">
          <div className="font-bold text-xl mb-2">INVOICE</div>
          <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
          <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
        <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
        <div className="text-gray-700 mb-2">{user.email}</div>
        <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
        <div className="text-gray-700">{item.customerEmail}</div>
      </div>
      <table className="w-full text-left mb-6">
        <thead>
          <tr>
            <th className="text-gray-700 font-bold uppercase py-2">Description</th>
            <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
            <th className="text-gray-700 font-bold uppercase py-2">Price</th>
            <th className="text-gray-700 font-bold uppercase py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-4 text-gray-700">{item.ticketTitle}</td>
            <td className="py-4 text-gray-700">{item.count}</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mb-6">
        <div className="text-gray-700 mr-2">Total:</div>
        <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
      </div>
      <div className="border-t-2 border-gray-300 pt-6 mb-6">
        <div className="my-4 text-2xl italic text-center">THANK YOU</div>
      </div>
    </div>
  );
});

export default PoolsInterfaceList;
