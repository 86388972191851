import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import sqTranslations from './locales/sq.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: enTranslations.common,
        roomCleaning: enTranslations.roomCleaning
      },
      sq: {
        common: sqTranslations.common,
        roomCleaning: sqTranslations.roomCleaning
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'common',
    ns: ['common', 'roomCleaning'],
  });

export default i18n;
