import React, { useState, useEffect } from 'react';
import { createTask, getCleaners } from '../../../../../api/task';
import { toast } from 'react-toastify';

interface Role {
  title: string;
}

interface Cleaner {
  id: string;
  name: string;
  surname: string;
  jobRole: string;
  role: Role;
}

interface CreateTaskProps {
  roomNumber: string;
}

export default function CreateTask({ roomNumber }: CreateTaskProps) {
  const [loading, setLoading] = useState(false);
  const [cleaners, setCleaners] = useState<Cleaner[]>([]);
  const [formData, setFormData] = useState({
    title: `Apartment - Room - ${roomNumber} // `,
    source: '',
    priority: 'LOW',
    dueDate: '',
    description: '',
    assignedToId: ''
  });

  useEffect(() => {
    const fetchCleaners = async () => {
      try {
        const response = await getCleaners();
        console.log("Cleaners response:", response);
        if (response.data.statusCode === 200) {
          setCleaners(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching cleaners:', error);
        toast.error('Failed to fetch cleaners');
      }
    };

    fetchCleaners();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createTask({
        ...formData,
        roomNumber: roomNumber.toString(), // Ensure roomNumber is a string
        priority: formData.priority as 'LOW' | 'MEDIUM' | 'HIGH'
      });
      toast.success('Task created successfully');
      // Reset form
      setFormData({
        title: `Apartment - Room - ${roomNumber} // `,
        source: '',
        priority: 'LOW',
        dueDate: '',
        description: '',
        assignedToId: ''
      });
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Failed to create task');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Task Title <span className="text-watermelon">*</span>
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Source <span className="text-watermelon">*</span>
          </label>
          <select
            name="source"
            value={formData.source}
            onChange={handleChange}
            required
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          >
            <option value="">Select Source</option>
            <option value="RECEPTION">Reception</option>
            <option value="HOUSEKEEPING">Housekeeping</option>
            <option value="MAINTENANCE">Maintenance</option>
            <option value="GUEST">Guest Request</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Priority <span className="text-watermelon">*</span>
          </label>
          <select
            name="priority"
            value={formData.priority}
            onChange={handleChange}
            required
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          >
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Due Date <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            name="dueDate"
            value={formData.dueDate}
            onChange={handleChange}
            required
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Assign To
          </label>
          <select
            name="assignedToId"
            value={formData.assignedToId}
            onChange={handleChange}
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          >
            <option value="">Select Staff</option>
            {cleaners.map((cleaner) => (
              <option key={cleaner.id} value={cleaner.id}>
                {cleaner.name} {cleaner.surname} - {cleaner.role?.title || cleaner.jobRole}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Description
        </label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          rows={4}
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          placeholder="Enter task description..."
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 bg-hazel-green text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hazel-green disabled:opacity-50"
        >
          {loading ? 'Creating...' : 'Create Task'}
        </button>
      </div>
    </form>
  );
}