import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import store from '../store';
import { setUser } from '../store/slices/user-slice';
import Loader from '../components/loader';
import api from '../api/api';
import { login as loginApi, logout as logoutApi } from '../api/auth.api';
import { getLatestOwnerPasscode } from '../api/auth.api';
import { getRole, getUnauthRole } from '../api/roles';

import axios from 'axios';

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userPermissions: []
};

const verifyToken = (accessToken) => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('access_token');
    delete api.defaults.headers.common.Authorization;
  }
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  loginWithPasscode: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);

  const login = async (email, password, ownerPasscode = '') => {
    try {
      const response = await loginApi(email, password, ownerPasscode);
      console.log('API Response:', response);

      if (!response.data?.data?.token) {
        throw new Error('Invalid response format');
      }

      const accessToken = response.data.data.token;
      console.log('Token received:', accessToken);

      // Check token validity
      if (!accessToken || accessToken.split('.').length !== 3) {
        throw new Error('Invalid token format');
      }

      const decodedToken = jwtDecode(accessToken);
      setSession(accessToken);

      const { roleId } = decodedToken;
      let roleData = {};

      if (roleId) {
        const roleResponse = await getRole(roleId);
        roleData = roleResponse.data?.result || {};
      }

      console.log("ROLE DATA", roleData);

      // Update user state with isLoggedIn flag
      const payload = {
        isLoggedIn: true,
        email: decodedToken.email,
        id: decodedToken.id,
        jobRole: decodedToken.jobRole,
        name: decodedToken.name,
        surname: decodedToken.surname,
        userPermissions: decodedToken.permissions || [],
        userRole: roleData.title || ''
      };

      store.dispatch(setUser(payload));
      localStorage.setItem('user', JSON.stringify(payload));

      return response;
    } catch (err) {
      console.error('Login error:', err);
      store.dispatch(setUser(null));
      throw err;
    }
  };

  const loginWithPasscode = async (userData) => {
    try {
      if (!userData?.user) {
        throw new Error('Invalid user data received during login');
      }

      const { user } = userData;
      const { email, id, roleId, jobRole, name, surname } = user;

      const token = userData?.token; 
      if (token) {
        setSession(token); 
      } else {
        throw new Error("Token not provided in passcode login response");
      }

      let roleData = {};
      if (roleId) {
        try {
          const roleResponse = await getUnauthRole(roleId);
          roleData = roleResponse.data?.result || {}; 
        } catch (roleError) {
          console.error("Error fetching role:", roleError);
        }
      }

      const payload = {
        email,
        id,
        jobRole,
        name,
        surname,
        userPermissions: roleData.permissions || [],
        userRole: roleData.title || ''
      };
      store.dispatch(setUser(payload)); 
      localStorage.setItem('user', JSON.stringify(payload)); 
    } catch (error) {
      console.error('Login with passcode error:', error);
    }
  };

  const logout = async () => {
    const token = localStorage.getItem('access_token'); 

    if (!token) {
      console.error('No token found, unable to log out');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      await logoutApi(userId);

      await logoutApi(token);

      setSession(null); 
      store.dispatch(setUser(null)); 
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const accessToken = window.localStorage.getItem('access_token');
        const savedUser = JSON.parse(window.localStorage.getItem('user'));

        if (accessToken && verifyToken(accessToken)) {
          setSession(accessToken);

          const decodedToken = jwtDecode(accessToken);
          const { email, id, roleId, jobRole, name, surname } = decodedToken;
          let roleData = {};

          if (roleId) {
            const roleResponse = await getRole(roleId);
            roleData = roleResponse.data?.result || {};
          }

          const payload = {
            email,
            id,
            jobRole,
            name,
            surname,
            userPermissions: roleData.permissions || [],
            userRole: roleData.title || ''
          };

          store.dispatch(setUser(payload));
        } else if (savedUser) {
          store.dispatch(setUser(savedUser));
        } else {
          store.dispatch(setUser(null));
        }
      } catch (error) {
        console.error('Initialization error:', error);
        store.dispatch(setUser(null));
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...user, login, userPermissions: user?.userPermissions || [], loginWithPasscode, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
