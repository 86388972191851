import axios from 'axios';
import { API_URL } from '../config';

// Create axios instance with default config
const inTimeApi = axios.create({
  baseURL: `${API_URL}/api/inTime`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor to add token
inTimeApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle errors
inTimeApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
      if (error.response.status === 401) {
        // Don't automatically redirect on 401, just log the error
        console.error('Unauthorized access. Please check your authentication.');
      }
    } else if (error.request) {
      console.error('API Request Error:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
    return Promise.reject(error);
  }
);

// Get historical statistics
export const getAllStatistics = async (period = 'week') => {
  try {
    const response = await inTimeApi.get('/historical-statistics', {
      params: { period }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching statistics:', error);
    throw error;
  }
};
