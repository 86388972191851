import api from './api';

// Get token from localStorage or sessionStorage
const getToken = () => {
  return localStorage.getItem('token') || sessionStorage.getItem('token');
};

// Add request interceptor to handle token
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Get dashboard statistics for a specific timeframe
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<{
 *   totalSales: { totalAmount: number, salesByHour: Array<{ hour: string, amount: number }> },
 *   roomStats: { totalBookings: number, totalGuests: number, occupiedRooms: number },
 *   venueStats: { totalReservations: number, totalRevenue: number },
 *   poolStats: { totalVisits: number, totalRevenue: number },
 *   topProducts: Array<{ id: string, name: string, quantity: number, revenue: number }>,
 *   recentTransactions: Array<{ id: string, amount: number, type: string, date: string }>,
 *   revenueByCategory: Array<{ category: string, total: number }>
 * }>}
 */
export const getDashboardStats = async (timeframe = 'day') => {
  try {
    console.log('Fetching dashboard stats for timeframe:', timeframe);
    const response = await api.get(`/api/dashboard/stats/${timeframe}`);
    console.log('Dashboard stats response:', response.data);
    
    // Ensure all numeric values are properly converted
    const data = response.data;
    return {
      totalSales: {
        totalAmount: Number(data.totalSales?.totalAmount || 0),
        salesByHour: (data.totalSales?.salesByHour || []).map(sale => ({
          hour: sale.hour,
          amount: Number(sale.amount || 0)
        }))
      },
      roomStats: {
        totalBookings: Number(data.roomStats?.totalBookings || 0),
        totalGuests: Number(data.roomStats?.totalGuests || 0),
        occupiedRooms: Number(data.roomStats?.occupiedRooms || 0)
      },
      venueStats: {
        totalReservations: Number(data.venueStats?.totalReservations || 0),
        totalRevenue: Number(data.venueStats?.totalRevenue || 0)
      },
      poolStats: {
        totalVisits: Number(data.poolStats?.totalVisits || 0),
        totalRevenue: Number(data.poolStats?.totalRevenue || 0)
      },
      topProducts: (data.topProducts || []).map(product => ({
        id: product.id,
        name: product.name,
        quantity: Number(product.quantity || 0),
        revenue: Number(product.revenue || 0)
      })),
      recentTransactions: (data.recentTransactions || []).map(tx => ({
        id: tx.id,
        amount: Number(tx.amount || 0),
        type: tx.type,
        date: tx.date
      })),
      revenueByCategory: (data.revenueByCategory || []).map(cat => ({
        category: cat.category,
        total: Number(cat.total || 0)
      }))
    };
  } catch (error) {
    console.error('Error fetching dashboard stats:', error);
    return {
      totalSales: { totalAmount: 0, salesByHour: [] },
      roomStats: { totalBookings: 0, totalGuests: 0, occupiedRooms: 0 },
      venueStats: { totalReservations: 0, totalRevenue: 0 },
      poolStats: { totalVisits: 0, totalRevenue: 0 },
      topProducts: [],
      recentTransactions: [],
      revenueByCategory: []
    };
  }
};

/**
 * Get all admin users
 * @returns {Promise<Array<{
 *   id: string,
 *   name: string,
 *   surname: string,
 *   email: string,
 *   jobRole: string,
 *   phoneNumber?: string,
 *   secondaryPhoneNumber?: string,
 *   isCheckedIn: boolean,
 *   role: any,
 *   wage?: string,
 *   address?: string,
 *   personalNumber?: string
 * }>>}
 */
export const getAllAdminUsers = async () => {
  try {
    console.log('Fetching admin users');
    const response = await api.get('/api/dashboard/admin-users');
    console.log('Admin users response:', response.data);
    return response.data || [];
  } catch (error) {
    console.error('Error fetching admin users:', error);
    return [];
  }
};

/**
 * Get pool earnings statistics (backward compatibility function)
 * This function now uses getDashboardStats internally and extracts pool-specific data
 * @returns {Promise<{ earnings: Array<{ date: string, earnings: number }> }>}
 */
export const getPoolEarningsStatistics = async () => {
  try {
    const data = await getDashboardStats('day');
    return {
      earnings: data.poolStats ? [{
        date: new Date().toISOString(),
        earnings: data.poolStats.totalRevenue || 0
      }] : []
    };
  } catch (error) {
    console.error('Error fetching pool earnings:', error);
    return { earnings: [] };
  }
};

/**
 * Get sales statistics for a specific timeframe
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<{ totalAmount: number, salesByHour: Array<{ hour: string, amount: number }> }>}
 */
export const getSalesStats = async (timeframe = 'day') => {
  try {
    const data = await getDashboardStats(timeframe);
    return data.totalSales;
  } catch (error) {
    console.error('Error fetching sales stats:', error);
    return { totalAmount: 0, salesByHour: [] };
  }
};

/**
 * Get room statistics for a specific timeframe
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<{ totalBookings: number, totalGuests: number, occupiedRooms: number }>}
 */
export const getRoomStats = async (timeframe = 'day') => {
  try {
    const data = await getDashboardStats(timeframe);
    return data.roomStats;
  } catch (error) {
    console.error('Error fetching room stats:', error);
    return { totalBookings: 0, totalGuests: 0, occupiedRooms: 0 };
  }
};

/**
 * Get venue statistics for a specific timeframe
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<{ totalReservations: number, totalRevenue: number }>}
 */
export const getVenueStats = async (timeframe = 'day') => {
  try {
    const data = await getDashboardStats(timeframe);
    return data.venueStats;
  } catch (error) {
    console.error('Error fetching venue stats:', error);
    return { totalReservations: 0, totalRevenue: 0 };
  }
};

/**
 * Get top products
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<Array<{ name: string, quantity: number, type: string, price: number }>>}
 */
export const getTopProducts = async (timeframe = 'day') => {
  try {
    const data = await getDashboardStats(timeframe);
    return data.topProducts;
  } catch (error) {
    console.error('Error fetching top products:', error);
    return [];
  }
};

/**
 * Get recent transactions
 * @returns {Promise<Array<{ id: string, amount: number, date: string, type: string }>>}
 */
export const getRecentTransactions = async () => {
  try {
    const data = await getDashboardStats('day');
    return data.recentTransactions;
  } catch (error) {
    console.error('Error fetching recent transactions:', error);
    return [];
  }
};

/**
 * Get revenue by category
 * @param {('day'|'month'|'year')} timeframe - The timeframe for statistics
 * @returns {Promise<Array<{ category: string, total: number }>>}
 */
export const getRevenueByCategory = async (timeframe = 'day') => {
  try {
    const data = await getDashboardStats(timeframe);
    return data.revenueByCategory;
  } catch (error) {
    console.error('Error fetching revenue by category:', error);
    return [];
  }
};