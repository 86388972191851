import api from './api';

// Reservation APIs
export const getAllReservations = () => api.get('/api/venueReservations/reservations');
export const getReservation = (id) => api.get(`/api/venueReservations/reservations/${id}`);
export const createReservation = (values) => 
  api.post('/api/venueReservations/reservations', values);
export const updateReservation = (id, values) =>
  api.patch(`/api/venueReservations/reservations/${id}`, values);
export const deleteReservation = (id) => api.delete(`/api/venueReservations/reservations/${id}`);
export const cancelReservation = (id, { cancelReason }) => 
  api.post(`/api/venueReservations/reservations/${id}/cancel`, { cancelReason });

export const getAllVenueMenus = () => api.get('/api/venueReservations/menus');

export const checkVenueAvailability = (venueId, startDate, endDate) => 
  api.get('/api/venueReservations/venues/availability', {
    params: {
      venueId,
      startDate,
      endDate
    }
  });
