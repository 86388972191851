import React, { useState } from 'react';
import DateSelector from './DateSelector.tsx';
import RoomGrid from './RoomGrid.tsx';
import SearchBar from './SearchBar.tsx';
import { addDays } from 'date-fns';
import { LayoutGrid } from 'lucide-react';

const PMSPage = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeframe, setTimeframe] = useState(15);
  
  const generateDates = () => {
    const dates = [];
    for (let i = 0; i < timeframe; i++) {
      dates.push(addDays(selectedDate, i));
    }
    return dates;
  };

  return (
    <div className="min-h-screen bg-tealish-blue">
      <div className="container mx-auto px-2 sm:px-4 md:px-6 py-4 sm:py-8">
        <div className="bg-white rounded-xl shadow-sm overflow-hidden max-w-[1000px] mx-auto">
          {/* Search Bar and Date Selector */}
          <div className="bg-white p-2 sm:p-4 border-b border-grey-goose">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
              <SearchBar />
              <DateSelector
                date={selectedDate}
                onDateChange={setSelectedDate}
                timeframe={timeframe}
                onTimeframeChange={setTimeframe}
              />
            </div>
          </div>

          {/* Room Grid */}
          <div className="max-h-[calc(100vh-280px)]">
            <RoomGrid dates={generateDates()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMSPage;