import React from 'react';
import { FileText, CheckCircle, XCircle, AlertTriangle, User, Receipt } from 'lucide-react';
import { differenceInCalendarDays } from 'date-fns';

import { Card } from "../../../../shadCN/Card.tsx";

interface FinalReportProps {
  roomNumber: string;
  fromDate: string;
  toDate: string;
  guestName: string;
  cleaningReport?: {
    roomCondition: string;
    cleanlinessLevel: string;
    issuesDescription: string;
    additionalNotes: string;
  };
  totalAmount: number;
  paidAmount: number;
  extraCharges: number;
  roomServiceCharges: Array<{
    id: string;
    totalAmount: number;
    orderItems: Array<{
      id: string;
      productName?: string;
      price: number;
      quantity: number;
    }>;
  }>;
  customCharges: Array<{ description: string; amount: string; }>;
}

export default function FinalReport({
  roomNumber,
  fromDate,
  toDate,
  guestName,
  cleaningReport,
  totalAmount = 0,
  paidAmount = 0,
  extraCharges = 0,
  roomServiceCharges = [],
  customCharges = []
}: FinalReportProps) {

  console.log("Passed cleaning reprt to the final reportCleaning Report:", cleaningReport);

  const getRoomStatusIcon = (condition: string) => {
    switch (condition) {
      case 'excellent':
      case 'good':
        return <CheckCircle className="text-green-500" size={18} />;
      case 'fair':
        return <AlertTriangle className="text-yellow-500" size={18} />;
      case 'poor':
      case 'maintenance':
        return <XCircle className="text-red-500" size={18} />;
      default:
        return <AlertTriangle className="text-yellow-500" size={18} />;
    }
  };

  const getStatusPercentage = (status: string): number => {
    switch (status?.toLowerCase()) {
      case 'excellent':
        return 100;
      case 'good':
        return 80;
      case 'fair':
        return 60;
      case 'poor':
        return 40;
      case 'maintenance':
        return 20;
      default:
        return 0;
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status?.toLowerCase()) {
      case 'excellent':
      case 'good':
        return 'bg-green-500';
      case 'fair':
        return 'bg-yellow-500';
      case 'poor':
      case 'maintenance':
        return 'bg-red-500';
      default:
        return 'bg-gray-300';
    }
  };

  const roomServiceTotal = roomServiceCharges.reduce((sum, charge) => sum + charge.totalAmount, 0);
  const customChargesTotal = customCharges.reduce((sum, charge) => sum + Number(charge.amount), 0);
  const allExtraCharges = extraCharges + roomServiceTotal + customChargesTotal;
  const total = totalAmount + allExtraCharges;
  const remaining = total - paidAmount;

  const nights = differenceInCalendarDays(new Date(toDate), new Date(fromDate));
  const nightlyRate = totalAmount / nights;

  return (
    <div className="space-y-6">
      <div className="bg-seashell rounded-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <FileText className="text-hazel-green" size={24} />
            <h3 className="text-lg font-semibold text-hazel-green">Checkout Summary Report</h3>
          </div>
          <span className="px-3 py-1 bg-hazel-green/10 text-hazel-green rounded-full text-sm">
            Room {roomNumber}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            <Card className="p-4 bg-white">
              <div className="flex items-center gap-3 mb-4">
                <User className="h-5 w-5 text-bluish-grey" />
                <h3 className="font-medium">Guest Details</h3>
              </div>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-bluish-grey my-2">Name</p>
                  <p className="text-xs font-medium">
                    {guestName}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-bluish-grey my-2">Time:</p>
                  <p className="text-xs mb-2">
                    <span className="font-medium mx-4">Check-in:</span> {fromDate ? new Date(fromDate).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }) + ' ' + new Date(fromDate).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' }) : ""}
                  </p>
                  <p className="text-xs">
                    <span className="font-medium mx-4">Check-out:</span> {toDate ? new Date(toDate).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }) + ' ' + new Date(toDate).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' }) : ""}
                  </p>
                </div>
              </div>
            </Card>
            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Room Status</h4>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-lg border border-grey-goose">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm">Room Condition</span>
                    <span className="text-xs font-medium capitalize">{cleaningReport?.roomCondition || 'Not Set'}</span>
                  </div>
                  <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getStatusColor(cleaningReport?.roomCondition)} transition-all duration-500`}
                      style={{ width: `${getStatusPercentage(cleaningReport?.roomCondition)}%` }}
                    />
                  </div>
                </div>

                <div className="bg-white p-4 rounded-lg border border-grey-goose">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm">Cleanliness Level</span>
                    <span className="text-xs font-medium capitalize">{cleaningReport?.cleanlinessLevel || 'Not Set'}</span>
                  </div>
                  <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getStatusColor(cleaningReport?.cleanlinessLevel)} transition-all duration-500`}
                      style={{ width: `${getStatusPercentage(cleaningReport?.cleanlinessLevel)}%` }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Financial Summary</h4>
              <div className="bg-white p-4 rounded-lg border border-grey-goose">
                <div className="space-y-3">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h3 className="text-sm font-medium text-bluish-grey">Room Charges</h3>
                      <p className="text-lg font-semibold">${totalAmount.toFixed(2)}</p>
                      <span className="text-sm text-gray-500">
                        (${nightlyRate.toFixed(2)} × {nights} nights)
                      </span>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-bluish-grey">Extra Charges</h3>
                      <p className="text-lg font-semibold">${allExtraCharges.toFixed(2)}</p>
                      <span className="text-sm text-gray-500">
                        (Services: ${roomServiceTotal.toFixed(2)}, Other: ${(extraCharges + customChargesTotal).toFixed(2)})
                      </span>
                    </div>
                  </div>

                  <div className="border-t pt-4">
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-medium">Total Amount:</span>
                      <span className="text-xl font-bold text-hazel-green">${total.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-base">Paid Amount:</span>
                      <span className="text-base font-medium">${paidAmount.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-base">Remaining Balance:</span>
                      <span className="text-base font-medium text-watermelon">${remaining.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Notes & Comments</h4>
              <textarea
                rows={4}
                className="w-full px-3 py-2 text-sm rounded-lg border border-grey-goose focus:ring-1 focus:ring-hazel-green"
                placeholder="Add any final notes or comments..."
                value={cleaningReport?.additionalNotes || ''}

              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center pt-4 border-t border-grey-goose">
        <div className="text-sm text-bluish-grey">
          Generated on {new Date().toLocaleDateString()}
        </div>
        <div className="space-x-3">
          <button className="px-4 py-2 text-sm border border-hazel-green text-hazel-green rounded-md hover:bg-hazel-green/5 transition-colors">
            Download Report
          </button>
          <button className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
            Complete Checkout
          </button>
        </div>
      </div>
    </div>
  );
}