import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getPools, createPoolReservation } from '../../api/pools';
import { createInvoice, invoiceCount as invoiceCountAPI } from '../../api/invoiceSale';
import { createTransaction } from '../../api/accounting';
import { adjustAccountValue } from '../../api/account.js';
import { SubmitButton } from '../../components/buttons';
import { useTicketContext } from '../../context/TicketContext';
import api from '../../api/api.js';

//note that this was in 20/11/2024

const AddTicket = ({ poolType, poolId, formValues }) => {
  const [pools, setPools] = useState({});
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  useEffect(() => {
    async function fetchPools() {
      try {
        const response = await getPools();
        if (response.data) {
          const poolsData = {};
          response.data.forEach(pool => {
            if (pool.type === 'INDOOR') {
              poolsData.INDOOR = {
                ...pool,
                tickets: [
                  { title: 'standard', price: pool.standardPrice || 20 }
                ]
              };
            } else if (pool.type === 'OUTDOOR') {
              poolsData.OUTDOOR = {
                ...pool,
                tickets: [
                  { title: 'MAN', price: 10 },
                  { title: 'WOMAN', price: 10 },
                  { title: 'KID', price: 5 }
                ]
              };
            }
          });
          setPools(poolsData);
        }
      } catch (error) {
        console.error('Error fetching pools:', error);
        toast.error('Failed to load pools.');
      }
    }

    const fetchInvoiceCount = async () => {
      try {
        const response = await invoiceCountAPI();
        setInvoiceCount(response.data.count);
      } catch (error) {
        console.error('Error fetching invoice count:', error);
        toast.error('Failed to load invoice count.');
      }
    };

    fetchPools();
    fetchInvoiceCount();
  }, []);

  // Calculate price based on duration
  const calculatePrice = (startTime, endTime, basePrice) => {
    try {
      console.log('Calculating price for:', { startTime, endTime, basePrice });

      // Create today's date
      const today = new Date();
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);

      // Create start and end date objects
      let start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startHours, startMinutes);
      let end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endHours, endMinutes);

      // If end time is before start time, add one day to end time
      if (end < start) {
        end.setDate(end.getDate() + 1);
      }

      console.log('Parsed dates:', { start: start.toISOString(), end: end.toISOString() });

      const durationMs = end.getTime() - start.getTime();
      const durationHours = Math.ceil(durationMs / (1000 * 60 * 60));

      console.log('Duration hours:', durationHours);

      if (durationHours <= 0) {
        throw new Error('End time must be after start time');
      }

      let price;
      if (durationHours <= 3) {
        price = basePrice;
      } else {
        const additionalHours = durationHours - 3;
        const additionalBlocks = Math.ceil(additionalHours / 3);
        price = basePrice * (1 + additionalBlocks);
      }

      console.log('Calculated price:', price);
      return Math.round(price * 100) / 100;
    } catch (error) {
      console.error('Error in calculatePrice:', error);
      return null;
    }
  };

  const handleSubmit = async (values, formikHelpers) => {
    try {
      let reservationData = {};

      if (poolType === 'INDOOR') {
        // Validate required fields
        if (!formValues?.name || !formValues?.surname || !formValues?.personalNumber || 
            !formValues?.phoneNumber || !formValues?.startTime || !formValues?.endTime) {
          throw new Error('All fields are required for indoor pool tickets');
        }

        const basePrice = pools[poolType]?.tickets[0]?.price || 20;
        const price = calculatePrice(formValues.startTime, formValues.endTime, basePrice);

        if (!price) {
          throw new Error('Invalid time duration');
        }

        // Get current date parts
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const day = now.getDate();

        // Parse start and end times
        const [startHour, startMinute] = formValues.startTime.split(':').map(Number);
        const [endHour, endMinute] = formValues.endTime.split(':').map(Number);

        // Create start date
        const startDate = new Date(year, month, day, startHour, startMinute);
        
        // Create end date
        let endDate = new Date(year, month, day, endHour, endMinute);
        
        // If end time is earlier than start time, add one day to end date
        if (endDate < startDate) {
          endDate = new Date(year, month, day + 1, endHour, endMinute);
        }

        // Format dates for API
        const startTimeStr = startDate.toISOString().split('.')[0] + 'Z';
        const endTimeStr = endDate.toISOString().split('.')[0] + 'Z';

        console.log('Reservation times:', {
          start: startTimeStr,
          end: endTimeStr,
          price: price
        });

        reservationData = {
          poolId,
          ticketType: 'STANDARD',
          price,
          name: formValues.name,
          surname: formValues.surname,
          personalNumber: formValues.personalNumber,
          phoneNumber: formValues.phoneNumber,
          startTime: startTimeStr,
          endTime: endTimeStr
        };
      } else {
        // For outdoor pools, use the fixed price from values
        reservationData = {
          poolId,
          ticketType: values.ticketTitle.toUpperCase(),
          price: Number(values.price)
        };
      }

      console.log('Sending reservation data:', reservationData);

      const response = await createPoolReservation(reservationData);
      
      if (response.data) {
        toast.success('Ticket created successfully!');
        await handlePrint(response.data);
        
        // First create or get the default client
        let clientId;
        try {
          const clientResponse = await api.post('/api/accounting/clients/check-or-create', {
            name: 'Pool Default Client',
            phone: '',
            email: '',
            address: 'Lakeside Resort'
          });
          clientId = clientResponse.data.id;
        } catch (error) {
          console.error('Error checking or creating client:', error);
          toast.error('Failed to check or create client.');
          return;
        }

        // Create invoice with the client ID
        const currentDate = new Date().toISOString();
        const invoiceData = {
          invoiceNumber: `INV-${(invoiceCount + 1).toString().padStart(6, '0')}`,
          clientId,
          invoiceDate: currentDate,
          paymentMethod: 'CASH',
          status: 'PAID',
          reference: `Pool Ticket - ${response.data.id}`,
          terms: 'Payment due upon receipt',
          notes: 'Pool ticket purchase',
          items: [{
            details: poolType === 'INDOOR' 
              ? `Pool Ticket - ${reservationData.ticketType} - ${reservationData.name} ${reservationData.surname} (${formValues.startTime}-${formValues.endTime})`
              : `Pool Ticket - ${reservationData.ticketType}`,
            quantity: 1,
            rate: reservationData.price,
            discount: 0,
            tax: 0,
            amount: reservationData.price
          }]
        };

        console.log('Creating invoice with data:', invoiceData);

        const invoiceResponse = await createInvoice(invoiceData);
        
        if (invoiceResponse.data) {
          // Create transaction
          const transactionData = {
            amount: reservationData.price,
            paymentType: 'CASH',
            transactionType: 'PAYMENT',
            description: `Pool Ticket - ${reservationData.ticketType}`,
            clientId: clientId,
            acceptedBy: 'Lake Side Hotel',
            accountId: 11, // Main Income account
            TransactionProducts: [{
              item: `Pool Ticket - ${reservationData.ticketType}`,
              type: 'Service',
              unit: 'Ticket',
              quantity: 1,
              unitPrice: reservationData.price,
              soldPrice: reservationData.price,
              discount: 0
            }]
          };

          console.log('Creating transaction with data:', transactionData);
          await createTransaction(transactionData);

          // Adjust account value
          await adjustAccountValue(11, reservationData.price);

          setInvoiceCount(prev => prev + 1);
        }

        formikHelpers.resetForm();
      }
    } catch (error) {
      console.error('Failed to create ticket:', error);
      toast.error(error.response?.data?.message || 'Failed to create ticket');
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  // Update price when form values change
  const handleFormChange = (values) => {
    if (values.startTime && values.endTime) {
      const pool = pools[poolType];
      const basePrice = pool?.tickets.find(t => t.title === 'standard')?.price || 20;
      const price = calculatePrice(values.startTime, values.endTime, basePrice);
      setCalculatedPrice(price);
    }
  };

  const getTicketImage = (type) => {
    if (type === 'WOMAN') {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448838960_838378207849442_8537274201646011122_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_ohc=B4WyjUUjFbwQ7kNvgEMJeak&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QGXzq0LnRoAs-6-61QscPkNlKM54Sjzvm5sgRJeh8bRFQ&oe=66B74829';
    } else if (type === 'MAN') {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/449683173_1667776560672670_756979275964704068_n.png?_nc_cat=110&ccb=1-7&_nc_sid=9f807c&_nc_ohc=c64DeVc3c6AQ7kNvgHF-kqo&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QE-04KPjZjaryYqyQuEhL1j6M1ngdTVANefv77jVqzvGg&oe=66B734BC';
    } else if (type === 'KID') {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/449683173_1667776560672670_756979275964704068_n.png?_nc_cat=110&ccb=1-7&_nc_sid=9f807c&_nc_ohc=c64DeVc3c6AQ7kNvgHF-kqo&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QE-04KPjZjaryYqyQuEhL1j6M1ngdTVANefv77jVqzvGg&oe=66B734BC';
    }
    return '';
  };

  const handlePrint = async (ticketData) => {
    try {
      const printWindow = window.open('', '_blank');
      if (!printWindow) {
        throw new Error('Pop-up window was blocked');
      }

      const ticketHtml = `
        <!DOCTYPE html>
        <html>
        <head>
          <title>Pool Ticket</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              color: #333;
            }
            .ticket {
              border: 2px solid #000;
              padding: 20px;
              max-width: 400px;
              margin: 0 auto;
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .details {
              margin-bottom: 20px;
            }
            .footer {
              text-align: center;
              font-size: 0.8em;
              margin-top: 20px;
            }
            .logo {
              max-width: 100px;
              margin-bottom: 10px;
            }
            .qr-code {
              text-align: center;
              margin-top: 20px;
            }
            .qr-code img {
              max-width: 150px;
            }
          </style>
        </head>
        <body>
          <div class="ticket">
            <div class="header">
              <img src="${getTicketImage(ticketData.ticketType)}" alt="Ticket Type" class="logo">
              <h1>Pool Ticket</h1>
            </div>
            <div class="details">
              <p><strong>Ticket Type:</strong> ${ticketData.ticketType}</p>
              ${ticketData.name ? `<p><strong>Name:</strong> ${ticketData.name} ${ticketData.surname}</p>` : ''}
              ${ticketData.startTime ? `
                <p><strong>Start Time:</strong> ${new Date(ticketData.startTime).toLocaleString()}</p>
                <p><strong>End Time:</strong> ${new Date(ticketData.endTime).toLocaleString()}</p>
              ` : ''}
              <p><strong>Price:</strong> €${ticketData.price}</p>
            </div>
            <div class="footer">
              <p>Thank you for choosing our pool!</p>
              <p>This ticket is valid only for the specified date and time.</p>
            </div>
          </div>
          <script>
            window.onload = () => {
              window.print();
              setTimeout(() => window.close(), 500);
            };
          </script>
        </body>
        </html>
      `;

      printWindow.document.write(ticketHtml);
      printWindow.document.close();
    } catch (error) {
      console.error('Error printing ticket:', error);
      // Don't show error toast here since ticket was created successfully
      console.warn('Printing failed but ticket was created successfully');
    }
  };

  return (
    <div className={`${poolType === 'INDOOR' ? 'max-w-xl mx-auto' : 'grid grid-cols-1 sm:grid-cols-3 gap-4'} my-4`}>
      {pools[poolType]?.tickets?.map((item, index) => (
        <Formik
          key={index}
          initialValues={{
            ticketTitle: item.title,
            price: item.price
          }}
          validationSchema={Yup.object().shape({
            ticketTitle: Yup.string(),
            price: Yup.number().required('Price is required').min(0, 'Price must be greater than 0')
          })}
          onSubmit={async (values, formikHelpers) => {
            try {
              let reservationData = {};

              if (poolType === 'INDOOR') {
                // Validate required fields
                if (!formValues?.name || !formValues?.surname || !formValues?.personalNumber || 
                    !formValues?.phoneNumber || !formValues?.startTime || !formValues?.endTime) {
                  throw new Error('All fields are required for indoor pool tickets');
                }

                const startTime = new Date(formValues.startTime);
                const endTime = new Date(formValues.endTime);
                const basePrice = pools[poolType]?.tickets[0]?.price || 20;

                // Calculate price using the new logic
                const price = calculatePrice(formValues.startTime, formValues.endTime, basePrice);
                if (!price) {
                  throw new Error('Invalid time duration');
                }

                // Get current date parts
                const now = new Date();
                const year = now.getFullYear();
                const month = now.getMonth();
                const day = now.getDate();

                // Parse start and end times
                const [startHour, startMinute] = formValues.startTime.split(':').map(Number);
                const [endHour, endMinute] = formValues.endTime.split(':').map(Number);

                // Create start date
                const startDate = new Date(year, month, day, startHour, startMinute);
                
                // Create end date
                let endDate = new Date(year, month, day, endHour, endMinute);
                
                // If end time is earlier than start time, add one day to end date
                if (endDate < startDate) {
                  endDate = new Date(year, month, day + 1, endHour, endMinute);
                }

                // Format dates for API
                const startTimeStr = startDate.toISOString().split('.')[0] + 'Z';
                const endTimeStr = endDate.toISOString().split('.')[0] + 'Z';

                console.log('Reservation times:', {
                  start: startTimeStr,
                  end: endTimeStr,
                  price: price
                });

                reservationData = {
                  poolId,
                  ticketType: 'STANDARD',
                  price,
                  name: formValues.name,
                  surname: formValues.surname,
                  personalNumber: formValues.personalNumber,
                  phoneNumber: formValues.phoneNumber,
                  startTime: startTimeStr,
                  endTime: endTimeStr
                };
              } else {
                // For outdoor pools, use the fixed price from values
                reservationData = {
                  poolId,
                  ticketType: values.ticketTitle.toUpperCase(),
                  price: Number(values.price)
                };
              }

              console.log('Sending reservation data:', reservationData);

              const response = await createPoolReservation(reservationData);
              
              if (response.data) {
                toast.success('Ticket created successfully!');
                await handlePrint(response.data);
                
                // First create or get the default client
                let clientId;
                try {
                  const clientResponse = await api.post('/api/accounting/clients/check-or-create', {
                    name: 'Pool Default Client',
                    phone: '',
                    email: '',
                    address: 'Lakeside Resort'
                  });
                  clientId = clientResponse.data.id;
                } catch (error) {
                  console.error('Error checking or creating client:', error);
                  toast.error('Failed to check or create client.');
                  return;
                }

                // Create invoice with the client ID
                const currentDate = new Date().toISOString();
                const invoiceData = {
                  invoiceNumber: `INV-${(invoiceCount + 1).toString().padStart(6, '0')}`,
                  clientId,
                  invoiceDate: currentDate,
                  paymentMethod: 'CASH',
                  status: 'PAID',
                  reference: `Pool Ticket - ${response.data.id}`,
                  terms: 'Payment due upon receipt',
                  notes: 'Pool ticket purchase',
                  items: [{
                    details: poolType === 'INDOOR' 
                      ? `Pool Ticket - ${reservationData.ticketType} - ${reservationData.name} ${reservationData.surname} (${formValues.startTime}-${formValues.endTime})`
                      : `Pool Ticket - ${reservationData.ticketType}`,
                    quantity: 1,
                    rate: reservationData.price,
                    discount: 0,
                    tax: 0,
                    amount: reservationData.price
                  }]
                };

                console.log('Creating invoice with data:', invoiceData);

                const invoiceResponse = await createInvoice(invoiceData);
                
                if (invoiceResponse.data) {
                  // Create transaction
                  const transactionData = {
                    amount: reservationData.price,
                    paymentType: 'CASH',
                    transactionType: 'PAYMENT',
                    description: `Pool Ticket - ${reservationData.ticketType}`,
                    clientId: clientId,
                    acceptedBy: 'Lake Side Hotel',
                    accountId: 11, // Main Income account
                    TransactionProducts: [{
                      item: `Pool Ticket - ${reservationData.ticketType}`,
                      type: 'Service',
                      unit: 'Ticket',
                      quantity: 1,
                      unitPrice: reservationData.price,
                      soldPrice: reservationData.price,
                      discount: 0
                    }]
                  };

                  console.log('Creating transaction with data:', transactionData);
                  await createTransaction(transactionData);

                  // Adjust account value
                  await adjustAccountValue(11, reservationData.price);

                  setInvoiceCount(prev => prev + 1);
                }

                formikHelpers.resetForm();
              }
            } catch (error) {
              console.error('Failed to create ticket:', error);
              toast.error(error.response?.data?.message || 'Failed to create ticket');
            } finally {
              formikHelpers.setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className='flex justify-center items-center p-5 bg-seashell rounded-md h-24'>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-full'
                  title={poolType === 'INDOOR' ? 'Add Standard Ticket' : `Add ${values.ticketTitle} ticket`}
                />
              </div>
            </form>
          )}
        </Formik>
      ))}
    </div>
  );
};

export default AddTicket;