import axios from 'axios';

// const axiosInstance = axios.create({ baseURL: 'http://3.79.80.215' });

const axiosInstance = axios.create({ baseURL: 'https://api-staff.lakesideks.com' });

// const axiosInstance = axios.create({ baseURL: 'http://localhost:3001' });


export const fetcher = (url) =>
  axiosInstance.get(url).then((res) => {
    if (res) {
      return res.data;
    }
    return null;
  });

export const setAuthorizationHeader = (accessToken) => {
  if (accessToken) {
    console.log("Setting Authorization Header:", accessToken); // Log the token

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
};


export const uploadImage = (formData) =>
  axiosInstance.post('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });




export default axiosInstance;
