import api from './api';

export const getPools = () => api.get('/api/pools');

export const updatePool = (id, values) => api.patch(`/api/pools/${id}`, values);

export const createPoolReservation = (values) =>
  api.post('/api/pools/reservations', values);

export const getPoolReservations = (id) =>
  api.get(`/api/pools/${id}/reservations`);

export const getRecentPoolReservations = () => api.get('/api/pools/recent-reservations');

export const verifyTicket = (ticketReference) =>
  api.get(`/api/pools/reservations/${ticketReference}/verify`);

export const checkInTicket = (ticketReference) =>
  api.post(`/api/pools/reservations/${ticketReference}/checkin`);

export const checkOutTicket = (ticketReference) =>
  api.post(`/api/pools/reservations/${ticketReference}/checkout`);
