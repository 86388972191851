import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import StatisticCard from './StatisticCard';
import { 
  IconBed, 
  IconTable, 
  IconPool, 
  IconToolsKitchen2, 
  IconGlass, 
  IconBuilding, 
  IconCurrencyDollar,
  IconLoader2,
  IconChartBar,
  IconUsers
} from '@tabler/icons-react';
import { getAllStatistics } from "../../api/inTime";
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const InTime = () => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState('week');
  const [activeTab, setActiveTab] = useState('revenue');
  const [activeTabState, setActiveTabState] = useState({
    revenue: true,
    clients: false
  });
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('No authentication token found. Please log in again.');
      navigate('/login');
      return;
    }

    const fetchStatistics = async () => {
      try {
        setLoading(true);
        const data = await getAllStatistics(period);
        if (!Array.isArray(data)) {
          setError('Invalid data format received');
          setStatistics([]);
          return;
        }
        setStatistics(data);
        setError(null);
      } catch (error) {
        if (error.response?.status === 401) {
          setError('Your session has expired. Please log in again.');
          navigate('/login');
        } else {
          setError(error.message || 'Failed to fetch statistics');
        }
        setStatistics([]);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [period, isLoggedIn, navigate]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return period === 'year' 
      ? date.toLocaleDateString('en-US', { month: 'short' })
      : date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const getCurrentStats = () => {
    if (!statistics || !statistics.length) {
      return {
        clients: {
          rooms: 0,
          tables: 0,
          pool: 0,
          restaurant: 0,
          bar: 0,
          conference: 0,
          total: 0
        },
        revenue: {
          rooms: 0,
          pool: 0,
          restaurant: 0,
          bar: 0,
          conference: 0,
          total: 0
        }
      };
    }
    return statistics[statistics.length - 1];
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const currentStats = getCurrentStats();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <IconLoader2 className="w-8 h-8 animate-spin text-hazel-green" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-hazel-green text-white rounded hover:bg-opacity-90"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Period Selection */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-hazel-green">Real-Time Statistics</h1>
        <div className="flex gap-2">
          {['week', 'month', 'year'].map((p) => (
            <button
              key={p}
              onClick={() => setPeriod(p)}
              className={`px-4 py-2 rounded-lg transition-all ${
                period === p
                  ? 'bg-hazel-green text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {p.charAt(0).toUpperCase() + p.slice(1)}
            </button>
          ))}
        </div>
      </div>

      {/* Main Revenue Card */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <StatisticCard
          title="Total Revenue"
          value={formatCurrency(currentStats.revenue.total)}
          Icon={IconCurrencyDollar}
          bgColor="bg-hazel-green bg-opacity-10"
          textColor="text-hazel-green"
          isMainCard={true}
        />
        <StatisticCard
          title="Total Clients"
          value={currentStats.clients.total}
          Icon={IconUsers}
          bgColor="bg-sage bg-opacity-10"
          textColor="text-sage"
          isMainCard={true}
        />
      </div>

      {/* Statistics Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 mb-8">
        <StatisticCard
          title="Rooms"
          value={`${currentStats.clients.rooms} clients (${formatCurrency(currentStats.revenue.rooms)})`}
          Icon={IconBed}
        />
        <StatisticCard
          title="Tables"
          value={`${currentStats.clients.tables} reservations`}
          Icon={IconTable}
        />
        <StatisticCard
          title="Pool"
          value={`${currentStats.clients.pool} clients (${formatCurrency(currentStats.revenue.pool)})`}
          Icon={IconPool}
        />
        <StatisticCard
          title="Restaurant"
          value={`${currentStats.clients.restaurant} clients (${formatCurrency(currentStats.revenue.restaurant)})`}
          Icon={IconToolsKitchen2}
        />
        <StatisticCard
          title="Bar"
          value={`${currentStats.clients.bar} clients (${formatCurrency(currentStats.revenue.bar)})`}
          Icon={IconGlass}
        />
        <StatisticCard
          title="Conference"
          value={`${currentStats.clients.conference} bookings (${formatCurrency(currentStats.revenue.conference)})`}
          Icon={IconBuilding}
        />
      </div>

      {/* Charts Section */}
      <div className="bg-white p-6 rounded-xl shadow-lg border border-grey-goose">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
            <IconChartBar className="inline" />
            Statistics Trends
          </h2>
          <div className="flex gap-2">
            <button
              onClick={() => {
                setActiveTab('revenue');
                setActiveTabState({ revenue: true, clients: false });
              }}
              className={`px-4 py-2 rounded-lg transition-all ${
                activeTabState.revenue
                  ? 'bg-hazel-green text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Revenue
            </button>
            <button
              onClick={() => {
                setActiveTab('clients');
                setActiveTabState({ revenue: false, clients: true });
              }}
              className={`px-4 py-2 rounded-lg transition-all ${
                activeTabState.clients
                  ? 'bg-hazel-green text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              Clients
            </button>
          </div>
        </div>

        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            {activeTabState.revenue ? (
              <LineChart data={statistics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatDate}
                  interval="preserveStartEnd"
                />
                <YAxis 
                  tickFormatter={(value) => formatCurrency(value)}
                />
                <Tooltip 
                  formatter={(value) => formatCurrency(value)}
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line type="monotone" dataKey="revenue.rooms" name="Rooms" stroke="#2E7D32" />
                <Line type="monotone" dataKey="revenue.pool" name="Pool" stroke="#1976D2" />
                <Line type="monotone" dataKey="revenue.restaurant" name="Restaurant" stroke="#D32F2F" />
                <Line type="monotone" dataKey="revenue.bar" name="Bar" stroke="#7B1FA2" />
                <Line type="monotone" dataKey="revenue.conference" name="Conference" stroke="#F57C00" />
              </LineChart>
            ) : (
              <LineChart data={statistics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatDate}
                  interval="preserveStartEnd"
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line type="monotone" dataKey="clients.rooms" name="Rooms" stroke="#2E7D32" />
                <Line type="monotone" dataKey="clients.tables" name="Tables" stroke="#1976D2" />
                <Line type="monotone" dataKey="clients.pool" name="Pool" stroke="#0277BD" />
                <Line type="monotone" dataKey="clients.restaurant" name="Restaurant" stroke="#D32F2F" />
                <Line type="monotone" dataKey="clients.bar" name="Bar" stroke="#7B1FA2" />
                <Line type="monotone" dataKey="clients.conference" name="Conference" stroke="#F57C00" />
              </LineChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default InTime;
