import React from 'react';
import { Building2, Key, LogOut, ShoppingCart, Star } from 'lucide-react';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';
import { QuickLink } from '../../components/hotelInterfaceComponents/QuickLink.tsx';
import Dashboard from '../../components/hotelInterfaceComponents/Dashboard.tsx';

function HotelHome() {
  const stats = [
    { icon: Building2, label: 'Active Properties', value: '0', trend: '+0%', color: 'bg-blue-50' },
    { icon: Key, label: 'Check-ins', value: '0', trend: '+0%', color: 'bg-green-50' },
    { icon: LogOut, label: 'Checkouts', value: '0', trend: '+0%', color: 'bg-purple-50' },
    { icon: ShoppingCart, label: 'Online Orders', value: '0', trend: '+0%', color: 'bg-orange-50' },
    { icon: Star, label: 'Reviews', value: '0', trend: '+0%', color: 'bg-pink-50' },
  ];

  const quickLinks = [
    { label: 'CREATE NEW PROPERTY', primary: true },
    { label: 'VIEW ALL PROPERTIES' },
    { label: 'ARRIVAL REVENUE REPORT' },
    { label: 'BOOKED REVENUE REPORT' },
    { label: 'SETTINGS' },
  ];

  const navItems = ['Dashboard', 'PMS', 'Manage Properties', 'Room Operations', 'Reports', 'Integrations', 'Manage'];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
          {stats.map((stat, index) => (
            <div
              key={index}
              className={`${stat.color} overflow-hidden rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300`}
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <stat.icon className="h-8 w-8 text-gray-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-600 truncate">
                        {stat.label}
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">
                          {stat.value}
                        </div>
                        <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                          {stat.trend}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Quick Links Section */}
        <div className="mt-6">
          <h2 className="text-sm font-medium text-gray-600 mb-3">Quick Actions</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3">
            {quickLinks.map((link, index) => (
              <QuickLink key={index} label={link.label} primary={link.primary} />
            ))}
          </div>
        </div>

        {/* Dashboard Section */}
        <div className="mt-8">
          <Dashboard />
        </div>
      </div>
    </div>
  );
}

export default HotelHome;
