import api from './api';

export const createPoolOrder = async (orderData) => {
    try {
        const response = await api.post('/api/pool-orders', orderData);
        return response.data;
    } catch (error) {
        console.error('Error creating pool order:', error);
        throw error;
    }
};

export const getPoolOrders = async (reservationId) => {
    try {
        const response = await api.get(`/api/pool-orders/reservation/${reservationId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching pool orders:', error);
        throw error;
    }
};

export const getPoolOrdersTotal = async (reservationId) => {
    try {
        const response = await api.get(`/api/pool-orders/reservation/${reservationId}/total`);
        return response.data.total;
    } catch (error) {
        console.error('Error fetching pool orders total:', error);
        throw error;
    }
};

export const getAllPoolOrders = async (status) => {
    try {
        const url = status ? `/api/pool-orders?status=${status}` : '/api/pool-orders';
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching all pool orders:', error);
        throw error;
    }
};

export const updateOrderStatus = async (orderId, status, staffId) => {
    try {
        const response = await api.patch(`/api/pool-orders/${orderId}/status`, { status, staffId });
        return response.data;
    } catch (error) {
        console.error('Error updating order status:', error);
        throw error;
    }
};
