import React, { useState , useEffect} from 'react';
import { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUserLarge , faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import useAuth from '../../hooks/useAuth';
import { checkUserHasPermission } from '../../helpers/user-helper';
import { HandPlatter, LayoutDashboard } from 'lucide-react';
import {
  IconAlignBoxBottomCenterFilled,
  IconShoppingCartFilled,
  IconPropeller,
  IconBedFilled,
  IconFileDollar,
  IconChefHat,
  IconSwimming,
  IconDogBowl,
  IconToolsKitchen2,
  IconReceipt,
  IconFish,
  IconCoffee,
  IconDoor,
  IconConfetti,
  IconFileInvoice,
  IconFileInfo,
  IconBriefcase2Filled,
  IconStackBack,
  IconArrowLeft,
  IconUsers, // For Clients
  IconClipboardList, // For Suppliers
  IconExchange, // For Transactions
  IconBook, // For Journal Entries
  IconNotebook, // For Ledgers
  IconFileText, // For Balance Sheet
  IconBuilding, // For Assets
  IconCash as IconCashRegister, // For Daily Cash Register
  IconChartBar, // For Budgets
  IconChartPie, // For Transactions
  IconMoneybag, // For Expenses
  IconFileInvoice as IconPayroll, // For Payroll
  IconGraph, // For Profit and Loss
  IconCash, // For Cash Flow
  IconFileText as IconIncomeStatement, // For Income Statement
  IconReceiptTax as IconTax, // For Tax Management
  IconBuildingBank as IconBank, // For Loans
  IconChartLine, // For Revenue Forecast
  IconChartArea, // For Expense Forecast
  IconChartDonut, // For Profitability Analysis
  IconTimeline as IconChartScatter, // For Variance Analysis
  IconChartRadar, // For Break-even Analysis
  IconStar,
  IconFileReport,
  IconCalculatorFilled // For KPI
} from '@tabler/icons-react';

function SidebarMenuItem({ href, label, icon, isActive, onClick }) {
  return (
    <Link
      to={href}
      onClick={() => {
        onClick();
      }}
      className={`flex items-center py-2.5 cursor-pointer pl-5 ${isActive ? 'bg-mercury w-7/8 rounded-md' : ''}`}
    >
      <p className={` ${isActive ? 'text-hazel-green' : 'text-grey-goose'}`}>
        {icon}
      </p>
      <p className={` ${isActive ? 'text-hazel-green font-medium' : 'text-bluish-grey'} ml-2`}>
        {label}
      </p>
    </Link>
  );
}

function Sidebar() {
  const { userPermissions, userRole } = useAuth();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const sidebarRef = useRef();

  const isActive = (href) => pathname.includes(href);

  const handleBackClick = () => {
    navigate('/administration');
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      const isCurrentlyMobile = window.innerWidth < 1024;
      setIsMobile(isCurrentlyMobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isMobile && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    const handleScroll = () => {
      if (isMobile) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const sidebarVariants = {
    open: { 
      x: 0,
      display: 'block',
      transition: { 
        type: 'spring', 
        stiffness: 300, 
        damping: 30,
        duration: 0.3
      }
    },
    closed: { 
      x: "-100%",
      transitionEnd: {
        display: 'none'
      },
      transition: { 
        type: 'spring', 
        stiffness: 300, 
        damping: 30,
        duration: 0.3
      }
    }
  };

  const overlayVariants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeInOut'
      }
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: 'easeInOut'
      }
    }
  };

  const sidebarMenu = [
    {
      group: 'menu',
      items: [
        {
          label: 'Dashboard',
          href: '/',
          icon: <IconAlignBoxBottomCenterFilled size={24} />
        },
        {
          label: 'Inventory',
          href: '/inventory',
          icon: <IconShoppingCartFilled size={23} />,
          permission: ['read_invoices']
        },
        {
          label: 'Admin',
          href: '/administration',
          icon: <IconFileInvoice size={23} />,
          permission: ['read_invoices']
        },
        {
          label: 'In Time',
          href: '/in_time',
          icon: <IconFileInfo size={22} />,
          permission: ['in_time']
        },
        {
          label: 'Stock',
          href: '/stock',
          icon: <IconStackBack size={23} />,
          permission: ['read_invoices']
        },
      ]
    },
    {
      group: 'management',
      items: [
        {
          label: 'Users',
          href: '/users',
          icon: <FontAwesomeIcon icon={faUserLarge} className='text-base mx-0.5' />,
          permission: ['read_users']
        },
        {
          label: 'Employees Management',
          href: '/employees',
          icon: <FontAwesomeIcon icon={faPersonCircleCheck} className='text-base mx-0.5' />,
        },
        {
          label: 'Roles',
          href: '/roles',
          icon: <IconPropeller size={22} />,
          permission: ['read_roles']
        },
        {
          label: 'Rooms',
          href: '/rooms',
          icon: <IconBedFilled size={22} />,
          permission: ['read_room_types', 'edit_rooms']
        },
        {
          label: 'Wedding Venue',
          href: '/venue',
          icon: <IconConfetti size={22} />,
        },
        {
          label: 'Conference Room',
          href: '/conferenceRoom',
          icon: <IconDoor size={22} />,
        },
        {
          label: 'Pools',
          href: '/pools',
          icon: <IconSwimming size={22} />,
          permission: ['read_outdoor_pools', 'read_indoor_pools']
        },
        {
          label: 'Restaurant',
          href: '/restaurant',
          icon: <IconDogBowl size={22} />,
          permission: ['waiter_premission']
        },
        {
          label: 'Waiter Cash Register',
          href: '/waiter/waiter-cash-log',
          icon: <IconCashRegister size={22} />,
          permission: ['waiter_premission']
        },
        {
          label: 'Assign Waiters',
          href: '/waiter/assign-waiters',
          icon: <HandPlatter className='text-sage' size={22} />,
          // permission: ['waiter_premission']
        },
        {
          label: 'Dish',
          href: '/dishes/dishes_list',
          icon: <IconFish size={22} />,
        },
        {
          label: 'Bar',
          href: 'barProducts/barProduct_list',
          icon: <IconCoffee size={22} />,
        },
        {
          label: 'Menu',
          href: '/menus/menu-page',
          icon: <IconReceipt size={22} />,
        },
        {
          label: 'Order',
          href: '/orders',
          icon: <IconToolsKitchen2 size={22} />,
        },
        {
          label: 'Invoices',
          href: '/invoices',
          icon: <IconFileDollar />,
          permission: ['read_invoices']
        }
      ]
    },
    {
      group: 'accounting', // Accounting group
      items: [
        {
          label: 'Dashboard',
          href: '/accounting',
          icon: <LayoutDashboard size={22} />
        },
        {
          label: 'Reports',
          href: '/accounting/reports',
          icon: <IconFileReport size={22} />
        },
        {
          label: 'Accounts',
          href: '/accounting/accounts',
          icon: <IconCalculatorFilled size={22} />
        },
        {
          label: 'Clients',
          href: '/accounting/clients',
          icon: <IconUsers size={22} />
        },
        {
          label: 'Suppliers',
          href: '/accounting/suppliers',
          icon: <IconClipboardList size={22} />
        },
        {
          label: 'Transactions',
          href: '/accounting/transactions',
          icon: <IconExchange size={22} />
        },
        {
          label: 'Journal Entries',
          href: '/accounting/journal-entries',
          icon: <IconBook size={22} />
        },
        {
          label: 'Ledgers',
          href: '/accounting/ledgers',
          icon: <IconNotebook size={22} />
        },
        {
          label: 'Balance Sheet',
          href: '/accounting/balance-sheet',
          icon: <IconFileText size={22} />
        },
        {
          label: 'Assets',
          href: '/accounting/assets',
          icon: <IconBuilding size={22} />
        },
        {
          label: 'Daily Cash Register',
          href: '/accounting/daily-cash-registers',
          icon: <IconCashRegister size={22} />
        },
      ]   
    },
    {
      group: 'finances', // Finances group
      items: [
        {
          label: 'Budgets',
          href: '/finances/budgets',
          icon: <IconChartBar size={22} />
        },
        {
          label: 'Transactions',
          href: '/finances/transactions',
          icon: <IconChartPie size={22} />
        },
        {
          label: 'Expenses',
          href: '/finances/expenses',
          icon: <IconMoneybag size={22} />
        },
        {
          label: 'Payroll',
          href: '/finances/payrolls',
          icon: <IconPayroll size={22} />
        },
        {
          label: 'Profit and Loss',
          href: '/finances/profit-loss',
          icon: <IconGraph size={22} />
        },
        {
          label: 'Cash Flow',
          href: '/finances/cash-flow',
          icon: <IconCash size={22} />
        },
        {
          label: 'Financial Statement',
          href: '/finances/financial-statement',
          icon: <IconIncomeStatement size={22} />
        },
        {
          label: 'Tax Management',
          href: '/finances/tax',
          icon: <IconTax size={22} />
        },
        {
          label: 'Loans',
          href: '/finances/loans',
          icon: <IconBank size={22} />
        },
      ]
    },
    {
      group: 'statistics', // Statistics group
      items: [
        {
          label: 'Revenue Forecast',
          href: '/statistics/revenue-forecast',
          icon: <IconChartLine size={22} />
        },
        {
          label: 'Expense Forecast',
          href: '/statistics/expense-forecast',
          icon: <IconChartArea size={22} />
        },
        {
          label: 'Profitability Analysis',
          href: '/statistics/profitability-analysis',
          icon: <IconChartDonut size={22} />
        },
        {
          label: 'Variance Analysis',
          href: '/statistics/variance-analysis',
          icon: <IconChartScatter size={22} />
        },
        {
          label: 'Break-even Analysis',
          href: '/statistics/break-even-analysis',
          icon: <IconChartRadar size={22} />
        },
        {
          label: 'KPI',
          href: '/statistics/kpi',
          icon: <IconStar size={22} />
        },
      ]
    }
  ];

  const isAccountingView = pathname.includes('/accounting');
  const isFinancesView = pathname.includes('/finances');
  const isStatisticsView = pathname.includes('/statistics');

  return (
    <>
      {/* Toggle button for small screens */}
      <button
        className="lg:hidden fixed top-4 right-4 z-[60] bg-hazel-green text-white p-2 rounded-full shadow-lg"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
      </button>

      {/* Overlay - only show on mobile */}
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={overlayVariants}
        className="lg:hidden fixed inset-0 bg-black/50 backdrop-blur-sm z-[45]"
        onClick={() => setIsOpen(false)}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      />

      {/* Sidebar */}
      <motion.div
        ref={sidebarRef}
        className={`
          bg-tealish-blue border-r border-gray-200 
          w-80 overflow-y-auto sidebar-scrollbar 
          fixed inset-y-0 left-0 z-[50]
          lg:relative lg:translate-x-0
          ${!isMobile ? 'block' : ''}
        `}
        initial={false}
        animate={isMobile ? {
          x: isOpen ? 0 : '-100%',
          display: isOpen ? 'block' : 'none'
        } : {
          x: 0,
          display: 'block'
        }}
        transition={{ 
          type: 'spring', 
          stiffness: 300, 
          damping: 30,
          duration: 0.3
        }}
      >
        {/* Logo and Header Section - Only visible on mobile */}
        <div className="lg:hidden sticky top-0 bg-tealish-blue z-10 border-b border-gray-200">
          <div className="flex items-center justify-between px-5 py-4">
            <div className="flex items-center space-x-3">
              <img src="/images/lake-side-logo.png" alt="logo" className="w-10 h-10" />
              <span className="text-xl font-semibold">
                <span className="text-watermelon">Lake</span>
                <span className="text-bluish-grey">Side</span>
              </span>
            </div>
            {/* Close button inside sidebar */}
            <button 
              onClick={toggleSidebar}
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
        </div>

        {/* Back to Categories - Only visible on mobile */}
        <div className="lg:hidden">
          {(isAccountingView || isFinancesView || isStatisticsView) && (
            <div className="px-5 py-2 border-b border-gray-200">
              <button 
                onClick={handleBackClick} 
                className='flex items-center text-bluish-grey w-full px-6 py-2 rounded hover:bg-gray-200 transition duration-200'
              >
                <IconArrowLeft size={16} className='mr-2' />
                Back to Categories
              </button>
            </div>
          )}
        </div>

        {/* Menu Items */}
        <div className="pb-20 pt-4">
          {sidebarMenu.map((item) => {
            if (
              (isAccountingView && item.group !== 'accounting') ||
              (isFinancesView && item.group !== 'finances') ||
              (isStatisticsView && item.group !== 'statistics')
            ) {
              return null;
            }

            return (
              <div key={item.group} className='px-5 mt-4'>
                <p className='text-bluish-grey uppercase mb-1.5 pl-6'>{item.group}</p>
                {item.items.map((subItem) => (
                  <div key={subItem.label}>
                    {(subItem.label === 'Dashboard' || 
                      (userRole.toLowerCase() === 'admin' && subItem.label.trim() !== 'In Time') ||
                      (userRole.toLowerCase() === 'owner' && checkUserHasPermission(userRole, userPermissions, subItem?.permission || [])) ||
                      (userRole.toLowerCase() !== 'admin' && checkUserHasPermission(userRole, userPermissions, subItem?.permission || []))
                    ) && (
                      <SidebarMenuItem
                        href={subItem.href}
                        label={subItem.label}
                        icon={subItem.icon}
                        isActive={subItem.label === 'Dashboard' ? pathname === '/' : isActive(subItem.href)}
                        onClick={() => setIsOpen(false)}
                      />
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </motion.div>
    </>
  );
}

export default Sidebar;