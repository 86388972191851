import api from './api';


// Venue APIs
export const getAllVenues = () => api.get('/api/venue/venues');
export const getVenue = (id) => api.get(`/api/venue/venues/${id}`);
export const createVenue = (values) => 
  api.post('/api/venue/venues', values);
export const updateVenue = (id ,values) =>
  api.patch(`/api/venue/venues/${id}`, values);
export const deleteVenue = (id) => api.delete(`/api/venue/venues/${id}`);



// Product APIs

// export const getAllProducts = () => api.get('/api/products');
// export const getProduct = (id) => api.get(`/api/products/${id}`);
// export const createProduct = (values) => 
//   api.post('/api/products', values);
// export const updateProduct = (id, values) =>
//   api.patch(`/api/products/${id}`, values);
// export const deleteProduct = (id) => api.delete(`/api/products/${id}`);