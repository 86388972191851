import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { updateTable, deleteTable } from '../../api/restaurantInterface';
import Table from './Table';
import { mergeTables, unmergeTable } from "../../api/restaurant";
import { getAssignedTables } from '../../api/waiter';
import { getTableRoomServiceOrders } from '../../api/orders';
import { IconList } from '@tabler/icons-react';
import RoomServiceOrdersSidebar from '../../components/RoomServiceOrdersSidebar';

function TableGrid({ environment }) {
    const [tablesDisplay, setTablesDisplay] = useState([]);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const [assignedTables, setAssignedTables] = useState([]);
    const [roomServiceTables, setRoomServiceTables] = useState({});
    const [showRoomServiceSidebar, setShowRoomServiceSidebar] = useState(false);
    const [roomServiceOrders, setRoomServiceOrders] = useState([]);
    
    const user = useSelector((state) => state?.auth?.user || null);

    console.log(user);

    useEffect(() => {
        if (user && user.id) {
            getAssignedTables(user.id)
                .then(response => {
                    if (response && response.data) {
                        const assignedTableIds = response.data.map(table => 
                            table.table ? table.table.id : table.id
                        );
                        setAssignedTables(assignedTableIds);
                    }
                })
                .catch(error => console.error('Failed to fetch assigned tables:', error));
        }
    }, [user]);

    // Responsive grid calculation
    const calculateInitialPositions = useCallback((tables) => {
        const BASE_TABLE_SIZE = containerWidth < 640 ? 80 : 100; // Smaller table sizes
        const GRID_GAP = containerWidth < 640 ? 8 : 12; // Smaller gaps
        const PADDING = 16;
        
        // Calculate available space
        const availableWidth = containerWidth - (PADDING * 2);
        const TABLES_PER_ROW = Math.max(2, Math.floor(availableWidth / (BASE_TABLE_SIZE + GRID_GAP)));
        
        return tables.map((table, index) => {
            // Only set new position if table doesn't have one or is outside bounds
            if (!table.position || 
                table.position.x > availableWidth || 
                table.position.y > window.innerHeight) {
                const row = Math.floor(index / TABLES_PER_ROW);
                const col = index % TABLES_PER_ROW;
                
                return {
                    ...table,
                    position: {
                        x: col * (BASE_TABLE_SIZE + GRID_GAP) + PADDING,
                        y: row * (BASE_TABLE_SIZE + GRID_GAP) + PADDING
                    }
                };
            }
            return table;
        });
    }, [containerWidth]);

    // Add container ref for scrolling
    const containerRef = React.useRef(null);

    // Update scroll area based on table positions
    useEffect(() => {
        if (containerRef.current && tablesDisplay.length > 0) {
            const maxX = Math.max(...tablesDisplay.map(t => t.position?.x || 0));
            const maxY = Math.max(...tablesDisplay.map(t => t.position?.y || 0));
            
            containerRef.current.style.minWidth = `${maxX + 200}px`;
            containerRef.current.style.minHeight = `${maxY + 200}px`;
        }
    }, [tablesDisplay]);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (environment.tables) {
            const tablesWithPositions = calculateInitialPositions(environment.tables);
            setTablesDisplay(tablesWithPositions);
            
            // Fetch room service orders for each table
            tablesWithPositions.forEach(async (table) => {
                try {
                    const orders = await getTableRoomServiceOrders(table.id);
                    if (orders && orders.length > 0) {
                        setRoomServiceTables(prev => ({
                            ...prev,
                            [table.id]: orders
                        }));
                    }
                } catch (error) {
                    console.error(`Error fetching room service orders for table ${table.id}:`, error);
                }
            });
        }
    }, [environment, calculateInitialPositions]);

    const fetchRoomServiceOrders = async () => {
        try {
            let orders = [];
            
            const promises = Object.keys(roomServiceTables).map(tableId => 
                getTableRoomServiceOrders(tableId)
                    .then(data => data || [])
                    .catch(err => {
                        console.error(`Error fetching orders for table ${tableId}:`, err);
                        return [];
                    })
            );
            
            const results = await Promise.all(promises);
            orders = results.flat();

            console.log('Fetched room service orders:', orders);
            
            setRoomServiceOrders(orders);
            setShowRoomServiceSidebar(true);
        } catch (error) {
            console.error('Error fetching room service orders:', error);
            // toast.error('Failed to fetch room service orders');
            // Set empty array on error to prevent map undefined error
            setRoomServiceOrders([]);
        }
    };

    const handleTableMove = async (tableId, newPosition) => {
        try {
            await updateTable(tableId, { position: newPosition });
            setTablesDisplay(prevTables =>
                prevTables.map(table =>
                    table.id === tableId
                        ? { ...table, position: newPosition }
                        : table
                )
            );
        } catch (error) {
            console.error('Failed to update table position:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteTable(id);
            setTablesDisplay((prevTables) => 
                prevTables.filter((table) => table.id !== id)
            );
        } catch (error) {
            console.error('Failed to delete table:', error);
        }
    };

    const handleMerge = async (table, data) => {
        const droppedTable = tablesDisplay.find((t) => t.id === table.id);
        const overlappedTable = tablesDisplay.find((t) => t.id !== table.id && isOverlap(data, t));

        if (overlappedTable) {
            try {
                const response = await mergeTables(droppedTable.id, overlappedTable.id);
                if (response.status === 200) {
                    const mergedTable = response.data;
                    if (mergedTable && mergedTable.id) {
                        setTablesDisplay((prevTables) =>
                            prevTables
                                .filter((t) => t.id !== overlappedTable.id && t.id !== droppedTable.id)
                                .concat(mergedTable)
                        );
                    } else {
                        console.error("Merged table is invalid:", mergedTable);
                    }
                } else {
                    console.error("Failed to merge tables:", response.message);
                }
            } catch (error) {
                console.error("Failed to merge tables:", error);
            }
        } else {
            console.warn("No overlapping table found for merging.");
        }
    };

    const handleUnmerge = async (mergedTableId) => {
        try {
            const response = await unmergeTable(mergedTableId);
            if (response.status === 200 && Array.isArray(response.data.originalTables)) {
                const originalTables = response.data.originalTables;
                setTablesDisplay(prevTables => {
                    const filteredTables = prevTables.filter(table => table.id !== mergedTableId);
                    return [...filteredTables, ...originalTables];
                });
            } else {
                console.error("Unmerge failed:", response.message);
                if (response.data.originalTables) {
                    console.error("Original Tables:", response.data.originalTables);
                } else {
                    console.error("Original Tables not found in response");
                }
            }
        } catch (error) {
            console.error("Error in unmerging table:", error);
        }
    };

    const isOverlap = (data, table) => {
        const draggedRect = data.node.getBoundingClientRect();
        const tableElement = document.querySelector(`[data-table-id='${table.id}']`);

        if (!tableElement) {
            console.warn(`Element with data-table-id ${table.id} not found`);
            return false;
        }

        const tableRect = tableElement.getBoundingClientRect();

        return !(
            draggedRect.right < tableRect.left ||
            draggedRect.left > tableRect.right ||
            draggedRect.bottom < tableRect.top ||
            draggedRect.top > tableRect.bottom
        );
    };

    return (
        <div className="relative w-full h-[calc(100vh-120px)] overflow-auto bg-gray-50 rounded-lg 
            scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100
            hover:scrollbar-thumb-gray-500
            scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            
            {/* Room Service Orders Button */}
            <div className="sticky top-0 z-10 bg-white shadow-sm p-4">
                <button
                    onClick={fetchRoomServiceOrders}
                    className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors"
                >
                    <IconList size={20} />
                    <span>View Room Service Orders</span>
                </button>
            </div>

            <div 
                ref={containerRef}
                className="absolute inset-0 pt-16 p-4 min-h-[500px]
                    [&::-webkit-scrollbar]:w-2
                    [&::-webkit-scrollbar-track]:bg-gray-100
                    [&::-webkit-scrollbar-thumb]:bg-gray-400
                    [&::-webkit-scrollbar-thumb]:rounded-full
                    [&::-webkit-scrollbar-track]:rounded-full
                    [&::-webkit-scrollbar-thumb]:hover:bg-gray-500"
            >
                <div className="relative w-full h-full">
                    {tablesDisplay.map((table) => (
                        <Table
                            key={table.id}
                            table={table}
                            onDelete={handleDelete}
                            onMerge={handleMerge}
                            onUnmerge={handleUnmerge}
                            onMove={handleTableMove}
                            position={table.position}
                            isAssigned={assignedTables.includes(table.id)}
                            containerWidth={containerWidth}
                            hasRoomService={Boolean(roomServiceTables[table.id]?.length)}
                        />
                    ))}
                </div>
            </div>

            <RoomServiceOrdersSidebar 
                show={showRoomServiceSidebar}
                onClose={() => setShowRoomServiceSidebar(false)}
                orders={roomServiceOrders}
            />
        </div>
    );
}

export default TableGrid;