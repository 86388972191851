import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import Modal from '../../components/modal';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import { fetcher } from '../../api/api';
import { deleteEmployee, getEmployees } from '../../api/employee';
import { getJobTitles, createJobTitle, deleteJobTitle, updateJobTitle } from '../../api/jobTitles';

function EmployeeList() {
  const [activeJobTitle, setActiveJobTitle] = useState('');
  const [activeTab, setActiveTab] = useState('job-titles');
  const [employees, setEmployees] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [newJobTitle, setNewJobTitle] = useState('');
  const [showJobTitleModal, setShowJobTitleModal] = useState(false);
  const [editingJobTitle, setEditingJobTitle] = useState(null);
  const [jobTitleToDelete, setJobTitleToDelete] = useState(null);
  const [showDeleteJobTitleAlert, setShowDeleteJobTitleAlert] = useState(false);

  const { data: jobTitles = [], error: jobTitlesError, isValidating: isValidatingJobTitles, mutate: mutateJobTitles } = useSWR('/api/job-titles', fetcher);

  const { data: employeeData, error: employeeError, isValidating: isValidatingEmployees, mutate: mutateEmployees } = useSWR(
    activeTab === 'job-titles' && activeJobTitle ? `/api/employees?jobTitle=${activeJobTitle}` : null,
    fetcher
  );

  useEffect(() => {
    console.log('Active Job Title:', activeJobTitle);
  }, [activeJobTitle]);

  // Set the default job title on initial load
  useEffect(() => {
    if (jobTitles) {
      setActiveJobTitle(jobTitles[0]?.id || '');
      console.log(jobTitles)
    }
  }, [jobTitles]);

  // Update employees whenever `employeeData` changes
  useEffect(() => {
    if (employeeData) {
      setEmployees(employeeData);
      console.log(employeeData)
    }
  }, [employeeData]);

  const handleDeleteEmployee = async () => {
    try {
      await deleteEmployee(selectedEmployeeId);
      setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== selectedEmployeeId));
      setShowDeleteAlert(false);
      mutateEmployees(); // Ensure the SWR cache is updated
    } catch (err) {
      console.error('Failed to delete employee:', err);
    }
  };

  const handleEditJobTitle = (jobTitle) => {
    setEditingJobTitle(jobTitle);
    setNewJobTitle(jobTitle.title);
    setShowJobTitleModal(true);
  };

  const handleSaveJobTitle = async () => {
    try {
      if (editingJobTitle) {
        // Update existing job title
        await updateJobTitle(editingJobTitle.id, { title: newJobTitle });
      } else {
        // Create new job title
        await createJobTitle(newJobTitle);
      }
      setNewJobTitle('');
      setEditingJobTitle(null);
      setShowJobTitleModal(false);
      mutateJobTitles(); // Re-fetch job titles
    } catch (err) {
      console.error('Failed to save job title:', err);
    }
  };

  const handleDeleteJobTitle = async () => {
    try {
      await deleteJobTitle(jobTitleToDelete);
      setJobTitleToDelete(null);
      setShowDeleteJobTitleAlert(false);
      mutateJobTitles(); // Re-fetch job titles
    } catch (err) {
      console.error('Failed to delete job title:', err);
    }
  };

  const closeModal = () => {
    setShowJobTitleModal(false);
    setEditingJobTitle(null);
    setNewJobTitle('');
  };

  if (jobTitlesError || employeeError) return <div>Error loading data...</div>;
  if (isValidatingJobTitles || isValidatingEmployees) return <Loading />;

  const handleJobTitleClick = (jobTitleId) => {
    setActiveJobTitle(jobTitleId);
    setActiveTab('job-titles');
  };

  return (
    <div className='p-4 sm:p-6 md:p-10'>
      <div className='flex flex-col sm:flex-row justify-end gap-3 sm:gap-x-3 mb-8'>
        <PermissionCheck action='add_employees'>
          <Link to={`/employees/create-employee?jobTitle=${activeJobTitle}`} className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto'>
            <IconPlus className='mr-2' size={20} />
            Add Employee
          </Link>
        </PermissionCheck>
        <PermissionCheck action='add_job_titles'>
          <button
            onClick={() => {
              setEditingJobTitle(null);
              setNewJobTitle('');
              setShowJobTitleModal(true);
            }}
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto'
          >
            <IconPlus className='mr-2' size={20} />
            Add Job Title
          </button>
        </PermissionCheck>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-2 sm:px-3 mt-5'>
        <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-auto py-2 scrollbar-thin scrollbar-thumb-gray-300'>
          <div className='flex items-center gap-2'>
            {jobTitles?.map((jobTitle) => (
              <div
                key={jobTitle.id}
                className={`flex items-center px-3 sm:px-5 py-2.5 sm:py-3.5 whitespace-nowrap ${
                  jobTitle.id === activeJobTitle && activeTab === 'job-titles' 
                  ? 'bg-seashell bg-opacity-30 rounded-md' 
                  : ''
                } cursor-pointer`}
                onClick={() => handleJobTitleClick(jobTitle.id)}
              >
                <p className='text-bluish-grey capitalize text-sm sm:text-base ml-2'>{jobTitle.title}</p>
                <IconTrash
                  className='ml-2 text-bluish-grey cursor-pointer'
                  size={18}
                  onClick={() => {
                    setJobTitleToDelete(jobTitle.id);
                    setShowDeleteJobTitleAlert(true);
                  }}
                />
                <IconPencil
                  className='ml-2 text-bluish-grey cursor-pointer'
                  size={18}
                  onClick={() => handleEditJobTitle(jobTitle)}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className='flex justify-end items-center bg-white py-2.5 px-3 sm:px-5 gap-x-5'>
          {activeTab === 'job-titles' && (
            <PermissionCheck action='edit_employees'>
              <Link to={`/employees/edit/${selectedEmployeeId}`}>
                <IconPencil className='text-sage' size={20} />
              </Link>
            </PermissionCheck>
          )}
        </div> */}
        {activeTab === 'job-titles' ? (
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 sm:px-3'>
            <PermissionCheck action='add_employees'>
              <Link 
                to={`/employees/create-employee?jobTitle=${activeJobTitle}`} 
                className='flex justify-center items-center p-5 bg-seashell rounded-md'
              >
                <IconPlus className='text-bluish-grey font-bold cursor-pointer' size={24} />
              </Link>
            </PermissionCheck>
            
            {employees?.length ? (
              employees.map((employee) => (
                <div key={employee.id} className='flex flex-col items-center p-4 sm:p-5 rounded-md bg-seashell'>
                  <p className='text-bluish-grey text-lg sm:text-xl font-medium text-center'>{employee.name}</p>
                  <div className='flex mt-2 gap-x-3'>
                    <PermissionCheck action='edit_employees'>
                      <Link to={`/employees/edit-employee/${employee.id}`}>
                        <IconPencil className='text-bluish-grey cursor-pointer' size={18} />
                      </Link>
                    </PermissionCheck>
                    <PermissionCheck action='delete_employees'>
                      <IconTrash
                        className='text-bluish-grey cursor-pointer'
                        size={18}
                        onClick={() => {
                          setSelectedEmployeeId(employee.id);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </PermissionCheck>
                  </div>
                </div>
              ))
            ) : (
              <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 text-center col-span-full'>
                No employees available for this job title!
              </p>
            )}
          </div>
        ) : (
          <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 text-center'>
            No employees available!
          </p>
        )}
      </div>
      
      {/* Job Title Modal */}
      {showJobTitleModal && (
        <Modal onClose={closeModal} className='bg-seashell p-6 rounded-lg shadow-lg'>
          <h2 className='text-xl font-bold mb-4 text-bluish-grey'>
            {editingJobTitle ? 'Edit Job Title' : 'Add New Job Title'}
          </h2>
          <input
            type='text'
            value={newJobTitle}
            onChange={(e) => setNewJobTitle(e.target.value)}
            placeholder='Enter job title'
            className='w-full p-2 border border-grey-goose rounded mb-4'
          />
          <div className='flex justify-end space-x-2'>
            <button
              onClick={closeModal}
              className='px-4 py-2 border border-silver rounded text-bluish-grey'
            >
              Cancel
            </button>
            <button
              onClick={handleSaveJobTitle}
              className='px-4 py-2 bg-sage text-white rounded hover:bg-hazel-green'
            >
              Save Changes
            </button>
          </div>
        </Modal>
      )}
      
      {/* Confirm Delete Employee */}
      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this employee?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={handleDeleteEmployee}
        />
      )}

      {/* Confirm Delete Job Title */}
      {showDeleteJobTitleAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this job title?'
          onCancel={() => setShowDeleteJobTitleAlert(false)}
          onConfirm={handleDeleteJobTitle}
        />
      )}
    </div>
  );
}

export default EmployeeList;
