import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import VenueForm from './venueForm';

function AddVenueForm() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Add New Venue'
      />
      <VenueForm isEdit={false} />
    </div>
  );
}

export default AddVenueForm;
