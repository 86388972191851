import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import { getRestaurants } from "../../api/restaurant";
import { deleteDishSection } from "../../api/dishSection";

function DishesList() {
  const [restaurants, setRestaurants] = useState([]);
  const [activeRestaurant, setActiveRestaurant] = useState(null);
  const [hoveredDish, setHoveredDish] = useState(null);
  const [showDishDeleteAlert, setShowDishDeleteAlert] = useState(false);
  const [selectedDish, setSelectedDish] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchRestaurants = async () => {
    try {
      const response = await getRestaurants();
      setRestaurants(response.data);
      setActiveRestaurant(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch restaurants:', error);
      setLoading(false);
    }
  };

  const handleDeleteDish = async (dishId) => {
    try {
      setLoading(true);
      await deleteDishSection(dishId);
      await fetchRestaurants();
      setShowAlert(false);
      setShowDishDeleteAlert(false);
    } catch (error) {
      console.error('Failed to delete dish:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <div className='flex justify-end gap-x-3 mb-8'>
        <PermissionCheck action='edit_rooms'>
          <Link
            to='/dishSection/add-dish'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New dish
          </Link>
        </PermissionCheck>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
        {restaurants.length > 0 ? (
          <>
            <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-scroll no-scrollbar'>
              <div className='flex items-center w-48'>
                {restaurants.map((tab) => (
                  <div key={tab.id}>
                    <div
                      className={`flex items-center px-5 py-3.5 ${
                        tab.id === activeRestaurant?.id
                          ? 'bg-seashell bg-opacity-30 rounded-md'
                          : ''
                      } cursor-pointer`}
                      onClick={() => setActiveRestaurant(tab)}
                    >
                      <p className='text-bluish-grey capitalize ml-2'>
                        {tab.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-4'>
              <PermissionCheck action='edit_room_types'>
                <Link
                  to={`/dishes/add-dish?restaurantId=${activeRestaurant?.id}`}
                  className='flex justify-center items-center p-2 bg-seashell rounded-md h-24 sm:h-28'
                >
                  <IconPlus
                    className='text-bluish-grey font-bold cursor-pointer'
                    size={20}
                  />
                </Link>
              </PermissionCheck>
              {activeRestaurant?.menus?.flatMap(menu => (
                menu.dishSections.map((dishSection) => (
                  <div
                    key={dishSection.id}
                    className={`flex ${
                      hoveredDish === dishSection.id
                        ? 'justify-center'
                        : 'justify-between'
                    } items-center rounded-md bg-seashell w-full h-24 sm:h-28`}
                    onMouseEnter={() => setHoveredDish(dishSection.id)}
                    onMouseLeave={() => setHoveredDish(null)}
                  >
                    {hoveredDish === dishSection.id ? (
                      <div className='flex justify-center items-center bg-white py-2 px-4 gap-x-4 rounded'>
                        <PermissionCheck action='edit_rooms'>
                          <Link to={`/dish-sections/edit/${dishSection.id}`}>
                            <IconPencil className='text-bluish-grey' size={16} />
                          </Link>
                        </PermissionCheck>
                        <PermissionCheck action='delete_rooms'>
                          <IconTrash
                            className='text-bluish-grey cursor-pointer'
                            size={16}
                            onClick={() => {
                              setSelectedDish(dishSection.id);
                              setShowAlert(true);
                            }}
                          />
                        </PermissionCheck>
                      </div>
                    ) : (
                      <>
                        <img
                          src={`http://127.0.0.1:3001${dishSection.imageUrl}`}
                          alt=''
                          className='w-16 h-16 sm:w-24 sm:h-24 rounded-md'
                        />
                        <p className='text-bluish-grey font-medium w-full text-center text-xs sm:text-sm'>
                          {dishSection.name}
                        </p>
                      </>
                    )}
                  </div>
                ))
              ))}
            </div>
          </>
        ) : (
          <p className='text-sage font-bold py-10 px-8'>
            There is no data to show!
          </p>
        )}
      </div>
      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          onCancel={() => setShowAlert(false)}
          onConfirm={() => handleDeleteDish(selectedDish)}
        />
      )}
      {showDishDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this dish?'
          text='Once you delete this dish, it cannot be undone'
          onCancel={() => setShowDishDeleteAlert(false)}
          onConfirm={() => handleDeleteDish(selectedDish)}
        />
      )}
    </div>
  );
}

export default DishesList;
