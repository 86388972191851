import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTable from '../../components/table-components/table';
import { PrintButton } from './PrintButton';
import { getPoolReservations } from '../../api/pools';
import IndoorPoolDetailsModal from '../../components/modals/IndoorPoolDetailsModal';
import TicketManagementModal from '../../components/modals/TicketManagementModal';

function PoolHistory() {
  const printRef = useRef();
  const params = useParams();
  const poolId = params.id;
  const [poolReservations, setPoolReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  useEffect(() => {
    async function fetchReservations() {
      setLoading(true);
      try {
        const reservations = await getPoolReservations(poolId);
        console.log('PoolHistory - Fetched reservations:', reservations.data);
        const groupedReservations = groupByDateAndType(reservations.data);
        setPoolReservations(groupedReservations);
        setTotalCount(groupedReservations.length);
      } catch (error) {
        console.error('Failed to fetch pool reservations:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchReservations();
  }, [poolId]);

  useEffect(() => {
    console.log('Modal state changed:', { isTicketModalOpen, selectedTicket });
    if (selectedTicket) {
      console.log('Selected ticket details:', selectedTicket);
    }
  }, [isTicketModalOpen, selectedTicket]);

  const groupByDateAndType = (data) => {
    const groupedData = data.reduce((acc, reservation) => {
      const date = new Date(reservation.createdAt).toLocaleDateString();
      
      if (!acc[date]) {
        acc[date] = { 
          date, 
          woman: { count: 0, totalPrice: 0 }, 
          man: { count: 0, totalPrice: 0 }, 
          kid: { count: 0, totalPrice: 0 },
          standard: { 
            count: 0, 
            totalPrice: 0,
            reservations: []
          }
        };
      }
      
      const ticketPrice = parseFloat(reservation.price);

      switch(reservation.ticketType) {
        case 'WOMAN':
          acc[date].woman.count += 1;
          acc[date].woman.totalPrice += ticketPrice;
          break;
        case 'MAN':
          acc[date].man.count += 1;
          acc[date].man.totalPrice += ticketPrice;
          break;
        case 'KID':
          acc[date].kid.count += 1;
          acc[date].kid.totalPrice += ticketPrice;
          break;
        case 'STANDARD':
          acc[date].standard.count += 1;
          acc[date].standard.totalPrice += ticketPrice;
          acc[date].standard.reservations.push({
            name: reservation.name,
            surname: reservation.surname,
            personalNumber: reservation.personalNumber,
            phoneNumber: reservation.phoneNumber,
            startTime: reservation.startTime,
            endTime: reservation.endTime,
            price: ticketPrice,
            ticketType: reservation.ticketType,
            status: reservation.status || 'PENDING_CHECKIN',
            actualCheckIn: reservation.actualCheckIn,
            actualCheckOut: reservation.actualCheckOut,
            duration: reservation.duration,
            extraCharges: reservation.extraCharges,
            ticketReference: reservation.ticketReference
          });
          break;
      }
      return acc;
    }, {});
  
    console.log('Final groupedData:', groupedData);
    return Object.values(groupedData);
  };

  const handleViewDetails = (item) => {
    setSelectedDate(item.date);
    setSelectedReservations(item.standard.reservations);
    setIsModalOpen(true);
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsTicketModalOpen(true);
  };

  const handleTicketUpdate = async () => {
    try {
      console.log('Updating ticket data...');
      const response = await getPoolReservations(poolId);
      
      if (response.data) {
        console.log('Got new reservations:', response.data);
        const groupedReservations = groupByDateAndType(response.data);
        console.log('Grouped reservations:', groupedReservations);
        
        setPoolReservations(groupedReservations);
        setTotalCount(groupedReservations.length);
        
        // Update the selected reservations if modal is open
        if (isModalOpen && selectedDate) {
          console.log('Updating modal data for date:', selectedDate);
          const updatedDateGroup = groupedReservations.find(group => group.date === selectedDate);
          if (updatedDateGroup) {
            console.log('Found updated group:', updatedDateGroup);
            setSelectedReservations(updatedDateGroup.standard.reservations);
          }
        }
      }
    } catch (error) {
      console.error('Failed to update reservations:', error);
      toast.error('Failed to refresh ticket data');
    }
  };

  const handleCloseTicketModal = () => {
    console.log('Closing ticket modal');
    setIsTicketModalOpen(false);
    setTimeout(() => {
      setSelectedTicket(null);
    }, 300); // Wait for animation to complete
  };

  return (
    <div className='p-10'>
      <CustomTable
        rows={poolReservations}
        columns={[
          {
            title: 'Date',
            renderValue: (item) => item.date
          },
          {
            title: 'Women',
            renderValue: (item) => 
              <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                <span className='font-normal text-black'>{item.woman.count}</span>
                <span>({item.woman.totalPrice}€)</span>
              </span>
          },
          {
            title: 'Men',
            renderValue: (item) => 
              <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                <span className='font-normal text-black'>{item.man.count}</span>
                <span>({item.man.totalPrice}€)</span>
              </span>
          },
          {
            title: 'Kids',
            renderValue: (item) => 
              <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                <span className='font-normal text-black'>{item.kid.count}</span>
                <span>({item.kid.totalPrice}€)</span>
              </span>
          },
          {
            title: 'Indoor Pool',
            renderValue: (item) => 
              <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                <span className='font-normal text-black'>{item.standard.count}</span>
                <span>({item.standard.totalPrice}€)</span>
              </span>
          },
          {
            title: 'Total',
            renderValue: (item) => {
              const total = item.woman.totalPrice + item.man.totalPrice + 
                          item.kid.totalPrice + item.standard.totalPrice;
              return (
                <span className="font-bold text-hazel-green">
                  {total}€
                </span>
              );
            }
          },
          {
            title: 'Details',
            renderValue: (item) => item.standard.count > 0 && (
              <button
                onClick={() => handleViewDetails(item)}
                className="p-2 rounded-full hover:bg-seashell transition-colors text-bluish-grey hover:text-hazel-green"
                title="View Indoor Pool Details"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </button>
            )
          },
          {
            title: 'Actions',
            renderValue: (item) => (
              <PrintButton 
                item={{ 
                  ...item, 
                  poolId, 
                  totalPrice: item.woman.totalPrice + item.man.totalPrice + 
                            item.kid.totalPrice + item.standard.totalPrice 
                }} 
              >
                Print
              </PrintButton>
            )
          }
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />

      {isModalOpen && selectedDate && (
        <IndoorPoolDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          reservations={selectedReservations}
          date={selectedDate}
          onTicketClick={handleTicketClick}
          onUpdate={handleTicketUpdate}
        />
      )}

      {isTicketModalOpen && selectedTicket && (
        <TicketManagementModal
          isOpen={isTicketModalOpen}
          onClose={() => setIsTicketModalOpen(false)}
          reservation={selectedTicket}
          onUpdate={handleTicketUpdate}
        />
      )}
    </div>
  );
}

export default PoolHistory;
