import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    // Log the entire user object to see its structure
    console.log('Current user object:', user);
    
    // Check both jobRole and role.title
    const isPastruseRole = 
      user?.jobRole?.toLowerCase() === 'pastruese' || 
      user?.role?.title?.toLowerCase() === 'pastruese';
    
    console.log('jobRole:', user?.jobRole);
    console.log('role.title:', user?.role?.title);
    console.log('Is Pastruse Role:', isPastruseRole);
    
    if (isPastruseRole) {
      console.log('Switching to Albanian (sq)');
      i18n.changeLanguage('sq').then(() => {
        console.log('Current language after change:', i18n.language);
      });
    } else {
      console.log('Switching to English (en)');
      i18n.changeLanguage('en').then(() => {
        console.log('Current language after change:', i18n.language);
      });
    }
  }, [user, i18n]);

  return (
    <LanguageContext.Provider value={{ i18n }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageContext;
