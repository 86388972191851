import React, { useState, useEffect } from 'react';
import {
  Cut,
  Line,
  Printer,
  Text,
  render,
  QRCode,
  Image
} from "react-thermal-printer";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { 
  User2, 
  UserRound, 
  Baby, 
  Ticket, 
  Clock, 
  EuroIcon,
  Plus,
  Minus
} from 'lucide-react';
import { SubmitButton } from '../../components/buttons';
import { getPools, createPoolReservation } from "../../api/poolsInterface";
import { generateINPFile } from '../../api/inpFile';
import { getPort, closePort } from '../pools/serialPortManager';

const AddTicket = ({ poolType, poolId }) => {
  const [pools, setPools] = useState({});
  const [ticketCounts, setTicketCounts] = useState({});

  useEffect(() => {
    async function fetchPools() {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          acc[pool.type] = {
            id: pool.id,
            tickets: pool.tickets
          };
          return acc;
        }, {});
        setPools(poolsData);

        // Initialize ticket counts
        const initialCounts = {};
        poolsData[poolType]?.tickets.forEach(ticket => {
          initialCounts[ticket.title] = 0;
        });
        setTicketCounts(initialCounts);
      } catch (error) {
        console.error('Failed to fetch pools:', error);
      }
    }

    fetchPools();
  }, [poolType]);

  const getTicketIcon = (type) => {
    switch(type.toLowerCase()) {
      case 'man':
        return <User2 className="w-8 h-8 text-bluish-grey" />;
      case 'woman':
        return <UserRound className="w-8 h-8 text-sage" />;
      case 'kid':
        return <Baby className="w-8 h-8 text-hazel-green" />;
      default:
        return <Ticket className="w-8 h-8 text-grey-goose" />;
    }
  };

  const handleCountChange = (title, increment) => {
    setTicketCounts(prev => ({
      ...prev,
      [title]: Math.max(0, (prev[title] || 0) + increment)
    }));
  };

  const handlePrint = async (data) => {
    const { id, price, ticketType } = data;
    const reservationData = { id, price, ticketType };
    await generateINPFile(reservationData);

    const receiptContent = await render(
      <Printer type="epson">
        <Image align="center" src="https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448909287_2753223888169196_8294231871019942389_n.png?_nc_cat=107&ccb=1-7&_nc_sid=9f807c&_nc_ohc=kRZLE2CpIIoQ7kNvgG6yT1M&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QF3sALjRmEr7_HfRKC4hW1sIF0FwdL1lkkFi49tCtB5ug&oe=66B71D73" />
        <Line />
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>ÇMIMI: {data.price} Euro</Text>
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>DATA:ORA: {new Date().toLocaleString()}</Text>
        <Line />
        <QRCode align="center" size={{ width: 1.8, height: 1.8 }} content="https://lakesideks.com/" />
        <Cut lineFeeds={7} />
      </Printer>
    );

    const port = await navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    if (port.readable && port.writable) {
      const writer = port.writable.getWriter();
      const encodedData = new Uint8Array(receiptContent);
      await writer.write(encodedData);
      await writer.releaseLock();

      setTimeout(async () => {
        await closePort();
        await port.close();
      }, 2000);
    }
  };

  const getTotalAmount = () => {
    return pools[poolType]?.tickets.reduce((total, ticket) => {
      return total + (ticketCounts[ticket.title] || 0) * ticket.price;
    }, 0) || 0;
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 gap-6">
        {pools[poolType]?.tickets?.map((item, index) => (
          <div 
            key={index}
            className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 transition-all duration-200 hover:shadow-md"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {getTicketIcon(item.title)}
                <div>
                  <h3 className="text-lg font-semibold capitalize text-gray-900">
                    {item.title} Ticket
                  </h3>
                  <div className="flex items-center space-x-2 text-gray-600">
                    <EuroIcon className="w-4 h-4" />
                    <span>{item.price.toFixed(2)}</span>
                    <Clock className="w-4 h-4 ml-2" />
                    <span>Valid today</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  type="button"
                  onClick={() => handleCountChange(item.title, -1)}
                  className="p-2 rounded-full bg-red-25 text-watermelon hover:bg-dawn-pink transition-colors"
                >
                  <Minus className="w-5 h-5" />
                </button>
                <span className="w-12 text-center font-semibold text-lg">
                  {ticketCounts[item.title] || 0}
                </span>
                <button
                  type="button"
                  onClick={() => handleCountChange(item.title, 1)}
                  className="p-2 rounded-full bg-seashell text-hazel-green hover:bg-grey-goose transition-colors"
                >
                  <Plus className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {getTotalAmount() > 0 && (
        <div className="mt-8 bg-tealish-blue rounded-xl p-6">
          <div className="flex justify-between items-center mb-4">
            <div className="text-gray-700">
              <h4 className="text-lg font-semibold">Total Amount</h4>
              <p className="text-sm text-gray-500">Including all tickets</p>
            </div>
            <div className="text-2xl font-bold text-hazel-green">
              €{getTotalAmount().toFixed(2)}
            </div>
          </div>
          
          <Formik
            initialValues={{
              ticketTitle: '',
              price: getTotalAmount()
            }}
            validationSchema={Yup.object().shape({
              ticketTitle: Yup.string(),
              price: Yup.number()
            })}
            onSubmit={async (values, { setErrors, setSubmitting }) => {
              try {
                // Create a reservation for each ticket type with count > 0
                for (const [title, count] of Object.entries(ticketCounts)) {
                  if (count > 0) {
                    const ticket = pools[poolType].tickets.find(t => t.title === title);
                    if (ticket) {
                      const payload = {
                        poolId,
                        ticketType: title.toUpperCase(),
                        price: ticket.price,
                        count: count
                      };
                      await createPoolReservation(payload);
                      await handlePrint(payload);
                    }
                  }
                }
                // Reset counts after successful submission
                setTicketCounts({});
                setSubmitting(false);
              } catch (error) {
                setErrors({ submit: 'Failed to create reservation' });
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width="w-full"
                  title="Issue Tickets"
                  className="bg-hazel-green hover:bg-sage text-white transition-colors"
                />
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default AddTicket;
