import React, { useState } from 'react';
import { createInvoice } from '../../api/invoiceSale';
import { createTransaction } from '../../api/accounting';

function PoolInvoiceModal({ isOpen, onClose, reservation, date }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isOpen) return null;

  const handleCreateInvoice = async () => {
    try {
      setIsSubmitting(true);

      // Create invoice data
      const invoiceData = {
        date: date,
        customerName: reservation.name + ' ' + reservation.surname,
        customerPhone: reservation.phoneNumber,
        personalNumber: reservation.personalNumber,
        items: [{
          description: `Pool Ticket (${reservation.ticketType})`,
          quantity: 1,
          price: reservation.price,
          total: reservation.price
        }],
        totalAmount: reservation.price,
        type: 'POOL',
        status: 'PAID'
      };

      // Create invoice
      const invoice = await createInvoice(invoiceData);

      // Create transaction record
      const transactionData = {
        date: date,
        description: `Pool Ticket Invoice #${invoice.data.id}`,
        amount: reservation.price,
        type: 'INCOME',
        category: 'POOL_TICKET',
        reference: invoice.data.id,
        status: 'COMPLETED'
      };

      await createTransaction(transactionData);

      onClose();
    } catch (error) {
      console.error('Failed to create invoice:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black opacity-30" onClick={onClose} />

        <div className="relative bg-seashell rounded-lg w-full max-w-md mx-4 p-6 shadow-xl">
          <div className="flex justify-between items-center mb-6 border-b border-grey-goose pb-4">
            <h2 className="text-lg font-medium text-bluish-grey">
              Create Invoice
            </h2>
            <button
              onClick={onClose}
              className="text-hazel-green hover:text-bluish-grey transition-colors"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <p className="text-sm text-bluish-grey">
                <span className="font-medium">Customer:</span> {reservation.name} {reservation.surname}
              </p>
              <p className="text-sm text-bluish-grey">
                <span className="font-medium">Phone:</span> {reservation.phoneNumber}
              </p>
              <p className="text-sm text-bluish-grey">
                <span className="font-medium">Personal Number:</span> {reservation.personalNumber}
              </p>
              <p className="text-sm text-bluish-grey">
                <span className="font-medium">Amount:</span> {reservation.price}€
              </p>
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-bluish-grey hover:text-sage transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateInvoice}
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-hazel-green hover:bg-sage transition-colors rounded-md disabled:opacity-50"
              >
                {isSubmitting ? 'Creating...' : 'Create Invoice'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoolInvoiceModal;
