import React, { useState, useEffect } from 'react';
import Loading from '../../components/loader';
import Food from '../../components/Food';
import BarMenu from '../../components/BarMenu';
import { getDishSectionsWithMenu } from '../../api/menus';
import { getBarProducts } from '../../api/barProduct';
import { getMenu } from '../../api/menus';
import { SlidingMenuBar } from '../../components/SlidingMenuBar';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';

function MenuPage() {
  const navigate = useNavigate();
  const [menus, setMenus] = useState([]);
  const [showMenuSelector, setShowMenuSelector] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [dishSections, setDishSections] = useState([]);
  const [barProducts, setBarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('FOODS');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const dishResponse = await getDishSectionsWithMenu();
        setDishSections(dishResponse?.data?.message || []);

        const menusResponse = await getMenu();
        setMenus(menusResponse.data || []);

        const barResponse = await getBarProducts();
        setBarProducts(barResponse.data || []);
      } catch (error) {
        console.error('MenuPage: Failed to fetch data:', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleMenuChange = async (menuId) => {
    try {
      setLoading(true);
      const dishResponse = await getDishSectionsWithMenu(menuId);
      setDishSections(dishResponse?.data || []);
      setSelectedMenuId(menuId);
    } catch (error) {
      console.error('Failed to fetch menu data:', error);
      setError('Failed to fetch menu data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="p-4 flex flex-col sm:flex-row justify-between items-center border-b border-gray-200">
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            onClick={() => setShowMenuSelector(!showMenuSelector)}
            className="px-4 py-2 text-sm font-medium text-white bg-hazel-green border 
              border-none rounded-md hover:bg-sage transition duration-300 ease-in-out transform hover:scale-105"
          >
            {showMenuSelector ? 'Show Default Menu' : 'Select Specific Menu'}
          </button>

          {showMenuSelector && (
            <select
              value={selectedMenuId || ''}
              onChange={(e) => handleMenuChange(e.target.value)}
              className="px-3 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none 
                focus:ring-2 focus:ring-hazel-green focus:border-transparent transition duration-300 ease-in-out"
            >
              {menus.map((menu) => (
                <option key={menu.id} value={menu.id}>
                  {menu.name}
                </option>
              ))}
            </select>
          )}

          <SlidingMenuBar
            onTabChange={(tabId) => setActiveTab(tabId)}
          />
        </div>
        
        <button
          onClick={() => navigate('/menus/create-menu')}
          className="font-semibold mt-2 sm:mt-0 bg-hazel-green hover:bg-sage text-white 
            px-4 py-2 text-sm rounded-xl flex items-center space-x-2 transition-colors duration-150 transform hover:scale-105"
        >
          <IconPlus size={20} />
          <span>New Menu</span>
        </button>
      </div>
  
      <div className="flex-1 overflow-auto">
        {activeTab === 'FOODS' ? (
          <Food 
            dishSections={dishSections}
            selectedMenuId={selectedMenuId}
          />
        ) : (
          <BarMenu 
            barProducts={barProducts}
            selectedMenuId={selectedMenuId}
          />
        )}
      </div>
    </div>
  );
}

export default MenuPage;
