import React, { useState } from 'react';
import SelectInput from './select-input'; // Import the SelectInput component

function BarMenu({ barProducts }) {
  const [filteredProducts, setFilteredProducts] = useState(barProducts);
  const [selectedType, setSelectedType] = useState('all');

  const uniqueTypes = [...new Set(barProducts.map(product => product.type))];

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    if (type === 'all') {
      setFilteredProducts(barProducts);
    } else {
      const filtered = barProducts.filter(product => product.type === type);
      setFilteredProducts(filtered);
    }
  };

  return (
    <div className='m-auto px-6 py-12'>
      <h1 className='italic text-hazel-green font-bold text-4xl text-center'>
        Bar Menu
      </h1>

      {/* Filter Row */}
      <div className='flex flex-col lg:flex-row justify-between'>
        {/* Filter Type */}
        <div>
          <p className='font-bold text-gray-700'>Filter Type</p>
          <SelectInput
            value={selectedType}
            label="Select Type"
            options={[{ id: 'all', title: 'All' }, ...uniqueTypes.map(type => ({ id: type, title: type }))]}
            onChange={handleTypeChange}
            isEntity={false}
          />
        </div>
      </div>

      {/* Display bar products */}
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className='border shadow-lg rounded-lg hover:scale-105 duration-300'
          >
            <img
              src={
                product.imageUrl
                  ? `https://api-staff.lakesideks.com${product.imageUrl}`
                  : '/images/default-placeholder.png'
              }
              alt={product.name || 'Bar Product'}
              className='w-full h-[150px] sm:h-[200px] object-cover rounded-t-lg'
            />
            <div className='flex flex-col px-4 py-4 gap-2'>
              <p className='font-bold text-lg sm:text-xl'>{product.name}</p>
              <p className='text-gray-600 text-sm'>{product.description}</p>
              <p className='text-gray-600 text-sm'><strong>Type:</strong> {product.type}</p>
              <p className='text-gray-600 text-sm'><strong>Available:</strong> {product.isAvailable ? 'Yes' : 'No'}</p>
              <p className='font-bold'>
                <span className='bg-hazel-green text-white p-1 px-4 rounded-full'>
                  {product.price}$
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BarMenu;
