import { useState } from 'react';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Plus, Filter } from 'lucide-react';
import { Button } from '../shadCN/Button.tsx';

interface Transaction {
  id: string;
  date: string;
  transactionNumber: string;
  description: string;
  amount: string;
  paymentType: string;
  status: string;
  products: any[];
}

interface AccordionProps {
  title: string;
  data: Transaction[];
  columns: string[];
}

export const TransactionAccordion = ({ 
  title, 
  data, 
  columns
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getColumnValue = (item: Transaction, column: string) => {
    switch (column) {
      case 'DATE':
        return item.date;
      case 'TRANSACTION #':
        return item.transactionNumber;
      case 'DESCRIPTION':
        return item.description;
      case 'AMOUNT':
        return item.amount;
      case 'PAYMENT TYPE':
        return item.paymentType;
      case 'STATUS':
        return item.status;
      default:
        return '';
    }
  };

  return (
    <div className="mb-4 border border-mercury rounded-lg overflow-hidden bg-white">
      {/* Accordion Header */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-4 hover:bg-seashell transition-colors"
      >
        <div className="flex items-center gap-2">
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className="h-4 w-4 text-bluish-grey" />
          </motion.div>
          <span className="font-semibold text-hazel-green">{title}</span>
          <span className="text-sm text-bluish-grey">({data.length})</span>
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" className="text-bluish-grey">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </Button>
          <Button className="bg-hazel-green hover:bg-sage text-white">
            <Plus className="h-4 w-4 mr-2" />
            New {title.slice(0, -1)}
          </Button>
        </div>
      </button>

      {/* Accordion Content */}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            style={{ overflow: "hidden" }}
          >
            <div className="border-t border-mercury">
              {/* Fixed Header */}
              <div className="bg-white border-b border-mercury">
                <div className="grid grid-cols-6 gap-4 px-4 py-3">
                  {columns.map((column) => (
                    <div key={column} className="text-sm font-medium text-bluish-grey">
                      {column}
                    </div>
                  ))}
                </div>
              </div>

              {/* Scrollable Content */}
              <div className="relative" style={{ maxHeight: "400px", overflowY: "auto" }}>
                <div className="divide-y divide-mercury">
                  {data.map((item) => (
                    <div
                      key={item.id}
                      className="grid grid-cols-6 gap-4 px-4 py-3 hover:bg-seashell transition-colors duration-200"
                    >
                      {columns.map((column) => (
                        <div 
                          key={column} 
                          className={`text-sm ${column === 'TRANSACTION #' ? 'text-hazel-green font-medium' : 'text-bluish-grey'}`}
                        >
                          {column === 'STATUS' ? (
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                                item.status === 'Paid'
                                  ? 'bg-[#EDF7ED] text-[#1E4620]'
                                  : item.status === 'Pending'
                                  ? 'bg-[#FFF4E5] text-[#663C00]'
                                  : 'bg-[#FDECEA] text-[#611A15]'
                              }`}
                            >
                              {item.status}
                            </span>
                          ) : (
                            getColumnValue(item, column)
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};