import api from './api';

export const createTask = (taskData) =>
  api.post('/api/tasks', taskData);

export const getTasksByRoom = async (roomNumber) => {
  try {
    const response = await api.get(`/api/tasks/room/${roomNumber}`);
    return response;
  } catch (error) {
    console.error('Error fetching tasks by room:', error);
    throw error;
  }
};

export const getTaskById = (taskId) =>
  api.get(`/api/tasks/${taskId}`);

export const updateTaskStatus = async (taskId, status) => {
  try {
    const response = await api.patch(`/api/tasks/${taskId}/status`, { status });
    return response;
  } catch (error) {
    console.error('Error updating task status:', error);
    throw error;
  }
};

export const assignTask = (taskId, assignedToId) =>
  api.patch(`/api/tasks/${taskId}/assign`, { assignedToId });

export const getTasks = (params) =>
  api.get('/api/tasks', { params });

export const getCleaners = () =>
  api.get('/api/tasks/cleaners');
