import React, { useState, useEffect } from 'react';
import { IconUserPlus } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import EmployeeForm from './EmployeeForm';
import { useLocation } from 'react-router-dom';

function AddEmployee() {
  const location = useLocation();
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobTitleParam = params.get('jobTitle');
    if (jobTitleParam) {
      setJobTitle(parseInt(jobTitleParam)); // Ensure jobTitle is a number
    }
  }, [location]);

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconUserPlus className='text-hazel-green mr-2' size={22} />}
        title='Add New Employee'
      />
      <EmployeeForm jobTitle={jobTitle} />
    </div>
  );
}

export default AddEmployee;
