import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { getRoles } from '../../api/roles';
import { createUser, updateUser } from '../../api/users';

function UserForm({ isEdit, user }) {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRolesList = useCallback(async () => {
    try {
      const response = await getRoles(100, 1, '');
      setRoles(response.data.roles);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getRolesList();
  }, [getRolesList]);

  const validationSchema = Yup.object().shape({
    personalNumber: Yup.string(),
    name: Yup.string().min(3).required('Name is required*'),
    surname: Yup.string().min(4).required('Surname is required*'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required*'),
    password: isEdit
      ? Yup.string().nullable()
      : Yup.string()
          .min(8, 'Password must be at least 8 characters.')
          .required('Password is required*')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
            'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
          ),
    jobRole: Yup.string().required('Job position is required*'),
    wage: Yup.string(),
    roleId: Yup.string(),
    phoneNumber: Yup.string().required('Phone number is required*'),
    secondaryPhoneNumber: Yup.string().nullable(),
    address: Yup.string()
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={user}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (isEdit) {
              const { id, ...editValues } = values;
              await updateUser(user.id, editValues);
            } else {
              await createUser(values);
              navigate('/users');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomInput
                type='text'
                name='surname'
                label='Surname'
                value={values.surname}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.surname && touched.surname}
                errorMessage={errors.surname}
              />
              <CustomInput
                type='text'
                name='email'
                label='Email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.email && touched.email}
                errorMessage={errors.email}
              />
              {!isEdit && (
                <CustomInput
                  type='password'
                  name='password'
                  label='Password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.password && touched.password}
                  errorMessage={errors.password}
                />
              )}
              <CustomInput
                type='text'
                name='jobRole'
                label='Job Position'
                value={values.jobRole}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.jobRole && touched.jobRole}
                errorMessage={errors.jobRole}
              />
              <CustomInput
                type='text'
                name='wage'
                label='Wage'
                value={values.wage}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.wage && touched.wage}
                errorMessage={errors.wage}
              />
              <CustomInput
                type='text'
                name='personalNumber'
                label='ID Number'
                value={values.personalNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.personalNumber && touched.personalNumber}
                errorMessage={errors.personalNumber}
              />
              <SelectInput
                value={values.roleId}
                label='Role'
                options={roles}
                onChange={handleChange('roleId')}
                onBlur={handleBlur}
                showError={errors.roleId && touched.roleId}
                errorMessage={errors.roleId}
              />
              <CustomInput
                type='text'
                name='phoneNumber'
                label='Phone Number'
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.phoneNumber && touched.phoneNumber}
                errorMessage={errors.phoneNumber}
              />
              <CustomInput
                type='text'
                name='secondaryPhoneNumber'
                label='Secondary Phone Number'
                value={values.secondaryPhoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={
                  errors.secondaryPhoneNumber && touched.secondaryPhoneNumber
                }
                errorMessage={errors.secondaryPhoneNumber}
              />
              <CustomInput
                type='text'
                name='address'
                label='Address'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.address && touched.address}
                errorMessage={errors.address}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/users')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit user' : 'Create user'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default UserForm;
