import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/loader';
import SelectInput from '../../../components/select-input';
import CustomInput from '../../../components/custom-input';
import DateInput from '../../../components/date-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import CustomSelectInput from '../../../components/custom-select-input';
import { getAllVenues } from '../../../api/venue';
import { createReservation, updateReservation } from '../../../api/venueReservation';
import { getVenue } from '../../../api/venue';
import { paymentMethods } from '../../../constants/entities';
import { checkVenueAvailability } from '../../../api/venueReservation';
import { getAllVenueMenus } from '../../../api/venueReservation';
import { useSelector } from 'react-redux';
import { createInvoice } from '../../../api/invoiceSale';
import { createTransaction } from '../../../api/accounting';
import { adjustAccountValue } from '../../../api/account';
import api from '../../../api/api';
import { toast } from 'react-toastify';

function VenueReservationForm({ isEdit, reservation, venueId }) {

  const { user } = useSelector((state) => state.user);

  const location = useLocation();

  const defaultReservationPage = location.pathname === '/wedding-venue/add-new-reservation';
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);

  const [venueAvailable, setVenueAvailable] = useState(true);
  const [availabilityError, setAvailabilityError] = useState('');
  const [venueMenus, setVenueMenus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVenues();
    getVenueMenus(); // Fetch venue menus
  }, []);

  const getVenues = async () => {
    try {
      const response = await getAllVenues();
      console.log(response.data);
      // Format the venues to match expected SelectInput format
      const formattedVenues = response.data.map((venue) => ({
        id: venue.id,
        title: venue.name,
        price: venue.pricePerDay,
      }));
      console.log(formattedVenues);
      setVenues(formattedVenues);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Failed to fetch venues:', err);
    }
  };

  const getVenueMenus = async () => {
    try {
      const response = await getAllVenueMenus();
      console.log(response.data);
      const formattedMenus = response.data.map((menu) => ({
        id: menu.id,
        title: menu.name,
      }));
      setVenueMenus(formattedMenus);
    } catch (err) {
      console.error('Failed to fetch venue menus:', err);
    }
  };

  const calculateTotalPrice = (values, setFieldValue) => {
    const discountAmount = values.venuePrice * (values.discount / 100);
    const totalPrice = values.venuePrice - discountAmount + values.deposit;
    setFieldValue('totalPrice', totalPrice);
  };

  const checkAvailability = async (values) => {
    try {
      const { startDate, endDate, venueId } = values;
      console.log("Values", values);
      const response = await checkVenueAvailability(
        venueId,
        startDate?.toISOString(),
        endDate?.toISOString()
      );
      console.log(response.data);

      return response.data.isAvailable;

      // if (response.data && response.data.isAvailable === false) {
      //   setAvailabilityError('The venue is not available for the selected dates.');
      // } else {
      //   setAvailabilityError('');
      // }
    } catch (error) {
      setAvailabilityError('An error occurred while checking availability.');
      console.error('Error checking venue availability:', error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{
          venueId: defaultReservationPage ? isEdit ? reservation?.venueId : '' : venueId || '',
          fromDate: isEdit ? dayjs(reservation.fromDate) : null,
          toDate: isEdit ? dayjs(reservation.toDate) : null,
          guests: isEdit ? reservation.guests : 0,
          email: isEdit ? reservation.email : '',
          phoneNumber: isEdit ? reservation.phoneNumber : '',
          discount: isEdit ? reservation.discount : 0,
          venuePrice: defaultReservationPage ? isEdit ? reservation.venuePrice : 0 : venues.find(venue => venue.id === venueId)?.price || 0,
          deposit: isEdit ? reservation.deposit : 0,
          totalPrice: defaultReservationPage ? isEdit ? reservation.totalPrice : 0 : venues.find(venue => venue.id === venueId)?.price || 0,
          paymentType: isEdit ? reservation.paymentType : '',
          paidPrice: isEdit ? reservation.paidPrice : 0,
          menuId: isEdit ? reservation.menuId || '' : ''
        }}
        validationSchema={Yup.object().shape({
          venueId: Yup.string().required('Venue is required*'),
          fromDate: Yup.string().required('Reservation start date is required*'),
          toDate: Yup.string().required('Reservation end date is required*'),
          guests: Yup.number().required('Number of guests is required*'),
          email: Yup.string().email('Invalid email format').required('Email is required*'),
          phoneNumber: Yup.string().required('Phone Number is required*'),
          discount: Yup.number().nullable(),
          venuePrice: Yup.number(),
          deposit: Yup.number().nullable(),
          totalPrice: Yup.number(),
          paymentType: Yup.string().required('Payment Type is required*'),
          paidPrice: Yup.number().nullable(),
          menuId: Yup.string().required('Menu is required*')
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const userId = parseInt(user.id, 10);
          try {
            const isAvailable = await checkAvailability({ ...values, startDate: values.fromDate, endDate: values.toDate });
            if (!isAvailable) {
              setAvailabilityError('The venue is not available for the selected dates.');
              return;
            }

            setAvailabilityError('');

            // Create or get client
            let clientId;
            try {
              const response = await api.post('/api/accounting/clients/check-or-create', {
                name: `${user.name} ${user.surname}`,
                phone: values.phoneNumber || '',
                email: values.email || '',
                address: ''
              });
              clientId = response.data.id;
            } catch (error) {
              console.error('Error checking or creating client:', error);
              toast.error('Failed to check or create client.');
              return;
            }

            // Calculate total amount
            const totalAmount = values.totalPrice;

            // Generate invoice number using timestamp
            const timestamp = new Date().getTime();
            const invoiceNumber = `INV-Venue-${timestamp}`;

            // Create invoice
            const invoiceData = {
              invoiceNumber,
              clientId,
              invoiceDate: new Date(),
              reference: invoiceNumber,
              terms: 'Payment due upon receipt',
              notes: `Venue Reservation Invoice - ${values.guests} guests`,
              paymentMethod: values.paymentType,
              status: 'Completed',
              totalAmount,
              items: [
                {
                  details: 'Venue Reservation',
                  quantity: 1,
                  rate: values.venuePrice,
                  amount: values.venuePrice,
                  discount: values.discount || 0,
                  tax: 0
                },
                ...(values.menuId ? [{
                  details: 'Venue Menu',
                  quantity: values.guests,
                  rate: values.menuPrice || 0,
                  amount: (values.menuPrice || 0) * values.guests,
                  discount: 0,
                  tax: 0
                }] : [])
              ]
            };

            // Create transaction data
            const transactionData = {
              acceptedBy: "Lake Side Hotel",
              amount: totalAmount,
              paymentType: values.paymentType,
              transactionType: "PAYMENT",
              clientId,
              accountId: 13, // Venue income account
              description: `Venue Reservation - ${values.guests} guests`,
              TransactionProduct: [
                {
                  item: 'Venue Reservation',
                  type: 'Venue',
                  unit: 'Booking',
                  quantity: 1,
                  unitPrice: values.venuePrice,
                  soldPrice: values.venuePrice - (values.discount || 0),
                },
                ...(values.menuId ? [{
                  item: 'Venue Menu',
                  type: 'Food/Beverage',
                  unit: 'Person',
                  quantity: values.guests,
                  unitPrice: values.menuPrice || 0,
                  soldPrice: (values.menuPrice || 0) * values.guests,
                }] : [])
              ]
            };

            if (isEdit) {
              const { id, venueId, ...updatedValues } = values;
              await updateReservation(reservation.id, updatedValues);
            } else {
              const { venueId, menuId, ...addValues } = values;
              const dataToSend = {
                ...addValues,
                venueId,
                userId,
                menuId,
                startDate: values.fromDate?.toISOString(),
                endDate: values.toDate?.toISOString(),
                numberOfGuests: values.guests,
              };
              console.log('Creating reservation with data:', dataToSend);

              // Execute all operations in parallel
              await Promise.all([
                createReservation(dataToSend),
                createInvoice(invoiceData),
                createTransaction(transactionData),
                adjustAccountValue(13, totalAmount) // Adjust venue income account
              ]);

              navigate('/venue');
              toast.success('Venue reservation created successfully with invoice and payment!');
            }
            setSubmitting(false);
          } catch (err) {
            console.error('Submission Error:', err);
            const errorMessage = err?.response?.data?.error || 'An unknown error occurred';
            setErrors({ submit: errorMessage });
            setSubmitting(false);
            toast.error('Failed to complete venue reservation process');
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-3'>
                <SelectInput
                  value={values.venueId}
                  label='Venue'
                  options={venues}
                  onChange={(event) => {
                    const selectedVenueId = event.target.value;
                    const selectedVenue = venues.find(venue => venue.id === selectedVenueId);
                    if (selectedVenue) {
                      setFieldValue('venueId', selectedVenueId);
                      setFieldValue('venuePrice', selectedVenue.price);
                      setFieldValue('totalPrice', selectedVenue.price);
                    } else {
                      setFieldValue('venueId', '');
                      setFieldValue('venuePrice', 0);
                      setFieldValue('totalPrice', 0);
                    }
                  }}
                  onBlur={handleBlur}
                  showError={errors.venueId && touched.venueId}
                  errorMessage={errors.venueId}
                />

                <SelectInput
                  value={values.menuId}
                  label='Menu'
                  options={venueMenus}
                  onChange={(event) => setFieldValue('menuId', event.target.value)}
                  onBlur={handleBlur}
                  showError={errors.menuId && touched.menuId}
                  errorMessage={errors.menuId}
                />
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Reservation info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 sm:grid-cols-9 gap-3 mt-4'>
                <div className='col-span-1 sm:col-span-2'>
                  <DateInput
                    name='fromDate'
                    label='From Date'
                    value={values.fromDate}
                    onChange={(date) => setFieldValue('fromDate', date)}
                    onBlur={handleBlur}
                    showError={errors.fromDate && touched.fromDate}
                    errorMessage={errors.fromDate}
                    disablePast
                  />
                </div>
                <div className='col-span-1 sm:col-span-2'>
                  <DateInput
                    name='toDate'
                    label='To Date'
                    value={values.toDate}
                    onChange={(date) => setFieldValue('toDate', date)}
                    onBlur={handleBlur}
                    showError={errors.toDate && touched.toDate}
                    errorMessage={errors.toDate}
                    disablePast
                  />
                </div>
                <div className='col-span-1 sm:col-span-2'>
                  <CustomInput
                    type='number'
                    name='guests'
                    label='Number of Guests'
                    value={values.guests}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.guests && touched.guests}
                    errorMessage={errors.guests}
                  />
                </div>
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Contact info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 sm:grid-cols-9 gap-3 mt-4'>
                <div className='col-span-1 sm:col-span-3'>
                  <CustomInput
                    type='text'
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                </div>
                <div className='col-span-1 sm:col-span-3'>
                  <CustomInput
                    type='text'
                    name='phoneNumber'
                    label='Phone Number'
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phoneNumber && touched.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </div>
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Payment info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 sm:grid-cols-6 gap-3 mt-4'>
                <CustomInput
                  type='number'
                  name='discount'
                  label='Discount'
                  value={values.discount}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('discount', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.discount && touched.discount}
                  errorMessage={errors.discount}
                />

                <CustomInput
                  type='number'
                  name='deposit'
                  label='Deposit'
                  value={values.deposit}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('deposit', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.deposit && touched.deposit}
                  errorMessage={errors.deposit}
                />

                <CustomInput
                  type='number'
                  name='venuePrice'
                  label='Venue Price'
                  value={values.venuePrice}
                  disabled
                />
                <SelectInput
                  value={values.paymentType}
                  label='Payment Type'
                  options={paymentMethods}
                  onChange={handleChange('paymentType')}
                  onBlur={handleBlur}
                  showError={errors.paymentType && touched.paymentType}
                  errorMessage={errors.paymentType}
                  isEntity
                />
                <CustomInput
                  type='number'
                  name='totalPrice'
                  label='Total Price'
                  value={values.totalPrice}
                  disabled
                />
                <CustomInput
                  type='number'
                  name='paidPrice'
                  label='Paid Price'
                  value={values.paidPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.paidPrice && touched.paidPrice}
                  errorMessage={errors.paidPrice}
                />
              </div>

              {availabilityError && (
                <p className='text-error'>{availabilityError}</p>
              )}

              {errors.submit && (
                <p className='text-error'>
                  {typeof errors.submit === 'string' ? errors.submit : JSON.stringify(errors.submit)}
                </p>
              )}

              <div className='flex justify-end items-center w-full mt-7 text-sm'>
                <CancelButton onCancel={() => navigate('/wedding-venue')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-full sm:w-1/5'
                  title='Save'
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default VenueReservationForm;
