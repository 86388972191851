import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getPools, createPoolReservation } from '../../api/pools';
import { createInvoice, invoiceCount as invoiceCountAPI } from '../../api/invoiceSale';
import { Plus, Clock, User, Phone, CreditCard, History } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';

const IndoorPoolReservation = () => {
  const navigate = useNavigate();
  const [pools, setPools] = useState({});
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  useEffect(() => {
    async function fetchPools() {
      try {
        const response = await getPools();
        if (response.data) {
          const poolsData = {};
          response.data.forEach(pool => {
            if (pool.type === 'INDOOR') {
              poolsData.INDOOR = {
                ...pool,
                tickets: [
                  { title: 'standard', price: pool.standardPrice || 20 }
                ]
              };
            }
          });
          setPools(poolsData);
        }
      } catch (error) {
        console.error('Error fetching pools:', error);
        toast.error('Failed to load pools.');
      }
    }

    fetchPools();
  }, []);

  const calculatePrice = (startTime, endTime, basePrice) => {
    try {
      const today = new Date();
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);

      let start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startHours, startMinutes);
      let end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endHours, endMinutes);

      // Handle overnight reservations
      if (end < start) {
        end.setDate(end.getDate() + 1);
      }

      // Only validate that end time is after start time
      if (end <= start) {
        throw new Error('End time must be after start time');
      }

      // Calculate duration in hours (rounded up)
      const durationMs = end.getTime() - start.getTime();
      const durationHours = Math.ceil(durationMs / (1000 * 60 * 60));
      
      // Calculate price - charge by hour
      const price = basePrice * durationHours;

      console.log(`Duration: ${durationHours} hours, Price: ${price}`);
      setCalculatedPrice(price);
      return { price, durationHours };
    } catch (error) {
      console.error('Error calculating price:', error);
      throw error;
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    personalNumber: Yup.string().required('Personal number is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    startTime: Yup.string().required('Start time is required'),
    endTime: Yup.string().required('End time is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const indoorPool = pools.INDOOR;
      if (!indoorPool) {
        throw new Error('Indoor pool not found');
      }

      const { price } = calculatePrice(
        values.startTime,
        values.endTime,
        indoorPool.tickets[0].price
      );

      const reservationData = {
        poolId: indoorPool.id,
        ticketType: 'STANDARD',
        name: values.name,
        surname: values.surname,
        personalNumber: values.personalNumber,
        phoneNumber: values.phoneNumber,
        startTime: new Date().setHours(...values.startTime.split(':').map(Number)),
        endTime: new Date().setHours(...values.endTime.split(':').map(Number)),
        price: price,
        status: 'PENDING_CHECKIN'
      };

      const response = await createPoolReservation(reservationData);
      if (response.data) {
        toast.success('Reservation created successfully!');
        resetForm();
        setCalculatedPrice(null);
      }
    } catch (error) {
      console.error('Error creating reservation:', error);
      toast.error(error.message || 'Failed to create reservation');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-tealish-blue to-seashell">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-hazel-green mb-2">Indoor Pool Reservation</h1>
            <p className="text-bluish-grey">Create and manage pool reservations</p>
          </div>
          <button
            onClick={() => navigate('/hotelInterface/indoor-pool/history')}
            className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-colors"
          >
            <History className="h-5 w-5" />
            View History
          </button>
        </div>
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold text-hazel-green">Indoor Pool Reservation</h1>
          </div>

          <div className="bg-white rounded-2xl shadow-lg overflow-hidden backdrop-blur-sm bg-opacity-90">
            <div className="p-6">
              <Formik
                initialValues={{
                  name: '',
                  surname: '',
                  personalNumber: '',
                  phoneNumber: '',
                  startTime: '',
                  endTime: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Guest Information Section */}
                    <div className="space-y-4">
                      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
                        <User className="h-4 w-4" />
                        Guest Information
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.name && errors.name && (
                            <div className="text-xs text-salmon-pink">{errors.name}</div>
                          )}
                        </div>

                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            Surname
                          </label>
                          <input
                            type="text"
                            name="surname"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.surname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.surname && errors.surname && (
                            <div className="text-xs text-salmon-pink">{errors.surname}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Contact Information Section */}
                    <div className="space-y-4">
                      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
                        <Phone className="h-4 w-4" />
                        Contact Information
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            Personal Number
                          </label>
                          <input
                            type="text"
                            name="personalNumber"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.personalNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.personalNumber && errors.personalNumber && (
                            <div className="text-xs text-salmon-pink">{errors.personalNumber}</div>
                          )}
                        </div>

                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            name="phoneNumber"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phoneNumber && errors.phoneNumber && (
                            <div className="text-xs text-salmon-pink">{errors.phoneNumber}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Reservation Time Section */}
                    <div className="space-y-4">
                      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        Reservation Time
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            Start Time
                          </label>
                          <input
                            type="time"
                            name="startTime"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.startTime}
                            onChange={(e) => {
                              handleChange(e);
                              if (values.endTime) {
                                calculatePrice(e.target.value, values.endTime, pools.INDOOR?.tickets[0].price || 20);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.startTime && errors.startTime && (
                            <div className="text-xs text-salmon-pink">{errors.startTime}</div>
                          )}
                        </div>

                        <div className="space-y-1">
                          <label className="block text-sm font-medium text-bluish-grey">
                            End Time
                          </label>
                          <input
                            type="time"
                            name="endTime"
                            className="w-full px-3 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
                            value={values.endTime}
                            onChange={(e) => {
                              handleChange(e);
                              if (values.startTime) {
                                calculatePrice(values.startTime, e.target.value, pools.INDOOR?.tickets[0].price || 20);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.endTime && errors.endTime && (
                            <div className="text-xs text-salmon-pink">{errors.endTime}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Price Display Section */}
                    {calculatedPrice && (
                      <div className="bg-gradient-to-r from-sage/10 to-hazel-green/10 rounded-xl p-4 border-2 border-hazel-green/20">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <CreditCard className="h-5 w-5 text-hazel-green" />
                            <span className="text-base font-medium text-bluish-grey">Total Price</span>
                          </div>
                          <span className="text-xl font-bold text-hazel-green">
                            €{calculatedPrice}
                          </span>
                        </div>
                      </div>
                    )}

                    {/* Submit Button */}
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="flex items-center gap-2 px-6 py-2 bg-gradient-to-r from-hazel-green to-sage text-white rounded-xl hover:from-sage hover:to-hazel-green transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg hover:shadow-xl"
                      >
                        <Plus className="h-4 w-4" />
                        Create Reservation
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndoorPoolReservation;
