import React, { useState } from 'react';
import dayjs from 'dayjs';
import { TablePagination } from '@mui/material';
import { toast } from 'react-toastify';
import PoolInvoiceModal from './PoolInvoiceModal';
import PoolRestaurantOrderModal from './PoolRestaurantOrderModal';
import { IconToolsKitchen2 } from '@tabler/icons-react';

function IndoorPoolDetailsModal({ isOpen, onClose, reservations, date, onTicketClick, onUpdate }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isRestaurantOrderModalOpen, setIsRestaurantOrderModalOpen] = useState(false);

  if (!isOpen) return null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTicketClick = (reservation) => {
    if (onTicketClick) {
      onTicketClick(reservation);
      if (onUpdate) onUpdate();
    }
  };

  const handleOrderComplete = async () => {
    // Close the restaurant order modal
    setIsRestaurantOrderModalOpen(false);
    
    // Trigger the update callback to refresh the data
    if (onUpdate) {
      await onUpdate();
    }
  };

  // Calculate the current page's reservations
  const currentReservations = reservations.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  console.log('currentReservations:', currentReservations); // Log the current reservations

  // Calculate total price of all reservations including extra charges
  const totalPrice = reservations.reduce((sum, res) => sum + parseFloat(res.price) + (parseFloat(res.extraCharges) || 0), 0);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black opacity-30" onClick={onClose} />

        <div className="relative bg-seashell rounded-lg w-full max-w-3xl mx-4 p-6 shadow-xl">
          <div className="flex justify-between items-center mb-6 border-b border-grey-goose pb-4">
            <h2 className="text-lg font-medium text-bluish-grey">
              Indoor Pool Reservations - {date}
            </h2>
            <button
              onClick={onClose}
              className="text-hazel-green hover:text-bluish-grey transition-colors"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="mt-4 overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-grey-goose">
                <thead className="bg-tealish-blue">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Personal Number
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Phone
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Scheduled Time
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Check In
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Check Out
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Duration
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Price
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Extra Charges
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Reference
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Invoice
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-bluish-grey uppercase tracking-wider">
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-grey-goose">
                  {currentReservations.map((reservation, idx) => (
                    <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-seashell'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.name} {reservation.surname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.personalNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.phoneNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          reservation.status === 'CHECKED_OUT' ? 'bg-blue-100 text-blue-600' :
                          reservation.status === 'CHECKED_IN' ? 'bg-green-100 text-green-600' :
                          'bg-yellow-100 text-yellow-600'
                        }`}>
                          {reservation.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {dayjs(reservation.startTime).format('HH:mm')} - {dayjs(reservation.endTime).format('HH:mm')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.actualCheckIn ? dayjs(reservation.actualCheckIn).format('HH:mm') : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.actualCheckOut ? dayjs(reservation.actualCheckOut).format('HH:mm') : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {(() => {
                          if (reservation.duration) {
                            const hours = Math.floor(reservation.duration);
                            const minutes = Math.round((reservation.duration % 1) * 60);
                            return `${hours}h ${minutes}m`;
                          } else if (reservation.actualCheckIn && reservation.actualCheckOut) {
                            const minutes = Math.round(
                              (new Date(reservation.actualCheckOut).getTime() - 
                              new Date(reservation.actualCheckIn).getTime()) / (1000 * 60)
                            );
                            const hours = Math.floor(minutes / 60);
                            const remainingMinutes = minutes % 60;
                            return `${hours}h ${remainingMinutes}m`;
                          }
                          return '-';
                        })()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.price}€
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.extraCharges ? `${reservation.extraCharges}€` : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        {reservation.ticketReference || '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">
                        <div className="flex space-x-2">
                          <button
                            onClick={() => {
                              setSelectedReservation(reservation);
                              setIsInvoiceModalOpen(true);
                            }}
                            className="text-hazel-green hover:text-sage transition-colors"
                            title="View Invoice"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => {
                              setSelectedReservation(reservation);
                              setIsRestaurantOrderModalOpen(true);
                            }}
                            className={`transition-colors ${
                              reservation.status === 'CHECKED_IN'
                                ? 'text-hazel-green hover:text-sage cursor-pointer'
                                : 'text-gray-400 cursor-not-allowed'
                            }`}
                            title={
                              reservation.status === 'CHECKED_IN'
                                ? 'Restaurant Order'
                                : 'Orders can only be placed for checked-in reservations'
                            }
                            disabled={reservation.status !== 'CHECKED_IN'}
                          >
                            <IconToolsKitchen2 size={20} />
                          </button>
                        </div>
                      </td>
                      
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handleTicketClick(reservation)}
                          className="text-hazel-green hover:text-hazel-green/80 transition-colors bg-seashell hover:bg-grey-goose/20 p-2 rounded-full"
                        >
                          <span className="sr-only">Manage ticket</span>
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-tealish-blue">
                  <tr>
                    <td colSpan="11" className="px-6 py-4 text-right font-medium text-bluish-grey">
                      Total (All Pages):
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-hazel-green">
                      {totalPrice}€
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="mt-4 border-t border-grey-goose">
              <TablePagination
                component="div"
                count={reservations.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                className="text-bluish-grey"
                sx={{
                  '.MuiTablePagination-select': {
                    backgroundColor: '#F0F3EF',
                    borderRadius: '4px',
                  },
                  '.MuiTablePagination-selectIcon': {
                    color: '#7c8b93',
                  },
                  '.MuiTablePagination-displayedRows': {
                    color: '#7c8b93',
                  },
                  '.MuiTablePagination-actions': {
                    color: '#7c8b93',
                    '& button': {
                      '&:hover': {
                        backgroundColor: '#F0F3EF',
                      },
                      '&.Mui-disabled': {
                        color: '#c9d3cc',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Modal */}
      {isInvoiceModalOpen && selectedReservation && (
        <PoolInvoiceModal
          isOpen={isInvoiceModalOpen}
          onClose={() => setIsInvoiceModalOpen(false)}
          reservation={selectedReservation}
        />
      )}

      {/* Restaurant Order Modal */}
      {isRestaurantOrderModalOpen && selectedReservation && (
        <PoolRestaurantOrderModal
          isOpen={isRestaurantOrderModalOpen}
          onClose={() => setIsRestaurantOrderModalOpen(false)}
          poolTicketReference={selectedReservation.ticketReference}
          customerName={`${selectedReservation.name} ${selectedReservation.surname}`}
          onOrderComplete={handleOrderComplete}
        />
      )}
    </div>
  );
}

export default IndoorPoolDetailsModal;
