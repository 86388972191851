// import React, { useState, useEffect, useCallback } from 'react';
// import { IconToolsKitchen2, IconCoffee, IconShoppingCart, IconBuilding } from '@tabler/icons-react';
// import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';


// import MenuSection from './MenuSection.tsx';
// import {
//   getCategoryOrderProducts,
//   createCategoryOrderProduct,
//   deleteCategoryOrderProduct,
//   updateCategoryOrderProduct
// } from '../../../../../api/categoryOrderProduct';
// import { getBarProducts } from '../../../../../api/barProduct';
// import { getDishSections } from '../../../../../api/dishSection';
// import { createRoomServiceOrder } from '../../../../../api/orders';
// import { getMenuById } from '../../../../../api/conferenceRoomMenu';
// import { getConferenceRoomReservationByRoomNumber } from '../../../../../api/conferenceRoomReservation';
// interface RestaurantOrderProps {
//   roomNumber: string;
// }

// interface OrderItem {
//   id: string;
//   name: string;
//   price: number;
//   quantity: number;
//   type: 'kitchen' | 'bar' | 'conference';
//   category: 'KITCHEN' | 'BAR' | 'CONFERENCE';
//   description: string;
// }

// export default function RestaurantOrder({ roomNumber }: RestaurantOrderProps) {
//   const user = useSelector((state) => state.user.user);
//   const [activeSection, setActiveSection] = useState<'kitchen' | 'bar' | 'conference'>('kitchen');
//   const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [barProducts, setBarProducts] = useState([]);
//   const [dishSections, setDishSections] = useState<DishSection[]>([]);
//   const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
//   const [groupedDishSections, setGroupedDishSections] = useState<Record<string, OrderItem[]>>({});
//   const [expandedDishType, setExpandedDishType] = useState<string | null>(null);
//   const [conferenceMenu, setConferenceMenu] = useState(null);
//   const [hasConferenceReservation, setHasConferenceReservation] = useState(false);

//   // Fetch conference room reservation and menu
//   const fetchConferenceData = useCallback(async () => {
//     try {
//       console.log('Fetching conference data for room:', roomNumber);
//       const response = await getConferenceRoomReservationByRoomNumber(roomNumber);
//       console.log('Conference reservation response:', response);
      
//       if (response?.data?.data) {  
//         setHasConferenceReservation(true);
//         if (response.data.data.menuId) {
//           try {
//             const menuResponse = await getMenuById(response.data.data.menuId);
//             console.log('Conference menu response:', menuResponse);
//             if (menuResponse?.data) {
//               setConferenceMenu(menuResponse.data);
//               // Automatically switch to conference tab if we have a conference reservation
//               setActiveSection('conference');
//             }
//           } catch (menuError) {
//             console.error('Error fetching conference menu:', menuError);
//             toast.error('Failed to load conference menu');
//           }
//         }
//       } else {
//         setHasConferenceReservation(false);
//         setConferenceMenu(null);
//       }
//     } catch (error) {
//       console.error('Error fetching conference data:', error);
//       setHasConferenceReservation(false);
//       setConferenceMenu(null);
//     }
//   }, [roomNumber]);

//   // Group conference menu items by type
//   const groupConferenceMenuItems = useCallback((menu) => {
//     if (!menu) return {};
    
//     const grouped = {
//       'Breakfast Items': menu.breakfastItems || [],
//       'Lunch Items': menu.lunchItems || [],
//       'Dinner Items': menu.dinnerItems || [],
//       'Breakfast Bar Items': menu.breakfastBarItems || [],
//       'Lunch Bar Items': menu.lunchBarItems || [],
//       'Dinner Bar Items': menu.dinnerBarItems || []
//     };

//     // Remove empty categories
//     const filteredGroups = Object.fromEntries(
//       Object.entries(grouped).filter(([_, items]) => items && items.length > 0)
//     );

//     console.log('Grouped conference menu items:', filteredGroups);
//     return filteredGroups;
//   }, []);

//   // Fetch menu data
//   const fetchMenuData = useCallback(async () => {
//     try {
//       const [categoriesResponse, barProductsResponse, dishSectionsResponse] = await Promise.all([
//         getCategoryOrderProducts(),
//         getBarProducts(),
//         getDishSections()
//       ]);

//       setCategories(categoriesResponse.data);
//       setBarProducts(barProductsResponse.data);
//       setDishSections(dishSectionsResponse.data);

//       // Group dish sections by category title
//       const grouped = dishSectionsResponse.data.reduce((acc, section) => {
//         // Get category title from categoryOrderProduct
//         const categoryTitle = section.categoryOrderProduct?.title || 'Other';
//         if (!acc[categoryTitle]) {
//           acc[categoryTitle] = [];
//         }
//         acc[categoryTitle].push({
//           id: section.id,
//           name: section.name,
//           price: section.price,
//           imageUrl: section.imageUrl,
//           type: 'kitchen',
//           description: section.description
//         });
//         return acc;
//       }, {});

//       console.log('Grouped kitchen sections:', grouped);
//       setGroupedDishSections(grouped);
//     } catch (error) {
//       console.error('Error fetching menu data:', error);
//       toast.error('Failed to load menu data');
//     }
//   }, []);

//   useEffect(() => {
//     fetchMenuData();
//     fetchConferenceData();
//   }, [fetchMenuData, fetchConferenceData]);

//   useEffect(() => {
//     const grouped = dishSections.reduce((acc, section) => {
//       if (!acc[section.type]) {
//         acc[section.type] = [];
//       }
//       acc[section.type].push(section);
//       return acc;
//     }, {} as Record<string, typeof dishSections>);
//     setGroupedDishSections(grouped);
//   }, [dishSections]);

//   // Add item to order
//   const addToOrder = (item, type: 'kitchen' | 'bar' | 'conference') => {
//     setOrderItems(prev => {
//       const existingItem = prev.find(i => i.id === item.id && i.type === type);
//       if (existingItem) {
//         return prev.map(i => 
//           i.id === item.id && i.type === type 
//             ? { ...i, quantity: i.quantity + 1 }
//             : i
//         );
//       }
//       return [...prev, { ...item, quantity: 1, type, category: type === 'kitchen' ? 'KITCHEN' : type === 'bar' ? 'BAR' : 'CONFERENCE' }];
//     });
//   };

//   const updateQuantity = (itemId: string, newQuantity: number, type: 'kitchen' | 'bar' | 'conference') => {
//     if (newQuantity < 1) {
//       setOrderItems(prev => prev.filter(item => item.id !== itemId || item.type !== type));
//       return;
//     }
//     setOrderItems(prev => 
//       prev.map(item => 
//         item.id === itemId && item.type === type ? { ...item, quantity: newQuantity } : item
//       )
//     );
//   };

//   const handleSubmitOrder = async () => {
//     if (!roomNumber) {
//       toast.error('Room number is required');
//       return;
//     }

//     if (orderItems.length === 0) {
//       toast.error('Please add items to your order');
//       return;
//     }

//     setLoading(true);
//     try {
//       const orderDetails = {
//         roomNumber,
//         waiterId: user?.id,
//         totalPrice: orderItems.reduce((total, item) => 
//           total + (parseFloat(item.price) * parseInt(item.quantity.toString(), 10)), 0),
//         orderItems: orderItems.map(item => ({
//           id: item.id,
//           quantity: item.quantity,
//           price: item.price,
//           category: item.category,
//           type: item.type === 'bar' ? 'barItem' : item.type === 'conference' ? 'conferenceItem' : 'dish'
//         }))
//       };

//       const response = await createRoomServiceOrder(orderDetails);
//       console.log('Room service order response:', response);
      
//       setOrderItems([]);
//       toast.success('Order submitted successfully');
//     } catch (error) {
//       console.error('Error submitting order:', error);
//       toast.error('Failed to submit order');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const totalAmount = orderItems.reduce((sum, item) => 
//     sum + (item.price * item.quantity), 0
//   );

//   const renderKitchenSection = () => {
//     return (
//       <div className="space-y-4">
//         {Object.entries(groupedDishSections).map(([type, sections]) => (
//           <MenuSection
//             key={type}
//             title={type}
//             items={sections.map(section => ({
//               id: section.id,
//               name: section.name,
//               price: section.price,
//               description: section.description,
//               type: 'kitchen'
//             }))}
//             isExpanded={expandedDishType === type}
//             onToggle={() => setExpandedDishType(
//               expandedDishType === type ? null : type
//             )}
//             onItemClick={(item) => addToOrder({ ...item, type: 'kitchen' })}
//           />
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div className="space-y-6">
//       <div className="flex space-x-4 mb-6">
//         <button
//           onClick={() => setActiveSection('kitchen')}
//           className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
//             activeSection === 'kitchen'
//               ? 'bg-hazel-green text-white'
//               : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
//           }`}
//         >
//           <IconToolsKitchen2 className="mr-2" size={20} />
//           Kitchen
//         </button>
//         <button
//           onClick={() => setActiveSection('bar')}
//           className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
//             activeSection === 'bar'
//               ? 'bg-hazel-green text-white'
//               : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
//           }`}
//         >
//           <IconCoffee className="mr-2" size={20} />
//           Bar
//         </button>
//         {hasConferenceReservation && (
//           <button
//             onClick={() => setActiveSection('conference')}
//             className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
//               activeSection === 'conference'
//                 ? 'bg-hazel-green text-white'
//                 : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
//             }`}
//           >
//             <IconBuilding className="mr-2" size={20} />
//             Conference Menu
//           </button>
//         )}
//       </div>

//       <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//         {/* Menu Items */}
//         <div className="space-y-4 h-80 overflow-hidden">
//           <h3 className="text-lg font-semibold text-bluish-grey sticky top-0 bg-seashell z-10 py-2">
//             {activeSection === 'kitchen' ? 'Kitchen Menu' : activeSection === 'bar' ? 'Bar Menu' : 'Conference Room Menu'}
//           </h3>
          
//           <div className="space-y-2 h-80 overflow-y-auto pr-4">
//             {activeSection === 'kitchen' ? (
//               renderKitchenSection()
//             ) : activeSection === 'bar' ? (
//               categories
//                 .filter(cat => cat.categoryType === 'BAR')
//                 .map(category => (
//                   <MenuSection
//                     key={category.id}
//                     title={category.title}
//                     items={barProducts
//                       .filter(product => product.categoryOrderProductId === category.id)
//                       .map(item => ({
//                         id: item.id,
//                         name: item.name,
//                         price: item.price,
//                         imageUrl: item.imageUrl,
//                         type: 'bar'
//                       }))}
//                     isExpanded={expandedCategory === category.id}
//                     onToggle={() => setExpandedCategory(
//                       expandedCategory === category.id ? null : category.id
//                     )}
//                     onItemClick={(item) => addToOrder({ ...item, type: 'bar' })}
//                   />
//                 ))
//             ) : (
//               // Conference menu section
//               conferenceMenu && Object.entries(groupConferenceMenuItems(conferenceMenu)).map(([type, items]) => (
//                 items && items.length > 0 && (
//                   <MenuSection
//                     key={type}
//                     title={type}
//                     items={items.map(item => ({
//                       id: item.id,
//                       name: item.name || item.title || 'Unnamed Item',
//                       price: parseFloat(item.price) || 0,
//                       description: item.description || '',
//                       type: 'conference'
//                     }))}
//                     isExpanded={expandedCategory === type}
//                     onToggle={() => setExpandedCategory(
//                       expandedCategory === type ? null : type
//                     )}
//                     onItemClick={(item) => addToOrder({ ...item, type: 'conference' })}
//                   />
//                 )
//               ))
//             )}
//           </div>
//         </div>

//         {/* Order Summary */}
//         <div className="bg-white p-6 rounded-lg shadow-md h-fit sticky top-4">
//           <div className="flex items-center justify-between mb-4">
//             <h3 className="text-lg font-semibold text-bluish-grey">Order Summary</h3>
//             <IconShoppingCart size={20} className="text-hazel-green" />
//           </div>
          
//           <div className="space-y-4 max-h-[300px] overflow-y-auto mb-4">
//             {orderItems.map((item) => (
//               <div key={item.id} className="flex items-center justify-between py-2 border-b">
//                 <div className="flex-1">
//                   <p className="font-medium text-bluish-grey">{item.name}</p>
//                   <p className="text-sm text-gray-600">${item.price.toFixed(2)}</p>
//                 </div>
//                 <div className="flex items-center space-x-3">
//                   <button
//                     onClick={() => updateQuantity(item.id, item.quantity - 1, item.type)}
//                     className="px-2 py-1 bg-grey-goose text-bluish-grey rounded hover:bg-sage hover:text-white transition-colors"
//                   >
//                     -
//                   </button>
//                   <span className="w-8 text-center">{item.quantity}</span>
//                   <button
//                     onClick={() => updateQuantity(item.id, item.quantity + 1, item.type)}
//                     className="px-2 py-1 bg-grey-goose text-bluish-grey rounded hover:bg-sage hover:text-white transition-colors"
//                   >
//                     +
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="border-t pt-4">
//             <div className="flex justify-between mb-4">
//               <span className="font-semibold text-bluish-grey">Total Amount:</span>
//               <span className="font-semibold text-hazel-green">
//                 ${totalAmount.toFixed(2)}
//               </span>
//             </div>
            
//             <button
//               onClick={handleSubmitOrder}
//               disabled={orderItems.length === 0 || loading}
//               className={`w-full py-2 rounded-md text-white transition-colors ${
//                 orderItems.length === 0 || loading
//                   ? 'bg-grey-goose cursor-not-allowed'
//                   : 'bg-hazel-green hover:bg-sage'
//               }`}
//             >
//               {loading ? 'Processing...' : 'Submit Order'}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect, useCallback } from 'react';
import { IconToolsKitchen2, IconCoffee, IconShoppingCart, IconBuilding } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';


import MenuSection from './MenuSection.tsx';
import {
  getCategoryOrderProducts,
  createCategoryOrderProduct,
  deleteCategoryOrderProduct,
  updateCategoryOrderProduct
} from '../../../../../api/categoryOrderProduct';
import { getBarProducts } from '../../../../../api/barProduct';
import { getDishSections } from '../../../../../api/dishSection';
import { createRoomServiceOrder } from '../../../../../api/orders';
import { getMenuById } from '../../../../../api/conferenceRoomMenu';
import { getConferenceRoomReservationByRoomNumber } from '../../../../../api/conferenceRoomReservation';
interface RestaurantOrderProps {
  roomNumber: string;
}

interface OrderItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  type: 'kitchen' | 'bar' | 'conference';
  category: 'KITCHEN' | 'BAR' | 'CONFERENCE';
  description: string;
}

export default function RestaurantOrder({ roomNumber }: RestaurantOrderProps) {
  const user = useSelector((state) => state.user.user);
  const [activeSection, setActiveSection] = useState<'kitchen' | 'bar' | 'conference'>('kitchen');
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [barProducts, setBarProducts] = useState([]);
  const [dishSections, setDishSections] = useState<DishSection[]>([]);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [groupedDishSections, setGroupedDishSections] = useState<Record<string, OrderItem[]>>({});
  const [expandedDishType, setExpandedDishType] = useState<string | null>(null);
  const [conferenceMenu, setConferenceMenu] = useState(null);
  const [hasConferenceReservation, setHasConferenceReservation] = useState(false);

  // Fetch conference room reservation and menu
  const fetchConferenceData = useCallback(async () => {

    console.log('RestaurantOrder received room number:', roomNumber);

    
    try {
      console.log('Fetching conference data for room:', roomNumber);
      const response = await getConferenceRoomReservationByRoomNumber(roomNumber);
      console.log('Conference reservation response:', response);
      
      if (response?.data?.data) {  
        setHasConferenceReservation(true);
        if (response.data.data.menuId) {
          try {
            const menuResponse = await getMenuById(response.data.data.menuId);
            console.log('Conference menu response:', menuResponse);
            if (menuResponse?.data) {
              setConferenceMenu(menuResponse.data);
              // Automatically switch to conference tab if we have a conference reservation
              setActiveSection('conference');
            }
          } catch (menuError) {
            console.error('Error fetching conference menu:', menuError);
            toast.error('Failed to load conference menu');
          }
        }
      } else {
        setHasConferenceReservation(false);
        setConferenceMenu(null);
      }
    } catch (error) {
      console.error('Error fetching conference data:', error);
      setHasConferenceReservation(false);
      setConferenceMenu(null);
    }
  }, [roomNumber]);

  // Group conference menu items by type
  const groupConferenceMenuItems = useCallback((menu) => {
    if (!menu) return {};
    
    const grouped = {
      'Breakfast Items': menu.breakfastItems || [],
      'Lunch Items': menu.lunchItems || [],
      'Dinner Items': menu.dinnerItems || [],
      'Breakfast Bar Items': menu.breakfastBarItems || [],
      'Lunch Bar Items': menu.lunchBarItems || [],
      'Dinner Bar Items': menu.dinnerBarItems || []
    };

    // Remove empty categories
    const filteredGroups = Object.fromEntries(
      Object.entries(grouped).filter(([_, items]) => items && items.length > 0)
    );

    console.log('Grouped conference menu items:', filteredGroups);
    return filteredGroups;
  }, []);

  // Fetch menu data
  const fetchMenuData = useCallback(async () => {
    try {
      const [categoriesResponse, barProductsResponse, dishSectionsResponse] = await Promise.all([
        getCategoryOrderProducts(),
        getBarProducts(),
        getDishSections()
      ]);

      setCategories(categoriesResponse.data);
      setBarProducts(barProductsResponse.data);
      setDishSections(dishSectionsResponse.data);

      // Group dish sections by category title
      const grouped = dishSectionsResponse.data.reduce((acc, section) => {
        // Get category title from categoryOrderProduct
        const categoryTitle = section.categoryOrderProduct?.title || 'Other';
        if (!acc[categoryTitle]) {
          acc[categoryTitle] = [];
        }
        acc[categoryTitle].push({
          id: section.id,
          name: section.name,
          price: section.price,
          imageUrl: section.imageUrl,
          type: 'kitchen',
          description: section.description
        });
        return acc;
      }, {});

      console.log('Grouped kitchen sections:', grouped);
      setGroupedDishSections(grouped);
    } catch (error) {
      console.error('Error fetching menu data:', error);
      toast.error('Failed to load menu data');
    }
  }, []);

  useEffect(() => {
    fetchMenuData();
    fetchConferenceData();
  }, [fetchMenuData, fetchConferenceData]);

  useEffect(() => {
    const grouped = dishSections.reduce((acc, section) => {
      if (!acc[section.type]) {
        acc[section.type] = [];
      }
      acc[section.type].push(section);
      return acc;
    }, {} as Record<string, typeof dishSections>);
    setGroupedDishSections(grouped);
  }, [dishSections]);

  // Add item to order
  const addToOrder = (item, type: 'kitchen' | 'bar' | 'conference') => {
    setOrderItems(prev => {
      const existingItem = prev.find(i => i.id === item.id && i.type === type);
      if (existingItem) {
        return prev.map(i => 
          i.id === item.id && i.type === type 
            ? { ...i, quantity: i.quantity + 1 }
            : i
        );
      }
      return [...prev, { ...item, quantity: 1, type, category: type === 'kitchen' ? 'KITCHEN' : type === 'bar' ? 'BAR' : 'CONFERENCE' }];
    });
  };

  const updateQuantity = (itemId: string, newQuantity: number, type: 'kitchen' | 'bar' | 'conference') => {
    if (newQuantity < 1) {
      setOrderItems(prev => prev.filter(item => item.id !== itemId || item.type !== type));
      return;
    }
    setOrderItems(prev => 
      prev.map(item => 
        item.id === itemId && item.type === type ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const handleSubmitOrder = async () => {
    if (!roomNumber) {
      toast.error('Room number is required');
      return;
    }

    if (orderItems.length === 0) {
      toast.error('Please add items to your order');
      return;
    }

    setLoading(true);
    try {
      const orderDetails = {
        roomNumber,
        waiterId: user?.id,
        totalPrice: orderItems.reduce((total, item) => 
          total + (parseFloat(item.price) * parseInt(item.quantity.toString(), 10)), 0),
        orderItems: orderItems.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          category: item.category,
          type: item.type === 'bar' ? 'barItem' : item.type === 'conference' ? 'conferenceItem' : 'dish'
        }))
      };

      const response = await createRoomServiceOrder(orderDetails);
      console.log('Room service order response:', response);
      
      setOrderItems([]);
      toast.success('Order submitted successfully');
    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error('Failed to submit order');
    } finally {
      setLoading(false);
    }
  };

  const totalAmount = orderItems.reduce((sum, item) => 
    sum + (item.price * item.quantity), 0
  );

  const renderKitchenSection = () => {
    return (
      <div className="space-y-4">
        {Object.entries(groupedDishSections).map(([type, sections]) => (
          <MenuSection
            key={type}
            title={type}
            items={sections.map(section => ({
              id: section.id,
              name: section.name,
              price: section.price,
              description: section.description,
              type: 'kitchen'
            }))}
            isExpanded={expandedDishType === type}
            onToggle={() => setExpandedDishType(
              expandedDishType === type ? null : type
            )}
            onItemClick={(item) => addToOrder({ ...item, type: 'kitchen' })}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveSection('kitchen')}
          className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
            activeSection === 'kitchen'
              ? 'bg-hazel-green text-white'
              : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
          }`}
        >
          <IconToolsKitchen2 className="mr-2" size={20} />
          Kitchen
        </button>
        <button
          onClick={() => setActiveSection('bar')}
          className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
            activeSection === 'bar'
              ? 'bg-hazel-green text-white'
              : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
          }`}
        >
          <IconCoffee className="mr-2" size={20} />
          Bar
        </button>
        {hasConferenceReservation && (
          <button
            onClick={() => setActiveSection('conference')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeSection === 'conference'
                ? 'bg-hazel-green text-white'
                : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
            }`}
          >
            <IconBuilding className="mr-2" size={20} />
            Conference Menu
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Menu Items */}
        <div className="space-y-4 h-80 overflow-hidden">
          <h3 className="text-lg font-semibold text-bluish-grey sticky top-0 bg-seashell z-10 py-2">
            {activeSection === 'kitchen' ? 'Kitchen Menu' : activeSection === 'bar' ? 'Bar Menu' : 'Conference Room Menu'}
          </h3>
          
          <div className="space-y-2 h-80 overflow-y-auto pr-4">
            {activeSection === 'kitchen' ? (
              renderKitchenSection()
            ) : activeSection === 'bar' ? (
              categories
                .filter(cat => cat.categoryType === 'BAR')
                .map(category => (
                  <MenuSection
                    key={category.id}
                    title={category.title}
                    items={barProducts
                      .filter(product => product.categoryOrderProductId === category.id)
                      .map(item => ({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        imageUrl: item.imageUrl,
                        type: 'bar'
                      }))}
                    isExpanded={expandedCategory === category.id}
                    onToggle={() => setExpandedCategory(
                      expandedCategory === category.id ? null : category.id
                    )}
                    onItemClick={(item) => addToOrder({ ...item, type: 'bar' })}
                  />
                ))
            ) : (
              // Conference menu section
              conferenceMenu && Object.entries(groupConferenceMenuItems(conferenceMenu)).map(([type, items]) => (
                items && items.length > 0 && (
                  <MenuSection
                    key={type}
                    title={type}
                    items={items.map(item => ({
                      id: item.id,
                      name: item.name || item.title || 'Unnamed Item',
                      price: parseFloat(item.price) || 0,
                      description: item.description || '',
                      type: 'conference'
                    }))}
                    isExpanded={expandedCategory === type}
                    onToggle={() => setExpandedCategory(
                      expandedCategory === type ? null : type
                    )}
                    onItemClick={(item) => addToOrder({ ...item, type: 'conference' })}
                  />
                )
              ))
            )}
          </div>
        </div>

        {/* Order Summary */}
        <div className="bg-white p-6 rounded-lg shadow-md h-fit sticky top-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-bluish-grey">Order Summary</h3>
            <IconShoppingCart size={20} className="text-hazel-green" />
          </div>
          
          <div className="space-y-4 max-h-[300px] overflow-y-auto mb-4">
            {orderItems.map((item) => (
              <div key={item.id} className="flex items-center justify-between py-2 border-b">
                <div className="flex-1">
                  <p className="font-medium text-bluish-grey">{item.name}</p>
                  <p className="text-sm text-gray-600">${item.price.toFixed(2)}</p>
                </div>
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity - 1, item.type)}
                    className="px-2 py-1 bg-grey-goose text-bluish-grey rounded hover:bg-sage hover:text-white transition-colors"
                  >
                    -
                  </button>
                  <span className="w-8 text-center">{item.quantity}</span>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity + 1, item.type)}
                    className="px-2 py-1 bg-grey-goose text-bluish-grey rounded hover:bg-sage hover:text-white transition-colors"
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="border-t pt-4">
            <div className="flex justify-between mb-4">
              <span className="font-semibold text-bluish-grey">Total Amount:</span>
              <span className="font-semibold text-hazel-green">
                ${totalAmount.toFixed(2)}
              </span>
            </div>
            
            <button
              onClick={handleSubmitOrder}
              disabled={orderItems.length === 0 || loading}
              className={`w-full py-2 rounded-md text-white transition-colors ${
                orderItems.length === 0 || loading
                  ? 'bg-grey-goose cursor-not-allowed'
                  : 'bg-hazel-green hover:bg-sage'
              }`}
            >
              {loading ? 'Processing...' : 'Submit Order'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}