import React from 'react';

interface QuickLinkProps {
  label: string;
  primary?: boolean;
}

export function QuickLink({ label, primary }: QuickLinkProps) {
  return (
    <button
      className={`
        px-3 py-2 rounded-md text-center transition-all duration-200 text-sm font-medium w-full
        ${primary 
          ? 'bg-watermelon text-white hover:bg-salmon-pink shadow-sm hover:shadow-md' 
          : 'bg-dawn-pink text-watermelon hover:bg-salmon-pink hover:text-white shadow-xs hover:shadow-sm'
        }
      `}
    >
      {label}
    </button>
  );
}