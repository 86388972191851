import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons'; // Adjust path as needed
import CustomTable from '../../../../../components/table-components/table'; // Adjust path as needed
import Loading from '../../../../../components/loader'; // Adjust path as needed
import { getTransactions, deleteTransaction } from '../../../../../api/accounting'; // API methods

function TransactionAccountingList() {
    const [transactionList, setTransactionList] = useState([]);
    const [fetchingInitialData, setFetchingInitialData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const getTransactionList = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getTransactions(
                rowsPerPage,
                currentPage + 1,
                searchText
            );
            console.log(response);
            setTransactionList(response.data?.transactions || []);
            setTotalCount(response.data?.count || 0);
        } catch (err) {
            setErrorMessage(err?.response?.data?.message || err.message);
        } finally {
            setFetchingInitialData(false);
            setLoading(false);
        }
    }, [currentPage, rowsPerPage, searchText]);

    useEffect(() => {
        getTransactionList();
    }, [getTransactionList]);

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            await deleteTransaction(id);
            setTransactionList((prevState) => prevState.filter((transaction) => transaction.id !== id));
            setTotalCount(totalCount - 1);
        } catch (err) {
            return err;
        } finally {
            setLoading(false);
        }
    };

    if (fetchingInitialData) {
        return <Loading />;
    }

    if (!fetchingInitialData && errorMessage) {
        return (
            <div className='text-center'>
                <h1>{errorMessage}</h1>
            </div>
        );
    }

    return (
        <div className='p-10'>
            <AddButton entity='transactions' link='/accounting/transactions/' title='New Transaction' />

            <CustomTable
                rows={transactionList || []}
                columns={[
                    {
                        title: 'Transaction Date',
                        renderValue: (item) => {
                            if (!item.transactionDate) return 'N/A';
                            try {
                                return new Date(item.transactionDate).toLocaleDateString();
                            } catch (e) {
                                return 'Invalid Date';
                            }
                        }
                    },
                    {
                        title: 'Amount',
                        renderValue: (item) => {
                            const amount = parseFloat(item.amount);
                            return isNaN(amount) ? '$0.00' : `$${amount.toFixed(2)}`;
                        }
                    },
                    {
                        title: 'Payment Type',
                        renderValue: (item) => String(item.paymentType || 'N/A')
                    },
                    {
                        title: 'Description',
                        renderValue: (item) => String(item.description || 'N/A')
                    },
                    {
                        title: 'Is Paid?',
                        renderValue: (item) => String(item.isPaid ? 'Yes' : 'No')
                    },
                    {
                        title: 'Transaction Type',
                        renderValue: (item) => String(item.transactionType || 'N/A')
                    },
                    {
                        title: 'Client',
                        renderValue: (item) => {
                            if (!item.client) return 'N/A';
                            return String(item.client.name || 'N/A');
                        }
                    },
                    {
                        title: 'Supplier',
                        renderValue: (item) => {
                            if (!item.supplier) return 'N/A';
                            return String(item.supplier.businessName || 'N/A');
                        }
                    },
                    {
                        title: 'Due Date',
                        renderValue: (item) => {
                            if (!item.dueDate) return 'N/A';
                            try {
                                return new Date(item.dueDate).toLocaleDateString();
                            } catch (e) {
                                return 'Invalid Date';
                            }
                        }
                    },
                    {
                        title: 'Transaction Number',
                        renderValue: (item) => String(item.transactionNumber || 'N/A')
                    },
                    {
                        title: 'Accepted By',
                        renderValue: (item) => String(item.acceptedBy || 'N/A')
                    }
                ]}
                entity='transactions'
                setSearchText={setSearchText}
                loading={loading}
                showMoreActions={true}
                editLink='/transactions'
                onDelete={handleDelete}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalCount={totalCount}
            />
        </div>
    );
}

export default TransactionAccountingList;
