import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal, Input } from 'antd';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../components/loader';
import CustomTable from '../../../components/table-components/table';
import { getAllReservations, cancelReservation } from '../../../api/venueReservation';
import ConfirmMessage from '../../../components/alerts/alert-message';
import './VenueReservationsList.css';

const { TextArea } = Input;

function ReservationList() {
  const [reservationList, setReservationList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [reservationToCancel, setReservationToCancel] = useState(null);

  useEffect(() => {
    getReservationList();
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const getReservationList = async () => {
    try {
      const response = await getAllReservations();
      setReservationList(response.data);
      setTotalCount(response.data.length);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  };

  const handleCancelClick = (reservation) => {
    // Check if reservation is in the past
    if (dayjs(reservation.endDate).isBefore(dayjs())) {
      Modal.error({
        title: 'Cannot Cancel Reservation',
        content: 'Past reservations cannot be cancelled.'
      });
      return;
    }
    
    setReservationToCancel(reservation);
    setShowCancelModal(true);
  };

  const handleCancelConfirm = async () => {
    if (!cancelReason.trim()) {
      Modal.error({
        title: 'Error',
        content: 'Please provide a reason for cancellation.'
      });
      return;
    }

    try {
      setLoading(true);
      await cancelReservation(reservationToCancel.id, { cancelReason });
      
      // First close the cancel modal
      setShowCancelModal(false);
      setReservationToCancel(null);
      setCancelReason('');
      
      // Then show success message
      Modal.success({
        title: 'Success',
        content: 'Reservation cancelled successfully.',
        onOk: () => {
          // Refresh the list after success modal is closed
          getReservationList();
        }
      });
    } catch (err) {
      Modal.error({
        title: 'Error',
        content: err?.response?.data?.error || 'Failed to cancel reservation'
      });
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  const canCancelReservation = (reservation) => {
    return reservation.status === 'ACTIVE' && !dayjs(reservation.endDate).isBefore(dayjs());
  };

  return (
    <div className='p-10'>
      <CustomTable
        rows={reservationList}
        columns={[
          {
            title: 'Reservation ID',
            renderValue: (item) => item.id
          },
          {
            title: 'Venue Name',
            renderValue: (item) => item.venue.name
          },
          {
            title: 'Start Date',
            renderValue: (item) => dayjs(item.startDate).format('DD/MM/YYYY')
          },
          {
            title: 'End Date',
            renderValue: (item) => dayjs(item.endDate).format('DD/MM/YYYY')
          },
          {
            title: 'Number of Guests',
            renderValue: (item) => item.numberOfGuests
          },
          {
            title: 'Menu Name',
            renderValue: (item) => item.menu ? item.menu.name : 'N/A'
          },
          {
            title: 'Status',
            renderValue: (item) => (
              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                ${item.status === 'ACTIVE' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {item.status}
              </span>
            )
          },
          {
            title: 'Actions',
            renderValue: (item) => canCancelReservation(item) && (
              <button
                onClick={() => handleCancelClick(item)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition-colors flex items-center gap-1"
              >
                <IconX size={16} />
                Cancel
              </button>
            )
          }
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />

      <Modal
        title={
          <div className="flex items-center gap-2 text-lg font-semibold text-bluish-grey border-b border-grey-goose pb-3">
            <IconX className="text-watermelon" size={24} />
            Cancel Reservation
          </div>
        }
        open={showCancelModal}
        onOk={handleCancelConfirm}
        onCancel={() => {
          setShowCancelModal(false);
          setReservationToCancel(null);
          setCancelReason('');
        }}
        okText="Confirm Cancellation"
        cancelText="Close"
        centered
        width={500}
        className="custom-modal"
        okButtonProps={{
          className: 'bg-watermelon hover:bg-salmon-pink border-watermelon hover:border-salmon-pink'
        }}
        cancelButtonProps={{
          className: 'hover:border-bluish-grey hover:text-bluish-grey'
        }}
      >
        <div className="py-4">
          <div className="mb-4">
            <h3 className="text-base font-medium text-bluish-grey mb-2">Reservation Details</h3>
            <div className="bg-seashell p-3 rounded-md">
              <p className="text-sm text-bluish-grey mb-1">
                <span className="font-medium">Venue:</span> {reservationToCancel?.venue?.name}
              </p>
              <p className="text-sm text-bluish-grey mb-1">
                <span className="font-medium">Date:</span> {reservationToCancel ? dayjs(reservationToCancel.startDate).format('DD/MM/YYYY') : ''}
              </p>
              <p className="text-sm text-bluish-grey">
                <span className="font-medium">Guests:</span> {reservationToCancel?.numberOfGuests}
              </p>
            </div>
          </div>
          
          <div className="mb-2">
            <label className="block text-sm font-medium text-bluish-grey mb-2">
              Cancellation Reason <span className="text-watermelon">*</span>
            </label>
            <TextArea
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              rows={4}
              placeholder="Please provide a detailed reason for cancellation..."
              className="w-full border-grey-goose rounded-md shadow-sm focus:border-sage focus:ring-1 focus:ring-sage"
              maxLength={500}
              showCount
            />
          </div>
          <p className="text-xs text-silver mt-2">
            Note: This action cannot be undone. The reservation will be permanently cancelled.
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default ReservationList;
