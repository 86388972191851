import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import CustomSelectInput from '../../components/custom-select-input';
import SelectInput from '../../components/select-input';
import { getDishSections, createOrUpdateDishSection, updateDishSection } from '../../api/dishSection';
import { getAvailableProducts } from '../../api/products';
import { getMenu } from '../../api/menus';
import { uploadImage } from '../../api/api';
import { IconUpload, IconX } from '@tabler/icons-react';
import { dishTypes, units } from '../../constants/entities';

function DishSectionForm({ isEdit, dishSection = {} }) {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const getProductsList = useCallback(async () => {
    try {
      const response = await getAvailableProducts();
      const formattedProducts = response.data.map((product) => ({
        id: product.id,
        name: product.name,
        unit: product.unit
      }));
      setProducts(formattedProducts);
    } catch (err) {
      console.error('Failed to fetch products:', err);
    }
  }, []);

  const getMenusList = useCallback(async () => {
    try {
      const response = await getMenu();
      const formattedMenus = response.data.map((menu) => ({
        id: menu.id,
        name: menu.name
      }));
      setMenus(formattedMenus);
    } catch (err) {
      console.error('Failed to fetch menus:', err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getProductsList();
      await getMenusList();
      setLoading(false);
    };
    fetchData();
  }, [getProductsList, getMenusList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required*'),
    type: Yup.string().required('Type is required*'),
    price: Yup.number().required('Price is required*').positive(),
    description: Yup.string().min(4).required('Description is required*'),
    imageUrl: Yup.string().nullable(),
    menuId: Yup.string().required('Menu is required*'),
    products: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Product is required*'),
        quantity: Yup.number().required('Quantity is required*').positive(),
        unit: Yup.string().required('Unit is required*'),
      })
    ).when('isEdit', {
      is: false,
      then: (schema) => schema.min(1, 'At least one product is required*'),
      otherwise: (schema) => schema,
    }),
  });

  console.log('DishSection received:', dishSection);

  const initialValues = {
    name: dishSection?.name ?? '',
    type: dishSection?.type ?? '',
    price: dishSection?.price ?? '',
    description: dishSection?.description ?? '',
    imageUrl: dishSection?.imageUrl ?? '',
    menuId: dishSection?.menuId ?? '',
    products: Array.isArray(dishSection?.products) && dishSection.products.length > 0
      ? dishSection.products.map(product => ({
          id: product.id || '',
          quantity: product.quantity || 1,
          unit: product.unit || ''
        }))
      : [{ id: '', quantity: 1, unit: '' }]
  };

  console.log('Form initial values:', initialValues);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 p-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            let imageUrl = values.imageUrl;
            if (uploadedFile) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              const uploadResponse = await uploadImage(formData);
              imageUrl = uploadResponse.data.url;
            }

            if (!imageUrl && !uploadedFile) {
              imageUrl = null;
            }

            // Transform products data to include product name from the products list
            const transformedProducts = values.products.map(product => {
              const selectedProduct = products.find(p => p.id === product.id);
              return {
                ...product,
                productName: selectedProduct?.name || ''
              };
            });

            if (isEdit) {
              await updateDishSection(dishSection.id, { 
                ...values, 
                imageUrl,
                products: transformedProducts
              });
            } else {
              await createOrUpdateDishSection({ 
                ...values, 
                imageUrl,
                products: transformedProducts
              });
            }
            
            navigate('/dishes/dishes_list');
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data?.message || 'An error occurred' });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='space-y-5'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomSelectInput
                name='type'
                value={values.type}
                label='Type'
                options={dishTypes.map(type => ({ id: type, title: type }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.type && touched.type}
                errorMessage={errors.type}
              />
              <CustomInput
                type='number'
                name='price'
                label='Price'
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.price && touched.price}
                errorMessage={errors.price}
              />
              <CustomInput
                type='text'
                name='description'
                label='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />
              <CustomSelectInput
                name='menuId'
                value={values.menuId}
                label='Menu'
                options={menus.map(menu => ({ id: menu.id, title: menu.name }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.menuId && touched.menuId}
                errorMessage={errors.menuId}
              />
            </div>

            <FieldArray
              name="products"
              render={arrayHelpers => (
                <div>
                  {values.products.map((product, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <SelectInput
                        value={product.id}
                        label="Product"
                        options={products.map(p => ({ id: p.id, title: p.name }))}
                        onChange={e => setFieldValue(`products.${index}.id`, e.target.value)}
                      />
                      <CustomInput
                        type="number"
                        name={`products.${index}.quantity`}
                        label="Quantity"
                        value={product.quantity}
                        onChange={(event) => setFieldValue(`products.${index}.quantity`, event.target.value)}
                      />
                      <CustomSelectInput
                        name={`products.${index}.unit`}
                        value={product.unit}
                        label="Unit"
                        options={units}
                        onChange={(e) => setFieldValue(`products.${index}.unit`, e.target.value)}
                        onBlur={handleBlur}
                      />
                      <button
                        type="button"
                        className="text-red-500"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <IconX />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="mt-3 text-sm text-blue-500"
                    onClick={() => arrayHelpers.push({ id: '', unit: '', quantity: 1 })}
                  >
                    Add product
                  </button>
                </div>
              )}
            />

            {errors.submit && <p className='text-error'>{errors.submit}</p>}

            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/dish-sections')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Dish Section' : 'Create Dish Section'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default DishSectionForm;
