import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import Loading from '../../components/loader';
import ConfirmMessage from '../../components/alerts/alert-message';
import { fetcher } from '../../api/api';
import { deleteConferenceRoom as deleteConferenceRoomApi } from '../../api/conferenceRoom';
import { getAllReservations } from '../../api/conferenceRoomReservation';
import { getAllMenus, deleteMenu as deleteMenuApi } from '../../api/conferenceRoomMenu'; // Add this line

function MenuCard({ menu, setMenuToDeleteId, setShowMenuDeleteAlert }) {
  // Calculate total items
  const totalItems = 
    menu._count?.breakfastItems +
    menu._count?.lunchItems +
    menu._count?.dinnerItems +
    menu._count?.breakfastBarItems +
    menu._count?.lunchBarItems +
    menu._count?.dinnerBarItems || 0;

  return (
    <div className='bg-white p-4 rounded-md shadow border border-gray-100 hover:shadow-md transition-shadow duration-200'>
      <div className='flex justify-between items-start'>
        <div>
          <h3 className='text-hazel-green text-lg font-semibold mb-1 truncate'>{menu.name}</h3>
          <p className='text-sm text-gray-500'>{menu.conferenceRoom?.name || 'No room assigned'}</p>
        </div>
        <p className='text-sage font-semibold'>${menu.price?.toFixed(2)}</p>
      </div>
      
      <div className='mt-3'>
        <p className='text-sm font-medium text-gray-700'>
          Total Items: {totalItems}
        </p>
      </div>

      <div className='flex justify-end gap-x-2 mt-3 pt-2 border-t border-gray-100'>
        <Link 
          to={`/conferenceRoom/menus/edit/${menu.id}`} 
          className="text-sage hover:text-sage-dark transition-colors duration-200"
        >
          <IconPencil size={18} />
        </Link>
        <button 
          onClick={() => {
            setMenuToDeleteId(menu.id);
            setShowMenuDeleteAlert(true);
          }}
          className="text-sage hover:text-red-600 transition-colors duration-200"
        >
          <IconTrash size={18} />
        </button>
      </div>
    </div>
  );
}

function MainConferenceRoomPage() {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [roomToDeleteId, setRoomToDeleteId] = useState('');
  const [latestReservations, setLatestReservations] = useState([]);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [menus, setMenus] = useState([]);
  const [showMenuDeleteAlert, setShowMenuDeleteAlert] = useState(false);
  const [menuToDeleteId, setMenuToDeleteId] = useState('');

  const { data: initialRooms, error: roomsError, isValidating: roomsValidating } = useSWR('/api/conferenceRoom/conference-rooms', fetcher);
  const { data: reservations, error: reservationsError } = useSWR('/api/conferenceRoomReservation', fetcher);
  const { data: initialMenus, error: menusError } = useSWR('/api/conferenceRoomMenu', fetcher);

  useEffect(() => {
    if (initialRooms) {
      setConferenceRooms(initialRooms);
    }
  }, [initialRooms]);

  useEffect(() => {
    if (reservations) {
      console.log("Reservations data:", reservations);
      const latest = reservations.reduce((acc, res) => {
        if (!acc[res.roomId] || new Date(res.startDate) > new Date(acc[res.roomId].startDate)) {
          acc[res.roomId] = res;
        }
        return acc;
      }, {});
      const latestReservations = Object.values(latest);
      console.log("Latest Reservations after reduction:", latestReservations);
      setLatestReservations(latestReservations);
    }
  }, [reservations]);

  useEffect(() => {
    if (initialMenus) {
      setMenus(initialMenus);
    }
  }, [initialMenus]);

  if (roomsError || reservationsError || menusError) return <div>Error loading data...</div>;
  if (roomsValidating) return <Loading />;

  const handleDeleteRoom = async () => {
    try {
      await deleteConferenceRoomApi(roomToDeleteId);
      // Update state by filtering out the deleted room
      setConferenceRooms(conferenceRooms.filter(room => room.id !== roomToDeleteId));
      setSelectedRoom(null);
      setShowDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete room:', err);
    }
  };

  const handleDeleteMenu = async () => {
    try {
      await deleteMenuApi(menuToDeleteId);
      setMenus(menus.filter(menu => menu.id !== menuToDeleteId));
      setMenuToDeleteId('');
      setShowMenuDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete menu:', err);
    }
  };

  return (
    <div className='p-10'>
      {/* Existing Conference Rooms Section */}
      <div className='mb-10'>
        <div className='flex flex-col sm:flex-row justify-end gap-y-3 sm:gap-x-3 mb-8'>
          <Link
            to='/conferenceRoom/create-conference-room'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New Conference Room
          </Link>
          <Link
            to='/conferenceRoom/add-new-reservation'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New Reservation
          </Link>
          <Link
            to='/conferenceRoom/reservations-list'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            Reservations List
          </Link>
        </div>

        <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
          {conferenceRooms && conferenceRooms.length > 0 ? (
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
              {conferenceRooms.map((room) => {
                const latestReservation = latestReservations.find(res => Number(res.conferenceRoomId) === Number(room.id));
                return (
                  <div key={room.id} className='bg-seashell p-5 rounded-md shadow-lg'>
                    <img src={`https://api-staff.lakesideks.com${room.imagePath}`} alt={room.name} className='w-full h-40 mb-6 object-cover rounded-md' />
                    <h3 className='text-hazel-green text-xl font-semibold mt-3'>{room.name}</h3>
                    <p className='text-hazel-green'>{room.location}</p>
                    <p className='text-hazel-green'>Capacity: {room.capacity}</p>
                    <p className='text-hazel-green'>Price per Hour: ${room.pricePerHour}</p>
                    {latestReservation ? (
                      <div className='text-sm mt-3 p-4 border border-gray-200 rounded-md text-bluish-grey'>
                        <h4 className='text-md font-semibold mb-2'>Latest Reservation</h4>
                        <p>Date: {new Date(latestReservation.startDate).toLocaleDateString()}</p>
                        <p>Number of Guests: {latestReservation.numberOfGuests}</p>
                      </div>
                    ) : (
                      <p className='mt-3 text-gray-600'>No reservations</p>
                    )}
                    <div className='flex justify-end gap-x-2 mt-3'>
                      <Link to={`/conference-rooms/edit/${room.id}`}>
                        <IconPencil className='text-sage cursor-pointer' size={20} />
                      </Link>
                      <IconTrash
                        className='text-sage cursor-pointer'
                        size={20}
                        onClick={() => {
                          setRoomToDeleteId(room.id);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className='text-sage font-bold py-10 px-8'>There are no conference rooms to show!</p>
          )}
        </div>
      </div>

      {/* Menus Section */}
      <div className="mt-12">
        <h2 className="text-2xl font-semibold text-hazel-green mb-4">Conference Room Menus</h2>
        <div className='flex flex-col sm:flex-row justify-end gap-y-3 sm:gap-x-3 mb-4'>
          <Link
            to='/conferenceRoom/menu'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2 text-sm'
          >
            <IconPlus className='mr-2' size={16} />
            Create Menu
          </Link>
        </div>
        <div className='border border-gray-200 rounded-xl shadow-lg p-4'>
          {menus && menus.length > 0 ? (
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
              {menus.map((menu) => (
                <MenuCard 
                  key={menu.id} 
                  menu={menu} 
                  setMenuToDeleteId={setMenuToDeleteId}
                  setShowMenuDeleteAlert={setShowMenuDeleteAlert}
                />
              ))}
            </div>
          ) : (
            <p className='text-sage font-medium py-8 px-4 text-center'>
              There are no menus to show. Create a new menu to get started!
            </p>
          )}
        </div>
      </div>

      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this conference room?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={() => handleDeleteRoom()}
        />
      )}

      {showMenuDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this menu?'
          onCancel={() => setShowMenuDeleteAlert(false)}
          onConfirm={() => handleDeleteMenu()}
        />
      )}
    </div>
  );
}

export default MainConferenceRoomPage;
