import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import DishSectionForm from './DishSectionForm';
import Loading from '../../components/loader';
import { getDishSectionById } from '../../api/dishSection';

function EditDishSection() {
  const { id } = useParams();
  const [dishSection, setDishSection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDishSection = async () => {
      try {
        const response = await getDishSectionById(id);
        const dishSectionData = response.data.message; // Extract from message property
        
        // Format the data to include products from DishSectionRecipe
        const formattedDishSection = {
          id: dishSectionData.id,
          name: dishSectionData.name,
          type: dishSectionData.type,
          price: dishSectionData.price,
          description: dishSectionData.description,
          imageUrl: dishSectionData.imageUrl,
          menuId: dishSectionData.menuId,
          products: dishSectionData.DishSectionRecipe?.length > 0 
            ? dishSectionData.DishSectionRecipe.map(recipe => ({
                id: recipe.id,
                quantity: recipe.quantity,
                unit: recipe.unit
              }))
            : []
        };
        
        console.log('Fetched dish section data:', dishSectionData);
        console.log('Formatted dish section:', formattedDishSection);
        setDishSection(formattedDishSection);
      } catch (error) {
        console.error('Failed to fetch dish section:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDishSection();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUtensils}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Edit dish section'
      />
      <DishSectionForm isEdit={true} dishSection={dishSection} />
    </div>
  );
}

export default EditDishSection;