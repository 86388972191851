import React, { useState, useEffect } from 'react';
import { Wine, ShoppingBag, Plus, X, DollarSign } from 'lucide-react';

interface ExtraChargesProps {
  roomNumber: string;
  fromDate: string;
  cleaningReport?: {
    minibarChecklist: Array<{
      id: string;
      name: string;
      checked: boolean;
      quantity: number;
      status: string;
    }>;
    inventoryChecklist: Array<{
      id: string;
      name: string;
      checked: boolean;
      quantity: number;
      status: string;
    }>;
  };
  roomServiceCharges: Array<{
    id: string;
    totalAmount: number;
    orderItems: Array<{
      id: string;
      productName?: string;
      price: number;
      quantity: number;
      type: string;
      category: string;
    }>;
    createdAt: string;
  }>;
  onChargesUpdate: (total: number) => void;
}

interface CustomCharge {
  description: string;
  amount: string;
}

export default function ExtraCharges({ roomNumber, fromDate, cleaningReport, roomServiceCharges, onChargesUpdate }: ExtraChargesProps) {
  const [customCharges, setCustomCharges] = useState<CustomCharge[]>([
    { description: '', amount: '' }
  ]);

  useEffect(() => {
    // Filter room service charges based on reservation date
    const validRoomServiceCharges = roomServiceCharges.filter(charge => {
      const chargeDate = new Date(charge.createdAt);
      const reservationStart = new Date(fromDate);
      return chargeDate >= reservationStart;
    });

    // Calculate charges based on missing items
    const minibarTotal = cleaningReport?.minibarChecklist
      ?.filter(item => item.status === 'Missing')
      .reduce((sum, item) => sum + (item.quantity || 0) * 5, 0) || 0;

    const inventoryTotal = cleaningReport?.inventoryChecklist
      ?.filter(item => item.status === 'Missing')
      .reduce((sum, item) => sum + (item.quantity || 0) * 10, 0) || 0;

    const customTotal = customCharges
      .reduce((sum, charge) => sum + (Number(charge.amount) || 0), 0);

    // Only include valid room service charges
    const roomServiceTotal = validRoomServiceCharges
      .reduce((sum, charge) => sum + charge.totalAmount, 0);

    console.log('Extra charges breakdown:', {
      minibarTotal,
      inventoryTotal,
      customTotal,
      roomServiceTotal,
      validCharges: validRoomServiceCharges.length,
      totalCharges: roomServiceCharges.length
    });

    const total = minibarTotal + inventoryTotal + customTotal + roomServiceTotal;
    onChargesUpdate(total);
  }, [cleaningReport, customCharges, roomServiceCharges, fromDate, onChargesUpdate]);

  const addCustomCharge = () => {
    setCustomCharges([...customCharges, { description: '', amount: '' }]);
  };

  const removeCustomCharge = (index: number) => {
    setCustomCharges(customCharges.filter((_, i) => i !== index));
  };

  const updateCustomCharge = (index: number, field: keyof CustomCharge, value: string) => {
    const updatedCharges = [...customCharges];
    updatedCharges[index] = { ...updatedCharges[index], [field]: value };
    setCustomCharges(updatedCharges);
  };

  const services = [
    {
      title: 'Room Service Orders',
      icon: DollarSign,
      items: roomServiceCharges
        .filter(charge => {
          const chargeDate = new Date(charge.createdAt);
          const reservationStart = new Date(fromDate);
          return chargeDate >= reservationStart;
        })
        .map(charge => ({
          id: charge.id,
          name: charge.orderItems.map(item => `${item.productName || 'Item'} (${item.quantity}x)`).join(', '),
          amount: charge.totalAmount,
          date: new Date(charge.createdAt).toLocaleString()
        }))
    },
    {
      title: 'Minibar Items',
      icon: Wine,
      items: cleaningReport?.minibarChecklist || [],
      pricePerUnit: 5
    },
    {
      title: 'Inventory Items',
      icon: ShoppingBag,
      items: cleaningReport?.inventoryChecklist || [],
      pricePerUnit: 10
    }
  ];

  return (
    <div className="p-3 space-y-3 bg-tealish-blue">
      <div className="bg-white rounded-lg p-3 shadow-sm">
        <h2 className="text-sm font-semibold text-hazel-green mb-2">
          Extra Charges - Room {roomNumber}
        </h2>

        <div className="grid grid-cols-2 gap-3">
          {services.map((service) => (
            <div key={service.title} className="mb-2">
              <div className="flex items-center gap-1 mb-1">
                <service.icon className="h-3 w-3 text-hazel-green" />
                <h3 className="text-xs font-medium text-bluish-grey">{service.title}</h3>
              </div>

              <div className="max-h-32 overflow-y-auto pr-1 space-y-1">
                {service.title === 'Room Service Orders' ? (
                  service.items.map((item: any) => (
                    <div
                      key={item.id}
                      className="flex items-center justify-between py-1 px-2 bg-seashell rounded"
                    >
                      <div className="flex-1 min-w-0">
                        <p className="text-xs font-medium text-bluish-grey truncate">{item.name}</p>
                        <p className="text-[10px] text-gray-500">{item.date}</p>
                      </div>
                      <p className="text-xs font-semibold text-hazel-green ml-1 whitespace-nowrap">
                        ${item.amount.toFixed(2)}
                      </p>
                    </div>
                  ))
                ) : (
                  service.items
                    .filter(item => item.status === 'Missing')
                    .map((item) => (
                      <div
                        key={item.id}
                        className="flex items-center justify-between py-1 px-2 bg-seashell rounded"
                      >
                        <div className="flex items-center gap-1 flex-1 min-w-0">
                          <span className="text-xs font-medium truncate">{item.name}</span>
                          <span className="text-[10px] px-1 py-0.5 bg-dawn-pink text-salmon-pink rounded-full">
                            Missing
                          </span>
                          <span className="text-[10px] text-bluish-grey whitespace-nowrap">
                            x{item.quantity || 1}
                          </span>
                        </div>
                        <span className="text-xs font-medium text-hazel-green ml-1 whitespace-nowrap">
                          ${(item.quantity || 1) * service.pricePerUnit}
                        </span>
                      </div>
                    ))
                )}
                {service.items.filter(item => 
                  service.title === 'Room Service Orders' ? true : item.status === 'Missing'
                ).length === 0 && (
                  <p className="text-[10px] text-bluish-grey text-center py-1">
                    No {service.title.toLowerCase()} to display
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-3 border-t border-grey-goose pt-2">
          <div className="flex items-center gap-1 mb-1">
            <Plus className="h-3 w-3 text-hazel-green" />
            <h3 className="text-xs font-medium text-bluish-grey">Custom Charges</h3>
          </div>

          <div className="space-y-1 max-h-24 overflow-y-auto pr-1">
            {customCharges.map((charge, index) => (
              <div key={index} className="grid grid-cols-[1fr,80px,24px] gap-1">
                <input
                  type="text"
                  placeholder="Description"
                  value={charge.description}
                  onChange={(e) => updateCustomCharge(index, 'description', e.target.value)}
                  className="w-full px-1.5 py-0.5 text-xs rounded border border-grey-goose focus:ring-1 focus:ring-hazel-green"
                />
                <input
                  type="number"
                  placeholder="Amount"
                  value={charge.amount}
                  onChange={(e) => updateCustomCharge(index, 'amount', e.target.value)}
                  className="w-full px-1.5 py-0.5 text-xs rounded border border-grey-goose focus:ring-1 focus:ring-hazel-green"
                />
                <button
                  onClick={() => removeCustomCharge(index)}
                  className="p-0.5 text-salmon-pink hover:bg-dawn-pink rounded"
                >
                  <X className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={addCustomCharge}
            className="mt-1 px-2 py-0.5 text-[10px] bg-hazel-green text-white rounded hover:bg-opacity-90 transition-colors inline-flex items-center gap-1"
          >
            <Plus className="h-2 w-2" />
            Add Custom Charge
          </button>
        </div>
      </div>
    </div>
  );
}
