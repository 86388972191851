import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a Context for the ticket state
const TicketContext = createContext();

// Create a provider component
export const TicketProvider = ({ children }) => {
  const [ticketInfo, setTicketInfo] = useState(null);

  // Function to update the ticket info
  const updateTicketInfo = (newInfo) => {
    console.log('TicketContext: Updating ticket info with:', newInfo);
    setTicketInfo(newInfo);
  };

  // Monitor state changes
  useEffect(() => {
    console.log('TicketContext: State updated to:', ticketInfo);
  }, [ticketInfo]);

  return (
    <TicketContext.Provider value={{ ticketInfo, updateTicketInfo }}>
      {children}
    </TicketContext.Provider>
  );
};

// Custom hook to use the TicketContext
export const useTicket = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error('useTicket must be used within a TicketProvider');
  }
  return context;
};
