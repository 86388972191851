import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { 
  Edit, 
  MoreHorizontal, 
  Plus, 
  ChevronDown, 
  Mail, 
  Phone,
  Building,
  MapPin
} from 'lucide-react';
import { Button } from '../../../../components/shadCN/Button.tsx';
import { Tabs, TabsList, TabsTrigger } from '../../../../components/shadCN/Tabs.tsx';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { TransactionsTab } from '../../../../components/detailsClientPageTransactionComponents/TransactionsTab.tsx';
import { getClientById, getSupplierById } from '../../../../api/accounting';
import { toast } from 'react-toastify';

const OverviewTab = ({ data, type }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data?.transactions) {
      // Process transactions for chart data
      const processedData = data.transactions
        .sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate))
        .map(transaction => ({
          date: new Date(transaction.transactionDate).toLocaleDateString(),
          amount: transaction.amount
        }));
      setChartData(processedData);
    }
  }, [data]);

  const getInitials = (name) => {
    return name
      ?.split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase() || '?';
  };

  const displayName = type === 'client' 
    ? data?.name 
    : data?.businessName;

  const email = data?.email || 'No email provided';
  const phone = data?.phone || 'No phone provided';
  const address = data?.address || 'No address provided';
  const representative = data?.representative || 'No representative';
  const businessType = data?.businessType || 'Not specified';
  const tradeName = data?.tradeName || 'Not specified';
  const ubin = data?.ubin || 'Not specified';
  const municipality = data?.municipality || 'Not specified';

  return (
    <div className="grid grid-cols-12 gap-6">
      {/* Left Column - Contact & Business Info */}
      <div className="col-span-4 space-y-6">
        {/* Contact Info */}
        <div className="bg-white rounded-xl p-6 border border-mercury">
          <div className="flex items-start gap-4">
            <div className="w-12 h-12 bg-tealish-blue rounded-full flex items-center justify-center">
              <span className="text-hazel-green font-semibold text-lg">{getInitials(displayName)}</span>
            </div>
            <div className="flex-1">
              <h2 className="text-lg font-semibold text-hazel-green">{displayName}</h2>
              <div className="space-y-2 mt-2">
                <div className="flex items-center gap-2 text-bluish-grey">
                  <Mail className="h-4 w-4" />
                  <span>{email}</span>
                </div>
                <div className="flex items-center gap-2 text-bluish-grey">
                  <Phone className="h-4 w-4" />
                  <span>{phone}</span>
                </div>
                {type === 'supplier' && (
                  <>
                    <div className="flex items-center gap-2 text-bluish-grey">
                      <Building className="h-4 w-4" />
                      <span>Trade Name: {tradeName}</span>
                    </div>
                    <div className="flex items-center gap-2 text-bluish-grey">
                      <MapPin className="h-4 w-4" />
                      <span>Municipality: {municipality}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Business Details Section */}
        <div className="bg-white rounded-xl p-6 border border-mercury">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold text-hazel-green">BUSINESS DETAILS</h3>
          </div>
          <div className="space-y-4">
            <div>
              <h4 className="text-sm text-bluish-grey mb-1">Business Type</h4>
              <p className="text-hazel-green">{businessType}</p>
            </div>
            <div>
              <h4 className="text-sm text-bluish-grey mb-1">UBIN</h4>
              <p className="text-hazel-green">{ubin}</p>
            </div>
            <div>
              <h4 className="text-sm text-bluish-grey mb-1">Representative</h4>
              <p className="text-hazel-green">{representative}</p>
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="bg-white rounded-xl p-6 border border-mercury">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold text-hazel-green">ADDRESS</h3>
          </div>
          <div className="space-y-4">
            <div>
              <h4 className="text-sm text-bluish-grey mb-1">Primary Address</h4>
              <p className="text-hazel-green">{address}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Center Column - Financial Chart */}
      <div className="col-span-5">
        <div className="bg-white rounded-xl p-6 border border-mercury h-full">
          <h3 className="font-semibold text-hazel-green mb-6">FINANCIAL OVERVIEW</h3>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData} margin={{ top: 10, right: 30, left: 20, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis 
                  dataKey="date" 
                  stroke="#6B7280"
                  tick={{ fill: '#6B7280', fontSize: 12 }}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis 
                  stroke="#6B7280"
                  tick={{ fill: '#6B7280', fontSize: 12 }}
                  tickFormatter={(value) => `€${value}`}
                />
                <Line 
                  type="monotone" 
                  dataKey="amount" 
                  stroke="#4B6455" 
                  strokeWidth={3}
                  dot={{ fill: '#4B6455', r: 5 }}
                  activeDot={{ r: 8, fill: '#4B6455' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Right Column - Quick Stats */}
      <div className="col-span-3 space-y-6">
        <div className="bg-white rounded-xl p-6 border border-mercury">
          <h3 className="font-semibold text-hazel-green mb-4">QUICK STATS</h3>
          <div className="space-y-4">
            <div>
              <h4 className="text-sm text-bluish-grey">Total Transactions</h4>
              <p className="text-2xl font-semibold text-hazel-green">{data?.transactions?.length || 0}</p>
            </div>
            <div>
              <h4 className="text-sm text-bluish-grey">Outstanding Balance</h4>
              <p className="text-2xl font-semibold text-hazel-green">€0.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function DetailsPage() {
  const [activeTab, setActiveTab] = useState('overview');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  
  // Updated type detection based on URL path segments
  const type = location.pathname.includes('/accounting/clients/') ? 'client' : 'supplier';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log('Fetching data for type:', type, 'with ID:', id);
        const response = type === 'client' 
          ? await getClientById(id)
          : await getSupplierById(id);
        
        if (!response.data) {
          throw new Error(`${type} not found`);
        }
        
        setData(response.data);
      } catch (error) {
        console.error(`Error fetching ${type} details:`, error);
        toast.error(`Failed to load ${type} details: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, type]);

  if (loading) {
    return (
      <div className="min-h-screen bg-seashell flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-hazel-green"></div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="min-h-screen bg-seashell flex items-center justify-center">
        <div className="text-hazel-green">No {type} found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-seashell">
      {/* Header */}
      <div className="bg-white border-b border-mercury px-8 py-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4">
            <h1 className="text-2xl font-semibold text-hazel-green">
              {type === 'client' ? data.name : data.businessName}
            </h1>
          </div>
          <div className="flex items-center gap-3">
            <Button variant="outline" size="sm" className="text-bluish-grey">
              <Edit className="h-4 w-4 mr-2" />
              Edit
            </Button>
            <Button className="bg-hazel-green hover:bg-sage text-white">
              <Plus className="h-4 w-4 mr-2" />
              New Transaction
            </Button>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
            </Button>
          </div>
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="border-b border-mercury">
            {['Overview', 'Transactions'].map((tab) => (
              <TabsTrigger
                key={tab}
                value={tab.toLowerCase()}
                className="px-4 py-2 -mb-px data-[state=active]:border-b-2 data-[state=active]:border-hazel-green data-[state=active]:text-hazel-green"
              >
                {tab}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>

      {/* Content */}
      <div className="container mx-auto py-8 px-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            {activeTab === 'overview' 
              ? <OverviewTab data={data} type={type} /> 
              : <TransactionsTab clientId={type === 'client' ? id : null} supplierId={type === 'supplier' ? id : null} />
            }
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}