import React, { useEffect, useState } from "react";
import { getPaginatedOrders } from "../../api/restaurantInterface";
import { Link } from "react-router-dom";
import { getRoomOrderById } from "../../api/roomOrders";

function RestaurantOrdersList() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const limit = 10;
  const [roomOrders, setRoomOrders] = useState({});

  const fetchRoomOrderDetails = async (roomOrderId) => {
    try {
      const response = await getRoomOrderById(roomOrderId);
      setRoomOrders(prev => ({
        ...prev,
        [roomOrderId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching room order:', error);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await getPaginatedOrders(page, limit);
        const data = response.data ? response.data : [];
        console.log('Fetched Orders:', data.orders);
        setOrders(data.orders);
        
        data.orders.forEach(order => {
          if (order.roomOrderId) {
            fetchRoomOrderDetails(order.roomOrderId);
          }
        });

        setTotalPages(Math.ceil(data.totalCount / limit));
        setTotalEntries(data.totalCount);
      } catch (error) {
        setError('Failed to fetch orders.');
        console.error('Failed to fetch orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [page]);

  const handleFirstPage = () => {
    setPage(1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  const getOrderLocation = (order) => {
    let location = [];
    
    // Add table number if it exists
    if (order.table) {
      location.push(`Table ${order.table.number}`);
    }
    
    // Add room number if it's a room service order with roomOrderId
    if (order.isRoomService && order.roomOrderId && roomOrders[order.roomOrderId]) {
      location.push(`Room ${roomOrders[order.roomOrderId].roomNumber}`);
    }
    
    return location.length > 0 ? location.join(' - ') : 'N/A';
  };

  return (
    <div className="px-6 py-3 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">ID</th>
              <th scope="col" className="px-6 py-3">Location</th>
              <th scope="col" className="px-6 py-3">Type</th>
              <th scope="col" className="px-6 py-3">Items</th>
              <th scope="col" className="px-6 py-3">Total Price</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Environment</th>
              <th scope="col" className="px-6 py-3">Created At</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <Link className="cursor-pointer" to={`/restaurant-interface/orders/${order.id}`}>
                    <th scope="row" className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline">
                      {order.id}
                    </th>
                  </Link>

                  <td className="px-6 py-4">
                    {getOrderLocation(order)}
                  </td>

                  <td className="px-6 py-4">
                    {order.isRoomService ? 'Room Service' : 'Table Order'}
                  </td>

                  <td className="px-6 py-4">
                    {order.OrderDishSection?.map(ds => (
                      <div key={ds.dishSection.id} className="text-xs flex flex-col justify-between items-center text-center">
                        <span>{ds.dishSection.name}</span>
                        <span className="ml-2 text-gray-500">
                          x{ds.quantity} - ${ds.price}
                        </span>
                      </div>
                    ))}
                  </td>
                  <td className="px-6 py-4">${order.totalPrice.toFixed(2)}</td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      order.status === 'paid' ? 'bg-green-100 text-green-800' :
                      order.status === 'unpaid' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {order.status}
                    </span>
                  </td>
                  <td className="px-6 py-4">{order.environment?.name || 'N/A'}</td>
                  <td className="px-6 py-4">{new Date(order.createdAt).toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="py-2 px-4 border-b text-center text-gray-500">
                  No orders found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-center z-10 mt-4">
        <div className="inline-flex space-x-4 mt-2 xs:mt-0">
          <button
            onClick={handleFirstPage}
            disabled={page === 1}
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900"
          >
            First
          </button>
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900"
          >
            Prev
          </button>
          <span className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-900">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900"
          >
            Next
          </button>
          <button
            onClick={handleLastPage}
            disabled={page === totalPages}
            className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-e hover:bg-gray-900"
          >
            Last
          </button>
        </div>
      </div>
    </div>
  );
}

export default RestaurantOrdersList;
