import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableBody,
  TablePagination,
} from '@mui/material';
import { TableHeadCustom, SearchFilter, CustomTableRow } from './index';
import Loading from '../loader';
import ConfirmMessage from '../alerts/alert-message';


function CustomTable({
  rows,
  columns,
  showMoreActions,
  actionsType,
  entity,
  setSearchText,
  loading,
  editLink,
  onDelete,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
}) {
  const location = useLocation();
  const [numberOfPages, setNumberOfPages] = useState(Math.ceil(totalCount / rowsPerPage));
  const [showAlert, setShowAlert] = useState(false);
  const [rowToDelete, setRowToDelete] = useState('');
  const [isCompact, setIsCompact] = useState(false); // For compact view when many columns

  // Calculate the number of pages based on total rows and rows per page
  const getNumberOfPages = (rows) => Math.ceil(totalCount / rows);

  useEffect(() => {
    setNumberOfPages(getNumberOfPages(rowsPerPage));
  }, [totalCount, rowsPerPage]);

  useEffect(() => {
    if (currentPage > numberOfPages) {
      setCurrentPage(1);
    }
  }, [numberOfPages]);

  useEffect(() => {
    setIsCompact(columns.length > 5); // Set compact mode based on column count
  }, [columns.length]);

  const handleDeleteRow = async (id) => {
    setRowToDelete(id);
    setShowAlert(true);
  };

  const deleteRow = async () => {
    try {
      onDelete(rowToDelete);
      setShowAlert(false);
    } catch (err) {
      return err;
    }
  };

  const handleChangePage = async (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <>
      <div className={`border border-gray-200 rounded-xl shadow-lg p-4 ${isCompact ? 'compact-table' : ''}`}>
        {rows.length > 0 ? (
          <>
            <SearchFilter
              searchItem={(searchText) => {
                setSearchText(searchText);
              }}
            />
            {loading && <Loading />}
            <TableContainer style={{ overflowX: 'auto' }}>
              <Table
                sx={{
                  minWidth: 800, // Ensures it fits well on most screens
                  position: 'relative',
                  '& td, & th': {
                    padding: isCompact ? '4px 8px' : '8px 12px', // Reduce padding for compact view
                    fontSize: isCompact ? '0.75rem' : '0.85rem', // Smaller font size in compact mode
                  },
                }}
              >
                <TableHeadCustom
                  headCells={columns}
                  entity={entity}
                  showMoreActions={showMoreActions}
                  actionsType={actionsType}
                />
                <TableBody>
                  {rows.map(
                    (row) =>
                      row && (
                        <CustomTableRow
                          key={row.id}
                          row={row}
                          columns={columns}
                          entity={entity}
                          showMoreActions={showMoreActions}
                          actionsType={actionsType}
                          editLink={`${editLink}/${row.id}`}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                        />
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              labelRowsPerPage='Rows per page'
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <p className='text-sage font-bold'>There is no data to show!</p>
        )}
      </div>

      {showAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this?'
          text={`This action cannot be undone.`}
          onCancel={() => setShowAlert(false)}
          onConfirm={() => deleteRow()}
        />
      )}
    </>
  );
}

export default CustomTable;

