import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/loader';
import SelectInput from '../../../components/select-input';
import CustomInput from '../../../components/custom-input';
import DateInput from '../../../components/date-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import {
  titleTypes,
  reservationStatus,
  paymentMethods,
  AgeGroup
} from '../../../constants/entities';
import { getRoomTypes as getRoomTypesApi } from '../../../api/rooms';
import {
  createReservation,
  updateReservation
} from '../../../api/reservations';

function ReservationForm({ isEdit, reservation }) {
  const navigate = useNavigate();
  const [roomTypes, setRoomTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const formikRef = useRef(null);
  const [extraBeds, setExtraBeds] = useState([
    { ageGroup: AgeGroup.INFANT, quantity: 0, pricePerBed: 0 },
    { ageGroup: AgeGroup.CHILD, quantity: 0, pricePerBed: 15 },
    { ageGroup: AgeGroup.ADULT, quantity: 0, pricePerBed: 25 }
  ]);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    getRoomTypes();
  }, []);

  useEffect(() => {
    if (!formikRef.current) return;

    const { values, setFieldValue } = formikRef.current;
    if (!values.fromDate || !values.toDate || !values.roomPrice) return;

    const now = Date.now();
    if (lastUpdate && now - lastUpdate < 100) {
      console.log('Skipping duplicate update');
      return;
    }
    
    setLastUpdate(now);
    console.log('=== Starting price calculation ===');
    console.log('Trigger values:', {
      fromDate: values.fromDate,
      toDate: values.toDate,
      roomPrice: values.roomPrice,
      extraBeds: extraBeds.map(bed => ({
        type: bed.ageGroup,
        quantity: bed.quantity,
        price: bed.pricePerBed
      }))
    });

    calculateTotalPrice(values, setFieldValue);
  }, [
    formikRef.current?.values?.fromDate,
    formikRef.current?.values?.toDate,
    formikRef.current?.values?.roomPrice,
    extraBeds,
    lastUpdate
  ]);

  const getRoomTypes = async () => {
    try {
      const response = await getRoomTypesApi();
      setRoomTypes(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getRooms = (roomType) => {
    const selectedType = roomTypes.find((item) => item.id === roomType);
    if (selectedType && selectedType.rooms) {
      const formattedRooms = selectedType.rooms.map(room => ({
        id: room.id,
        name: `${room.number}`, 
        title: `Room ${room.number} (${room.status})`,
        price: selectedType.price
      }));
      console.log('Formatted Rooms:', formattedRooms);
      setRooms(formattedRooms);
      
      if (formikRef.current) {
        const { values, setFieldValue } = formikRef.current;
        setFieldValue('roomPrice', selectedType.price);
        calculateTotalPrice(values, setFieldValue);
      }
    }
  };

  const calculateExtraBedsPrice = () => {
    let totalExtraBeds = 0;
    console.log('Calculating extra beds prices:');
    
    extraBeds.forEach(bed => {
      const quantity = Number(bed.quantity) || 0;
      const bedTotal = bed.pricePerBed * quantity;
      console.log(`- ${bed.ageGroup}: ${quantity} beds × €${bed.pricePerBed} = €${bedTotal}`);
      totalExtraBeds += bedTotal;
    });
    
    console.log(`Total extra beds per night: €${totalExtraBeds}`);
    return totalExtraBeds;
  };

  const calculateTotalPrice = (values, setFieldValue) => {
    if (!values || !setFieldValue) return;
    console.log('\nCalculating total price:');

    // Calculate number of nights
    const fromDate = values.fromDate ? dayjs(values.fromDate) : null;
    const toDate = values.toDate ? dayjs(values.toDate) : null;
    const numberOfNights = fromDate && toDate ? toDate.diff(fromDate, 'day') : 0;
    console.log(`Number of nights: ${numberOfNights}`);

    // Calculate room price
    const basePrice = Number(values.roomPrice) || 0;
    const totalRoomPrice = basePrice * numberOfNights;
    console.log(`Room price: €${basePrice} × ${numberOfNights} nights = €${totalRoomPrice}`);

    // Calculate discount
    const discount = Number(values.discount) || 0;
    const discountAmount = totalRoomPrice * (discount / 100);
    console.log(`Discount: ${discount}% = €${discountAmount}`);

    // Calculate extra beds
    const extraBedsPerNight = calculateExtraBedsPrice();
    const totalExtraBedsPrice = extraBedsPerNight * numberOfNights;
    console.log(`Extra beds total: €${extraBedsPerNight} × ${numberOfNights} nights = €${totalExtraBedsPrice}`);

    // Add deposit
    const deposit = Number(values.deposit) || 0;
    console.log(`Deposit: €${deposit}`);

    // Calculate final total
    const totalPrice = totalRoomPrice - discountAmount + deposit + totalExtraBedsPrice;
    console.log(`\nFinal total: €${totalPrice}`);
    console.log('=== End price calculation ===\n');

    setFieldValue('totalPrice', totalPrice);
    setFieldValue('paidPrice', totalPrice);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-4 sm:px-7 py-5'>
      <Formik
        initialValues={{
          roomType: isEdit ? reservation?.rooms[0]?.roomTypeId : '',
          roomId: isEdit ? reservation?.rooms[0]?.id : '',
          title: isEdit ? reservation.title : '',
          name: isEdit ? reservation.name : '',
          surname: isEdit ? reservation.surname : '',
          fromDate: isEdit ? dayjs(reservation.fromDate) : null,
          toDate: isEdit ? dayjs(reservation.toDate) : null,
          adults: isEdit ? reservation.adults : 0,
          children: isEdit ? reservation.children : 0,
          status: isEdit ? reservation.status : '',
          personalNumber: isEdit ? reservation.personalNumber : '',
          phoneNumber: isEdit ? reservation.phoneNumber : '',
          discount: isEdit ? reservation.discount : 0,
          roomPrice: isEdit ? reservation.roomPrice : 0,
          deposit: isEdit ? reservation.deposit : 0,
          totalPrice: isEdit ? reservation.totalPrice : 0,
          paymentType: isEdit ? reservation.paymentType : '',
          paidPrice: isEdit ? reservation.paidPrice : 0
        }}
        validationSchema={Yup.object().shape({
          roomType: Yup.string().required('Room Type is required*'),
          roomId: Yup.string().required('Room Number is required*'),
          title: Yup.string().required('Title is required*'),
          name: Yup.string().required('Name is required*'),
          surname: Yup.string().required('Surname is required*'),
          fromDate: Yup.string().required('Checkin date is required*'),
          toDate: Yup.string().required('Checkout date is required*'),
          adults: Yup.number(),
          children: Yup.number(),
          status: Yup.string().required('Status is required*'),
          personalNumber: Yup.string(),
          phoneNumber: Yup.string(),
          discount: Yup.number().nullable(),
          roomPrice: Yup.number(),
          deposit: Yup.number().nullable(),
          totalPrice: Yup.number(),
          paymentType: Yup.string().required('Payment Type is required*'),
          paidPrice: Yup.number().nullable()
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const rooms = [{
              id: values.roomId,
              adults: parseInt(values.adults, 10),
              children: parseInt(values.children, 10),
              extraBeds: extraBeds
                .filter(bed => bed.quantity > 0)
                .map(bed => ({
                  ageGroup: bed.ageGroup,
                  quantity: bed.quantity,
                  pricePerBed: bed.pricePerBed
                }))
            }];

            const fromDate = dayjs(values.fromDate);
            const toDate = dayjs(values.toDate);
            const numberOfNights = toDate.diff(fromDate, 'day');

            const submitData = {
              ...values,
              rooms,
              status: values.status,
              fromDate: fromDate.format(),
              toDate: toDate.format(),
              numberOfNights,
              totalPrice: Number(values.totalPrice)
            };

            console.log('Submitting reservation with data:', submitData);

            if (isEdit) {
              await updateReservation(reservation.id, submitData);
            } else {
              await createReservation(submitData);
              navigate('/rooms');
            }
            setSubmitting(false);
          } catch (err) {
            console.error('Error submitting reservation:', err);
            setErrors({ submit: err?.response?.data });
            setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          formikRef.current = formikProps;
          
          const {
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3'>
                <SelectInput
                  value={values.roomType}
                  label='Room Type'
                  options={roomTypes}
                  onChange={(event) => {
                    setFieldValue('roomType', event.target.value);
                    const selectedRoomType = roomTypes.find(
                      (item) => item.id === event.target.value
                    );
                    getRooms(event.target.value);
                  }}
                  onBlur={handleBlur}
                  showError={errors.roomType && touched.roomType}
                  errorMessage={errors.roomType}
                />
                <SelectInput
                  value={values.roomId}
                  label='Room Number'
                  options={rooms}
                  onChange={handleChange('roomId')}
                  onBlur={handleBlur}
                  showError={errors.roomId && touched.roomId}
                  errorMessage={errors.roomId}
                  isEntity
                />
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Reservation info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 md:grid-cols-9 gap-3 mt-4'>
                <div className='md:col-span-1'>
                  <SelectInput
                    value={values.title}
                    label='Title'
                    options={titleTypes}
                    onChange={handleChange('title')}
                    onBlur={handleBlur}
                    showError={errors.title && touched.title}
                    errorMessage={errors.title}
                    isEntity
                  />
                </div>
                <div className='md:col-span-2'>
                  <CustomInput
                    type='text'
                    name='name'
                    label='Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.name && touched.name}
                    errorMessage={errors.name}
                  />
                </div>
                <div className='md:col-span-2'>
                  <CustomInput
                    type='text'
                    name='surname'
                    label='Surname'
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.surname && touched.surname}
                    errorMessage={errors.surname}
                  />
                </div>
                <div className='md:col-span-2'>
                  <DateInput
                    name='fromDate'
                    label='From Date'
                    value={values.fromDate}
                    onChange={(date) => {
                      setFieldValue('fromDate', date);
                      if (date && values.toDate && values.roomPrice) {
                        calculateTotalPrice({ ...values, fromDate: date }, setFieldValue);
                      }
                    }}
                    onBlur={handleBlur}
                    showError={errors.fromDate && touched.fromDate}
                    errorMessage={errors.fromDate}
                    disablePast
                  />
                </div>
                <div className='md:col-span-2'>
                  <DateInput
                    name='toDate'
                    label='To Date'
                    value={values.toDate}
                    onChange={(date) => {
                      setFieldValue('toDate', date);
                      if (values.fromDate && date && values.roomPrice) {
                        calculateTotalPrice({ ...values, toDate: date }, setFieldValue);
                      }
                    }}
                    onBlur={handleBlur}
                    showError={errors.toDate && touched.toDate}
                    errorMessage={errors.toDate}
                    disablePast
                  />
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-9 gap-3'>
                <div className='md:col-span-1'>
                  <CustomInput
                    type='number'
                    name='adults'
                    label='Adults'
                    value={values.adults}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.adults && touched.adults}
                    errorMessage={errors.adults}
                  />
                </div>
                <div className='md:col-span-1'>
                  <CustomInput
                    type='number'
                    name='children'
                    label='Children'
                    value={values.children}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.children && touched.children}
                    errorMessage={errors.children}
                  />
                </div>
                <div className='col-span-full mt-4'>
                  <div className='bg-gray-50 rounded-lg p-4 border border-gray-200'>
                    <div className='flex items-center mb-3'>
                      <h4 className='text-bluish-grey font-medium text-lg'>Extra Beds</h4>
                      <span className='ml-2 text-sm text-gray-500'>
                        (Additional sleeping arrangements)
                      </span>
                    </div>
                    
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                      {extraBeds.map((bed, index) => (
                        <div 
                          key={bed.ageGroup} 
                          className='bg-white rounded-lg p-4 border border-gray-200 shadow-sm'
                        >
                          <div className='flex justify-between items-start mb-3'>
                            <div>
                              <h5 className='font-medium text-gray-900'>
                                {bed.ageGroup === AgeGroup.INFANT ? 'Infant Bed' : 
                                 bed.ageGroup === AgeGroup.CHILD ? 'Child Bed' : 'Adult Bed'}
                              </h5>
                              <p className='text-sm text-gray-600'>
                                {bed.ageGroup === AgeGroup.INFANT ? '0-5 years' : 
                                 bed.ageGroup === AgeGroup.CHILD ? '5-10 years' : '10+ years'}
                              </p>
                            </div>
                            <div className='text-right'>
                              <span className={`inline-block px-2 py-1 rounded text-sm ${
                                bed.ageGroup === AgeGroup.INFANT 
                                  ? 'bg-green-100 text-green-800'
                                  : bed.ageGroup === AgeGroup.CHILD
                                  ? 'bg-blue-100 text-blue-800'
                                  : 'bg-purple-100 text-purple-800'
                              }`}>
                                {bed.ageGroup === AgeGroup.INFANT ? 'Free' : 
                                 bed.ageGroup === AgeGroup.CHILD ? '€15/bed/night' : '€25/bed/night'}
                              </span>
                            </div>
                          </div>
                          
                          <div className='flex items-center'>
                            <CustomInput
                              type='number'
                              min='0'
                              className='w-full'
                              value={bed.quantity}
                              onChange={(e) => {
                                e.persist();
                                const quantity = Math.max(0, parseInt(e.target.value) || 0);
                                console.log(`\nChanging ${bed.ageGroup} bed quantity to ${quantity}`);
                                
                                // Update beds state with functional update to ensure latest state
                                setExtraBeds(currentBeds => {
                                  const newBeds = currentBeds.map(b => 
                                    b.ageGroup === bed.ageGroup 
                                      ? { ...b, quantity }
                                      : b
                                  );
                                  console.log('New extra beds configuration:', 
                                    newBeds.map(b => `${b.ageGroup}: ${b.quantity} (€${b.pricePerBed} each)`)
                                  );
                                  return newBeds;
                                });
                              }}
                            />
                            <span className='ml-2 text-gray-500'>bed(s)</span>
                          </div>
                          
                          {bed.quantity > 0 && formikRef.current?.values?.fromDate && formikRef.current?.values?.toDate && (
                            <div className='mt-2 text-sm text-gray-500'>
                              {(() => {
                                const fromDate = dayjs(formikRef.current.values.fromDate);
                                const toDate = dayjs(formikRef.current.values.toDate);
                                const nights = toDate.diff(fromDate, 'day');
                                const pricePerNight = bed.pricePerBed * bed.quantity;
                                const totalPrice = pricePerNight * nights;
                                return (
                                  <>
                                    <div>Price per night: €{pricePerNight}</div>
                                    <div>Total for {nights} {nights === 1 ? 'night' : 'nights'}: €{totalPrice}</div>
                                  </>
                                );
                              })()}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    
                    {extraBeds.some(bed => bed.quantity > 0) && formikRef.current?.values?.fromDate && formikRef.current?.values?.toDate && (
                      <div className='mt-4 p-3 bg-white rounded-lg border border-gray-200'>
                        <div className='flex justify-between items-center'>
                          <span className='font-medium text-gray-700'>Extra Beds Summary:</span>
                          <span className='font-medium text-lg text-bluish-grey'>
                            {(() => {
                              const fromDate = dayjs(formikRef.current.values.fromDate);
                              const toDate = dayjs(formikRef.current.values.toDate);
                              const nights = toDate.diff(fromDate, 'day');
                              const perNight = calculateExtraBedsPrice();
                              return `€${perNight} × ${nights} nights = €${perNight * nights}`;
                            })()}
                          </span>
                        </div>
                        <div className='mt-2 text-sm text-gray-500'>
                          Total additional beds: {extraBeds.reduce((total, bed) => total + (bed.quantity || 0), 0)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='md:col-span-3'>
                  <SelectInput
                    value={values.status}
                    label='Reservation Status'
                    options={reservationStatus}
                    onChange={handleChange('status')}
                    onBlur={handleBlur}
                    showError={errors.status && touched.status}
                    errorMessage={errors.status}
                    isEntity
                  />
                </div>
                {values.status === 'CHECKIN' && (
                  <div className='md:col-span-2'>
                    <CustomInput
                      type='text'
                      name='cardNumber'
                      label='Card Number'
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.cardNumber && touched.cardNumber}
                      errorMessage={errors.cardNumber}
                    />
                  </div>
                )}
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Contact info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 md:grid-cols-9 gap-3 mt-4'>
                <div className='md:col-span-3'>
                  <CustomInput
                    type='text'
                    name='personalNumber'
                    label='Personal Number'
                    value={values.personalNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.personalNumber && touched.personalNumber}
                    errorMessage={errors.personalNumber}
                  />
                </div>
                <div className='md:col-span-3'>
                  <CustomInput
                    type='text'
                    name='phoneNumber'
                    label='Phone Number (Optional)'
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phoneNumber && touched.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </div>
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Payment info
              </h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-1 md:grid-cols-6 gap-3 mt-4'>
                <CustomInput
                  type='number'
                  name='discount'
                  label='Discount'
                  value={values.discount}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('discount', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.discount && touched.discount}
                  errorMessage={errors.discount}
                />
                <CustomInput
                  type='number'
                  name='roomPrice'
                  label='Room Price'
                  value={values.roomPrice}
                  disabled
                />
                <CustomInput
                  type='number'
                  name='deposit'
                  label='Deposit'
                  value={values.deposit}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('deposit', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.deposit && touched.deposit}
                  errorMessage={errors.deposit}
                />
                <SelectInput
                  value={values.paymentType}
                  label='Payment Type'
                  options={paymentMethods}
                  onChange={handleChange('paymentType')}
                  onBlur={handleBlur}
                  showError={errors.paymentType && touched.paymentType}
                  errorMessage={errors.paymentType}
                  isEntity
                />
                <CustomInput
                  type='number'
                  name='totalPrice'
                  label='Total Price'
                  value={values.totalPrice}
                  disabled
                />
                <CustomInput
                  type='number'
                  name='paidPrice'
                  label='Paid Price'
                  value={values.paidPrice}
                  onChange={handleChange('paidPrice')}
                  onBlur={handleBlur}
                  showError={errors.paidPrice && touched.paidPrice}
                  errorMessage={errors.paidPrice}
                />
              </div>

              {errors.submit && <p className='text-error'>{errors.submit}</p>}
              <div className='flex flex-col sm:flex-row justify-end items-center w-full mt-7 text-sm gap-3'>
                <CancelButton onCancel={() => navigate('/rooms')} className="w-full sm:w-auto" />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-full sm:w-auto md:w-1/5'
                  title='Save'
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ReservationForm;