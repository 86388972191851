import React, { useState, useEffect } from 'react';

import FilterButton from '../dash-component/DropdownFilter';
import Datepicker from '../dash-component/Datepicker.jsx';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import DashboardCard12 from '../partials/dashboard/DashboardCard12';
import DashboardCard13 from '../partials/dashboard/DashboardCard13';
import Banner from '../partials/Banner';
import Loading from '../components/loader';
import { Card, CardContent, CardHeader, CardTitle } from '../components/shadCN/Card.tsx';
import Tabs from '../components/shadCN/Tabs.jsx';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { ShoppingCart, TrendingUp, CreditCard, PieChart, Waves, Hotel, CalendarDays, DollarSign } from 'lucide-react'
import { Button } from '../components/shadCN/Button.tsx';
import ThemeToggle from '../dash-component/ThemeToggle';

import { getDashboardStats, getSalesStats, getTopProducts } from '../api/dashboard';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('day');
  const tabs = ['day', 'month', 'year'];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    salesData: [],
    dailySales: [],
    totalSales: 0,
    totalExpenses: 0,
    totalRevenue: 0,
    totalPurchases: 0
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const data = await getDashboardStats(activeTab);
        
        // Transform sales by hour data
        const salesByHour = data.totalSales?.salesByHour || [];
        
        // Calculate totals
        const totalSales = data.totalSales?.totalAmount || 0;
        const totalExpenses = data.expenses?.total || 0;
        const totalRevenue = data.revenueByCategory?.reduce((acc, curr) => acc + Number(curr.total || 0), 0) || 0;
        const totalPurchases = data.topProducts?.reduce((acc, curr) => acc + Number(curr.revenue || 0), 0) || 0;

        // Transform daily sales data from recent transactions
        const dailySales = data.recentTransactions?.map(transaction => ({
          name: transaction.type || 'Unknown',
          total: Number(transaction.amount || 0)
        })).filter(sale => sale.total > 0) || [];

        setDashboardData({
          salesData: salesByHour,
          dailySales,
          totalSales,
          totalExpenses,
          totalRevenue,
          totalPurchases
        });

      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [activeTab]);

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="z-2 flex h-screen overflow-hidden bg-slate-100 dark:bg-gray-900">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">Dashboard</h1>
              </div>

              <div className="z-2 grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <FilterButton align="right" />
                <Datepicker align="right" />
                <button className="px-6 py-2 bg-black text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                  Add View
                </button>
                <ThemeToggle />
              </div>
            </div>  

            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              <Tabs 
                tabs={tabs} 
                activeTab={activeTab} 
                setActiveTab={setActiveTab} 
              />

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8 mt-6">
                <StatCard 
                  title="Blerje" 
                  value={`${dashboardData.totalPurchases.toFixed(2)} €`} 
                  icon={<ShoppingCart className="h-6 w-6" />} 
                  color="bg-sage" 
                />
                <StatCard 
                  title="Shitje" 
                  value={`${dashboardData.totalSales.toFixed(2)} €`} 
                  icon={<TrendingUp className="h-6 w-6" />} 
                  color="bg-bluish-grey" 
                />
                <StatCard 
                  title="Shpenzime" 
                  value={`${dashboardData.totalExpenses.toFixed(2)} €`} 
                  icon={<CreditCard className="h-6 w-6" />} 
                  color="bg-salmon-pink" 
                />
                <StatCard 
                  title="Totali" 
                  value={`${dashboardData.totalRevenue.toFixed(2)} €`} 
                  icon={<PieChart className="h-6 w-6" />} 
                  color="bg-hazel-green" 
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                <Card className='shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                  <CardHeader>
                    <CardTitle>Shitjet sipas oreve</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={dashboardData.salesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hour" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="amount" fill="#90a68c" name="Shitjet (€)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>

                <Card className='shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                  <CardHeader>
                    <CardTitle className="text-lg font-semibold text-gray-800">Pazari Ditorë</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-2">
                      {dashboardData.dailySales.map((sale, index) => (
                        <li key={index} className="flex justify-between items-center border-b border-gray-200 py-2">
                          <span className="text-gray-700">{sale.name}</span>
                          <span className="font-semibold text-gray-900">{sale.total.toFixed(2)} €</span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-800">Total:</span>
                        <span className="font-bold text-xl text-gray-900">
                          {dashboardData.totalSales.toFixed(2)} €
                        </span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Additional dashboard cards */}
              <div className="grid grid-cols-12 gap-6">
                <DashboardCard01 />
                <DashboardCard02 />
                <DashboardCard03 />
                <DashboardCard04 />
                <DashboardCard05 />
                <DashboardCard06 />
                <DashboardCard07 />
                <DashboardCard08 />
                <DashboardCard09 />
                <DashboardCard10 />
                <DashboardCard11 />
                <DashboardCard12 />
                <DashboardCard13 />
              </div>
            </main>
          </div>
        </main>
      </div>
    </div>
  );
}

function StatCard({ title, value, icon, color }) {
  return (
    <div className={`${color} rounded-xl shadow-lg p-4 dark:bg-gray-800`}>
      <div className="flex items-center">
        <div className="p-2 rounded-lg">
          {icon}
        </div>
        <div className="ml-4">
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">{title}</h3>
          <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{value}</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;