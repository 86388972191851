import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { createMenu, updateMenu } from '../../api/venueMenus';
import { getDishSections } from '../../api/dishSection';
import { getAllVenues } from '../../api/venue';
import { getBarProducts } from '../../api/barProduct';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { FieldArray } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function VenueMenuForm({ isEdit = false, initialMenu = null }) {
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [barProducts, setBarProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [venuesData, dishSectionsData, barProductsData] = await Promise.all([
          getAllVenues(),
          getDishSections(),
          getBarProducts()
        ]);
        setVenues(venuesData.data || []);
        setDishSections(dishSectionsData.data || []);
        setBarProducts(barProductsData.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load form data');
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedValues = {
        name: values.name,
        price: parseFloat(values.price),
        venueId: values.venueId,
        dishSections: values.dishSections.map(ds => ({ id: ds.id })),
        barProducts: values.barProducts.map(bp => ({ id: bp.id }))
      };

      if (isEdit) {
        await updateMenu(initialMenu.id, formattedValues);
        toast.success('Menu updated successfully!');
      } else {
        await createMenu(formattedValues);
        toast.success('Menu created successfully!');
      }

      navigate('/venue');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form');
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = isEdit && initialMenu
  ? {
      name: initialMenu ? initialMenu.name : '',
      price: initialMenu ? initialMenu.price : '',
      venueId: initialMenu ? initialMenu.venueId : '',
      dishSections: initialMenu ? initialMenu.dishSections : [],
      barProducts: initialMenu ? initialMenu.barProducts || [] : [], // Ensure barProducts is an array
    }
  : {
      name: '',
      price: '',
      venueId: '',
      dishSections: [],
      barProducts: [], // Default to an empty array
    };

  return (
    <div>
      <ToastContainer />
      <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            venueId: Yup.string().required('Required'),
            price: Yup.number().required('Required').min(0, 'Price must be positive')
          })}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            const handleDishChange = (index, value) => {
              const selectedDish = dishSections.find(dish => dish.id === value);
              if (selectedDish) {
                setFieldValue(`dishSections.${index}`, {
                  id: selectedDish.id,
                  name: selectedDish.name,
                  price: selectedDish.price
                });
              }
            };

            const handleFieldChange = (index, field, value) => {
              setFieldValue(`barProducts.${index}.${field}`, value);
            };

            return (
              <Form className='px-7 py-5'>
                <h1 className='text-xl font-semibold mb-5'>Manage Menu</h1>
                <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
                  <CustomInput
                    type='text'
                    name='name'
                    label='Menu Name'
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                  <SelectInput
                    name='venueId'
                    label='Venue'
                    value={values.venueId}
                    options={venues.map(venue => ({
                      id: venue.id,
                      title: venue.name
                    }))}
                    onChange={(e) => setFieldValue('venueId', e.target.value)}
                    showError={false}
                  />
                  <CustomInput
                    type='number'
                    name='price'
                    label='Menu Price'
                    value={values.price}
                    onChange={e => setFieldValue('price', e.target.value)}
                  />
                </div>

                <h2 className='text-lg font-semibold mt-6 mb-2'>Dish Sections</h2>
                <FieldArray
                  name="dishSections"
                  render={arrayHelpers => (
                    <div>
                      {values.dishSections.map((dishSection, index) => (
                        <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
                          <h3 className='font-semibold mb-2'>Dish Section {index + 1}</h3>
                          <SelectInput
                            name={`dishSections.${index}.id`}
                            label='Dish Section'
                            value={dishSection.id}
                            options={dishSections.map(dish => ({
                              id: dish.id,
                              title: dish.name,
                            }))}
                            onChange={(e) => handleDishChange(index, e.target.value)}
                            showError={false}
                          />
                          <button
                            type='button'
                            className='text-red-500 mt-3'
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove Dish Section
                          </button>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='text-blue-500 mt-4'
                        onClick={() => arrayHelpers.push({ id: '' })}
                      >
                        Add Dish Section
                      </button>
                    </div>
                  )}
                />

                <h2 className='text-lg font-semibold mt-6 mb-2'>Bar Products</h2>
                <FieldArray
                  name="barProducts"
                  render={arrayHelpers => (
                    <div>
                      {values.barProducts.map((barProduct, index) => (
                        <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
                          <h3 className='font-semibold mb-2'>Bar Product {index + 1}</h3>
                          <SelectInput
                            name={`barProducts.${index}.id`}
                            label='Bar Product'
                            value={barProduct.id}
                            options={barProducts.map(product => ({
                              id: product.id,
                              title: product.name,
                            }))}
                            onChange={(e) => handleFieldChange(index, 'id', e.target.value)}
                            showError={false}
                          />
                          <CustomInput
                            type='number'
                            name={`barProducts.${index}.quantity`}
                            label='Quantity'
                            value={barProduct.quantity || 1}
                            onChange={(e) => handleFieldChange(index, 'quantity', e.target.value)}
                          />
                          <button
                            type='button'
                            className='text-red-500 mt-3'
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove Bar Product
                          </button>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='text-blue-500 mt-4'
                        onClick={() => arrayHelpers.push({ id: '', quantity: 1 })}
                      >
                        Add Bar Product
                      </button>
                    </div>
                  )}
                />

                <div className='flex justify-end items-center w-full mt-4 text-sm'>
                  <CancelButton onCancel={() => navigate('/venue')} />
                  <SubmitButton width='w-1/2 md:w-1/4' />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default VenueMenuForm;
