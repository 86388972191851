import api from './api';

export const getAllMenus = () => api.get('/api/venueMenu');
export const getMenu = (id) => api.get(`/api/venueMenu/${id}`);

export const createMenu = (values) =>
  api.post('/api/venueMenu/create-menu', {
    name: values.name,
    price: parseFloat(values.price),
    venueId: parseInt(values.venueId, 10),
    dishSections: values.dishSections,
    barProducts: values.barProducts
  });

export const updateMenu = (id, values) =>
  api.put(`/api/venueMenu/${id}`, {
    ...values,
    price: parseFloat(values.price),
    venueId: parseInt(values.venueId, 10)
  });

export const deleteMenu = (id) => api.delete(`/api/venueMenu/${id}`);

export const addDishToMenu = (menuId, values) =>
  api.post(`/api/venueMenu/${menuId}/dishSections`, values);

export const updateDishSection = (venueMenuId, dishSectionId, values) =>
  api.put(`/api/venueMenu/${venueMenuId}/dishSections/${dishSectionId}`, values);

export const addBarProductToMenu = (menuId, values) => 
  api.post(`/api/venueMenu/${menuId}/barProducts`, values);
