import React, { useState, useEffect, useRef } from "react";

export const SlidingBtnTabEnvironments = ({ environments, onTabChange }) => {
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    onTabChange(environments[index].id);
    
    const container = containerRef.current;
    const tab = tabsRef.current[index];
    if (container && tab) {
      const scrollLeft = tab.offsetLeft - (container.clientWidth / 2) + (tab.clientWidth / 2);
      container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  };

  return (
    <div 
      ref={containerRef}
      className="relative mx-auto flex h-7 sm:h-8 rounded-full border border-black/40 bg-neutral-800/90 
        px-0.5 sm:px-1 overflow-x-auto scrollbar-none w-fit max-w-[90vw] sm:max-w-[400px] backdrop-blur-sm"
    >
      <span
        className="absolute bottom-0.5 top-0.5 sm:bottom-1 sm:top-1 z-10 flex overflow-hidden rounded-full transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      >
        <span className="h-full w-full rounded-full bg-gray-200/30" />
      </span>
      
      <div className="flex items-center space-x-0.5 sm:space-x-1">
        {environments.map((env, index) => {
          const isActive = activeTabIndex === index;
          return (
            <button
              key={env.id}
              ref={(el) => (tabsRef.current[index] = el)}
              className={`
                ${isActive ? "text-white" : "text-gray-300 hover:text-white"}
                z-30 whitespace-nowrap cursor-pointer select-none rounded-full 
                px-2 sm:px-2.5 py-0.5
                text-[10px] sm:text-xs font-medium
                transition-all duration-300
                min-w-[60px] sm:min-w-[70px]
              `}
              onClick={() => handleTabClick(index)}
            >
              {env.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};
