import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import VenueForm from './venueForm';
import Loading from '../../components/loader';
import { getVenue } from '../../api/venue';

function EditVenueForm() {
  const { id } = useParams();
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const response = await getVenue(id);
        setVenue(response.data);
      } catch (error) {
        console.error('Failed to fetch venue:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVenue();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Edit Venue'
      />
      <VenueForm isEdit={true} venue={venue} />
    </div>
  );
}

export default EditVenueForm;