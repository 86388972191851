import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../components/buttons';
import CustomTable from '../../components/table-components/table';
import Loading from '../../components/loader';
import { getUsers, deleteUser } from '../../api/users';
import { IconTrash } from '@tabler/icons-react';
import Modal from '../../components/modal';

function UsersList() {
  const [usersList, setUsersList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');

  const getUsersList = useCallback(async () => {
    try {
      const response = await getUsers(rowsPerPage, currentPage + 1, searchText);

      setUsersList(response.data.users);
      setTotalCount(response.data.count);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteUser(selectedUserId);
      setUsersList((prevState) => [
        ...prevState.filter((item) => item.id !== selectedUserId)
      ]);
      setTotalCount(totalCount - 1);
      setShowDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete user:', err);
      setErrorMessage(err?.response?.data?.message || 'Failed to delete user');
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton entity='users' link='/users/add-user' title='New user' />
      <CustomTable
        rows={usersList}
        columns={[
          {
            title: 'Name',
            renderValue: (item) => item.name
          },
          {
            title: 'Surname',
            renderValue: (item) => item.surname
          },
          {
            title: 'Email',
            renderValue: (item) => item.email
          },
          {
            title: 'Phone Number',
            renderValue: (item) => item.phoneNumber
          },
          {
            title: 'Role',
            renderValue: (item) => item.role?.title
          },
          {
            title: 'Actions',
            renderValue: (item) => (
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    setSelectedUserId(item.id);
                    setShowDeleteAlert(true);
                  }}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <IconTrash className="w-5 h-5" />
                </button>
              </div>
            ),
            textAlign: 'center'
          }
        ]}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
      />

      {showDeleteAlert && (
        <Modal
          title="Delete User"
          onClose={() => setShowDeleteAlert(false)}
        >
          <div className="flex flex-col">
            <p className="text-bluish-grey mb-6">
              Are you sure you want to delete this user? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteAlert(false)}
                className="px-4 py-2 text-sage border border-sage rounded-md hover:bg-sage hover:text-white transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default UsersList;
