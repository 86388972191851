import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { IconArrowBackUp } from '@tabler/icons-react';

const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Define the paths where the BackButton should be hidden
    const excludedPaths = [
        // '/statistics'
    ];

    // Check if the current path is '/' or if there's no previous page
    const canGoBack = location.key !== 'default' && location.pathname !== '/';

    // Check if the current path matches any excluded paths
    const shouldHideBackButton = excludedPaths.some(path => location.pathname.startsWith(path));

    // Only show the back button if the current path is not in the excludedPaths
    return (
        canGoBack && !shouldHideBackButton && (
            <button 
                onClick={() => navigate(-1)}
                className="bg-hazel-green absolute mt-2 top-16 left-10 flex items-center justify-center rounded-full w-8 h-8 hover:bg-sage transition-all duration-300 z-[9999]"
            >
                <IconArrowBackUp className='text-white' size={22} />
            </button>
        )
    );
};


export default BackButton;
