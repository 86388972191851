//second version
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IconHistory } from '@tabler/icons-react';
import AddTicket from './AddTicket';
import CustomInput from '../../components/custom-input';
import PermissionCheck from '../../utils/PermissionCheck';
import { SubmitButton } from '../../components/buttons';
import { getPools, updatePool, createPoolReservation } from '../../api/pools';
import useAuth from '../../hooks/useAuth';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { generateINPFile } from '../../api/inpFile';
import { getPort, closePort } from './serialPortManager';
import DateInput from '../../components/date-input';
import TimeInput from './TimeInput.tsx';


function PoolsList() {

  const { userPermissions } = useAuth();
  const [pools, setPools] = useState({});
  const [activePool, setActivePool] = useState('OUTDOOR');
  const [activePoolId, setActivePoolId] = useState(null);
  const printRef = useRef();
  const navigate = useNavigate();



  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          const tickets = {
            man: pool.tickets.find((ticket) => ticket.title === 'man').price,
            woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
            kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
          };

          if (pool.type === 'INDOOR') {
            const standardTicket = pool.tickets.find((ticket) => ticket.title === 'standard');
            tickets.standardPrice = standardTicket ? standardTicket.price : 20;
          }

          acc[pool.type] = {
            id: pool.id,
            ...tickets
          };
          return acc;
        }, {});

        if (!userPermissions.includes('read_indoor_pools')) {
          delete poolsData['INDOOR'];
        }

        if (!userPermissions.includes('read_outdoor_pools')) {
          delete poolsData['OUTDOOR'];
        }
        setPools(poolsData);
        const firstPoolType = Object.keys(poolsData)[0];
        setActivePool(firstPoolType);
        setActivePoolId(poolsData[firstPoolType].id);
      } catch (error) {
        console.error('Failed to fetch pools data', error);
      }
    };
    fetchPools();
    
  }, []);

  const handleTabChange = (tab, setFieldValue) => {
    setActivePool(tab);
    setActivePoolId(pools[tab].id);
    setFieldValue('man', pools[tab].man);
    setFieldValue('woman', pools[tab].woman);
    setFieldValue('kid', pools[tab].kid);
    if (tab === 'INDOOR' && pools[tab].standardPrice !== undefined) {
      setFieldValue('standardPrice', pools[tab].standardPrice);
    }
  };
 

  const handleLinkClick = async (event, url) => {
    event.preventDefault();
    await closePort();
    navigate(url);
  };
 
  //correct

  // const handlePrint = async (item) => {
  //   const saleData = { product: 'Ticket', price: 99 };

  //   console.log('Sending data to generate INP file:', saleData);


  //   try {
  
  //     const response = await generateINPFile(saleData);
  //     console.log('API response:', response.data);
  //     printRef.current.setItem(item);
  //     setTimeout(() => {
  //       printRef.current.triggerPrint();
  //     }, 500); // Give some time for the state to update
  //   } catch (error) {
  //     console.error('Error generating INP file:', error);
  //   }
  // };

  const handlePrint = async (item) => {
    // await generateINPFile(item);
    const saleData = { product: 'Ticket', price: item.totalPrice };

    console.log('Sending data to generate INP file:', saleData);

    // Call the API to generate the INP file
    // await axios.post('http://localhost:3001/generate-sale', saleData);
    await axios.post('https://api-staff.lakesideks.com/generate-sale', saleData);



  // Call the API to generate the INP file
    printRef.current.setItem(item);
    setTimeout(() => {
      printRef.current.triggerPrint();
    }, 500); // Give some time for the state to update
  };

  if (!pools[activePool]) return null;

  const poolPrices = pools[activePool];

  const disableInputs =
    activePool === 'INDOOR'
      ? !userPermissions.includes('edit_indoor_pools')
      : !userPermissions.includes('edit_outdoor_pools');

  return (
    <div className='p-4 sm:p-10'>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
        <Formik
          initialValues={{
            man: poolPrices.man,
            woman: poolPrices.woman,
            kid: poolPrices.kid,
            ...(activePool === 'INDOOR' ? {
              name: '',
              surname: '',
              personalNumber: '',
              phoneNumber: '',
              startTime: '',
              endTime: '',
              ticketPrice: 20,
              standardPrice: poolPrices.standardPrice,
            } : {})
          }}
          validationSchema={
            activePool === 'INDOOR'
              ? Yup.object().shape({
                  name: Yup.string(),
                  surname: Yup.string(),
                  personalNumber: Yup.string(),
                  phoneNumber: Yup.string(),
                  startTime: Yup.string(),
                  endTime: Yup.string(),
                  standardPrice: Yup.number().required('Standard price is required*'),
                })
              : Yup.object().shape({
                  man: Yup.number().required('Price is required*'),
                  woman: Yup.number().required('Price is required*'),
                  kid: Yup.number().required('Price is required*'),
                })
          }
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              let reservationData = {};
              
              if (activePool === 'INDOOR') {
                reservationData = {
                  poolId: activePoolId,
                  ticketType: 'STANDARD',
                  name: values.name,
                  surname: values.surname,
                  personalNumber: values.personalNumber,
                  phoneNumber: values.phoneNumber,
                  startTime: values.startTime,
                  endTime: values.endTime,
                  price: values.standardPrice || poolPrices.standardPrice
                };
              } else {
                reservationData = {
                  poolId: activePoolId,
                  ticketType: values.ticketType,
                  price: values[values.ticketType.toLowerCase()]
                };
              }
  
              console.log('Creating reservation with data:', reservationData);
              const response = await createPoolReservation(reservationData);
              console.log('Reservation response:', response);

              if (response.data.statusCode === 200) {
                toast.success('Ticket created successfully!');
                resetForm();
              } else {
                throw new Error(response.data.message || 'Failed to create ticket');
              }
            } catch (error) {
              console.error('Failed to create ticket:', error);
              toast.error(error.message || 'Failed to create ticket. Please try again.');
            } finally {
              setSubmitting(false);
            }
          }}

          //this is madness
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className='flex flex-col sm:flex-row items-center justify-between w-full border-b border-gray-200'>
                <div className='flex flex-col sm:flex-row items-center'>
                  {Object.keys(pools).map((tab) => (
                    <div key={tab} className='mb-2 sm:mb-0'>
                      <div
                        className={`flex items-center px-5 py-3.5 ${
                          tab === activePool
                            ? 'bg-seashell bg-opacity-30 rounded-md'
                            : ''
                        } cursor-pointer`}
                        onClick={() => handleTabChange(tab, setFieldValue)}
                      >
                        <p className='text-bluish-grey ml-2'>{`${tab} Swimming Pool`}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <PermissionCheck
                  action={
                    activePool === 'INDOOR'
                      ? 'read_indoor_pools'
                      : 'read_outdoor_pools'
                  }
                >
                  <Link to={`/pools/history/${activePoolId}`}>
                    <IconHistory
                      className='my-2 text-bluish-grey cursor-pointer'
                      size={18}
                    />
                  </Link>
                </PermissionCheck>
              </div>





              {activePool === 'INDOOR' ? (
                <div className="mt-6">
                  <h3 className='text-bluish-grey font-medium uppercase mb-4'>
                    Indoor Pool Reservation
                  </h3>
                  
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-6'>
                    <CustomInput
                      type='text'
                      name='name'
                      label='Name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.name && touched.name}
                      errorMessage={errors.name}
                    />
                    <CustomInput
                      type='text'
                      name='surname'
                      label='Surname'
                      value={values.surname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.surname && touched.surname}
                      errorMessage={errors.surname}
                    />
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-6'>
                    <TimeInput
                      name='startTime'
                      label='Start Time'
                      value={values.startTime}
                      onChange={(value) => setFieldValue('startTime', value)}
                      onBlur={handleBlur}
                      showError={errors.startTime && touched.startTime}
                      errorMessage={errors.startTime}
                    />
                    <TimeInput
                      name='endTime'
                      label='End Time'
                      value={values.endTime}
                      onChange={(value) => setFieldValue('endTime', value)}
                      onBlur={handleBlur}
                      showError={errors.endTime && touched.endTime}
                      errorMessage={errors.endTime}
                    />
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-6'>
                    <CustomInput
                      type='text'
                      name='personalNumber'
                      label='Personal Number'
                      value={values.personalNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.personalNumber && touched.personalNumber}
                      errorMessage={errors.personalNumber}
                    />
                    <CustomInput
                      type='text'
                      name='phoneNumber'
                      label='Phone Number'
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.phoneNumber && touched.phoneNumber}
                      errorMessage={errors.phoneNumber}
                    />
                  </div>

                  <div className="mt-6">
                    <h3 className='text-bluish-grey font-medium uppercase mb-4'>
                      Add Ticket
                    </h3>
                    <AddTicket 
                      poolType={activePool} 
                      poolId={activePoolId} 
                      formValues={values}
                    />
                    <form onSubmit={handleSubmit}>
                      <div className="mt-4 flex justify-center">
                        <div className="w-full max-w-xl mx-auto">
                          <CustomInput
                            type='number'
                            name='standardPrice'
                            label='Standard Ticket Price (3 hours)'
                            value={values.standardPrice}
                            onChange={(e) => {
                              handleChange(e);
                              console.log('Standard price changed:', e.target.value);
                            }}
                            onBlur={handleBlur}
                            showError={errors.standardPrice && touched.standardPrice}
                            errorMessage={errors.standardPrice}
                            disabled={disableInputs}
                          />
                          <div className="mt-2 flex justify-end">
                            <button
                              type="button"
                              className="px-4 py-2 bg-sage text-white rounded hover:bg-hazel-green transition-colors"
                              onClick={async () => {
                                try {
                                  const response = await updatePool(activePoolId, {
                                    standardPrice: values.standardPrice
                                  });
                                  console.log("RESPONSE",response)
                                  if (response.status === 201) {
                                    toast.success('🎫 Price updated successfully!', {
                                      position: "top-right",
                                      autoClose: 3000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                    // Update local state
                                    setPools(prev => ({
                                      ...prev,
                                      INDOOR: {
                                        ...prev.INDOOR,
                                        standardPrice: values.standardPrice
                                      }
                                    }));
                                  } else {
                                    toast.error('Failed to update price. Please try again.', {
                                      position: "top-right",
                                      autoClose: 3000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                  }
                                } catch (error) {
                                  console.error('Failed to update price:', error);
                                  toast.error('Failed to update price. Please try again.', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }
                              }}
                              disabled={disableInputs || isSubmitting}
                            >
                              Update Price
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-end items-center w-full mt-6 text-sm mb-4'>
                        {/* <SubmitButton
                          isSubmitting={isSubmitting}
                          width='w-full sm:w-1/5'
                          title='Update Price'
                        /> */}
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  <AddTicket 
                    poolType={activePool} 
                    poolId={activePoolId} 
                    formValues={values}
                  />
                  <form onSubmit={handleSubmit}>
                    <h3 className='text-bluish-grey font-medium uppercase mt-5'>
                      Update Ticket Prices
                    </h3>
                    <div className='border-b my-2' />
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mt-5'>
                      <CustomInput
                        type='number'
                        name='man'
                        label='Man Ticket Price'
                        value={values.man}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        showError={errors.man && touched.man}
                        errorMessage={errors.man}
                        disabled={disableInputs}
                      />
                      <CustomInput
                        type='number'
                        name='woman'
                        label='Woman Ticket Price'
                        value={values.woman}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        showError={errors.woman && touched.woman}
                        errorMessage={errors.woman}
                        disabled={disableInputs}
                      />
                      <CustomInput
                        type='number'
                        name='kid'
                        label='Kid Ticket Price'
                        value={values.kid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        showError={errors.kid && touched.kid}
                        errorMessage={errors.kid}
                        disabled={disableInputs}
                      />
                    </div>
                    {!disableInputs && (
                      <div className='flex justify-end items-center w-full mt-14 text-sm mb-4'>
                        <SubmitButton
                          isSubmitting={isSubmitting}
                          width='w-full sm:w-1/5'
                          title='Save'
                        />
                      </div>
                    )}
                  </form>
                </>
              )}




              {/* <form onSubmit={handleSubmit}>
                <h3 className='text-bluish-grey font-medium uppercase mt-5'>
                  New Swimming Deal
                </h3>
                <div className='border-b my-2' />
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mt-5'>

                  <CustomInput
                    type='number'
                    name='man'
                    label='Man Ticket Price'
                    value={values.man}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.man && touched.man}
                    errorMessage={errors.man}
                    disabled={disableInputs}
                  />
                  <CustomInput
                    type='number'
                    name='woman'
                    label='Woman Ticket Price'
                    value={values.woman}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.woman && touched.woman}
                    errorMessage={errors.woman}
                    disabled={disableInputs}
                  />
                  <CustomInput
                    type='number'
                    name='kid'
                    label='Kid Ticket Price'
                    value={values.kid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.kid && touched.kid}
                    errorMessage={errors.kid}
                    disabled={disableInputs}
                  />
                </div>
                {!disableInputs && (
                  <div className='flex justify-end items-center w-full mt-14 text-sm mb-4'>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      width='w-full sm:w-1/5'
                      title='Save'
                    />
                  </div>
                )}
              </form> */}
            </>
          )}
        </Formik>
      </div>
    
      <TicketPrintComponent ref={printRef} />
    </div>
  );
}

const TicketPrintComponent = React.forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const printContentRef = useRef();
  const printTriggerRef = useRef();

  useImperativeHandle(ref, () => ({
    setItem: (item) => setItem(item),
    triggerPrint: () => {
      if (printTriggerRef.current) {
        printTriggerRef.current.click();
      }
    },
  }));

  if (!item) return null;

  return (
    <>
      <ReactToPrint
        trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
        content={() => printContentRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintContent ref={printContentRef} item={item} />
      </div>
    </>
  );
});

const PrintContent = React.forwardRef(({ item }, ref) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" style={{ maxWidth: '40px' }} />
          <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
        </div>
        <div className="text-gray-700">
          <div className="font-bold text-xl mb-2">INVOICE</div>
          <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
          <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-8 mb-8">
        <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
        <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
        <div className="text-gray-700 mb-2">{user.email}</div>
        <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
        <div className="text-gray-700">{item.customerEmail}</div>
      </div>
      <table className="w-full text-left mb-8">
        <thead>
          <tr>
            <th className="text-gray-700 font-bold uppercase py-2">Description</th>
            <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
            <th className="text-gray-700 font-bold uppercase py-2">Price</th>
            <th className="text-gray-700 font-bold uppercase py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-4 text-gray-700">{item.ticketTitle}</td>
            <td className="py-4 text-gray-700">{item.count}</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mb-8">
        <div className="text-gray-700 mr-2">Total:</div>
        <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
      </div>

      <div className="border-t-2 border-gray-300 pt-8 mb-8">
        <div className='my-6 text-2xl italic text-center'>THANK YOU</div>
      </div>
    </div>
  );
});

export default PoolsList;
