import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '../../components/shadCN/Select.tsx';
import { getSuppliers, createTransaction as createOrder } from '../../api/accounting';
import LowStockTable from '../../components/LowStockTable.js';

export default function EntryOrderForm() {
  const [showOrderForm, setShowOrderForm] = useState(true);
  const [lineItems, setLineItems] = useState([{ id: 1, item: '', unitPrice: 0, quantity: 1, discount: 0 }]);
  const [supplierId, setSupplierId] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [total, setTotal] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await getSuppliers();
        setSuppliers(response.data);
      } catch (error) {
        toast.error('Error fetching suppliers');
        console.error('Error fetching suppliers:', error);
      }
    };
    fetchSuppliers();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    
    if (!supplierId) {
      newErrors.supplier = 'Supplier is required';
    }
    
    if (!invoiceNumber) {
      newErrors.invoiceNumber = 'Invoice number is required';
    }
    
    if (lineItems.length === 0) {
      newErrors.lineItems = 'At least one item is required';
    }

    lineItems.forEach((item, index) => {
      if (!item.item) {
        newErrors[`item-${index}`] = 'Item name is required';
      }
      if (item.unitPrice <= 0) {
        newErrors[`unitPrice-${index}`] = 'Unit price must be greater than 0';
      }
      if (item.quantity <= 0) {
        newErrors[`quantity-${index}`] = 'Quantity must be greater than 0';
      }
      if (item.discount < 0) {
        newErrors[`discount-${index}`] = 'Discount cannot be negative';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addLineItem = () => {
    setLineItems([...lineItems, { id: lineItems.length + 1, item: '', unitPrice: 0, quantity: 1, discount: 0 }]);
  };

  const removeLineItem = (id) => {
    if (lineItems.length > 1) {
      setLineItems(lineItems.filter(item => item.id !== id));
      calculateTotal(lineItems.filter(item => item.id !== id));
    } else {
      toast.warning('At least one item is required');
    }
  };

  const updateLineItem = (index, field, value) => {
    const updatedItems = [...lineItems];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setLineItems(updatedItems);
    calculateTotal(updatedItems);
    
    // Clear field-specific error when user makes a change
    if (errors[`${field}-${index}`]) {
      const newErrors = { ...errors };
      delete newErrors[`${field}-${index}`];
      setErrors(newErrors);
    }
  };

  const calculateTotal = (items) => {
    const totalAmount = items.reduce((sum, item) => {
      const itemTotal = (item.unitPrice * item.quantity);
      const discountAmount = (itemTotal * (item.discount / 100));
      return sum + (itemTotal - discountAmount);
    }, 0);
    setTotal(Number(totalAmount.toFixed(2)));
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast.error('Please fix the errors before submitting');
      return;
    }

    setLoading(true);
    
    try {
      const orderData = {
        supplierId,
        invoiceNumber,
        amount: total,
        paymentType: 'CASH', // You might want to make this selectable
        transactionType: 'PURCHASE',
        TransactionProducts: lineItems.map(item => ({
          item: item.item,
          type: 'PRODUCT',
          unit: 'PCS', // You might want to make this selectable
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          soldPrice: item.unitPrice,
          discount: item.discount
        }))
      };

      const response = await createOrder(orderData);
      
      toast.success('Order created successfully');
      
      // Reset form
      setLineItems([{ id: 1, item: '', unitPrice: 0, quantity: 1, discount: 0 }]);
      setSupplierId('');
      setInvoiceNumber('');
      setTotal(0);
      
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error creating order');
      console.error('Error submitting order:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen py-8 px-4'>
      <div className='bg-white rounded-lg w-full max-w-3xl lg:max-w-4xl shadow-md p-6 md:p-10'>
        <div className='flex flex-col md:flex-row justify-between items-center mb-8'>
          <h1 className='text-3xl md:text-4xl font-bold text-hazel-green'>Stock Management</h1>
          <div className="flex items-center space-x-4 mt-4 md:mt-0">
            <button
              onClick={() => setShowOrderForm(true)}
              className={`px-4 py-2 rounded-lg ${
                showOrderForm ? 'bg-hazel-green text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              Order Form
            </button>
            <button
              onClick={() => setShowOrderForm(false)}
              className={`px-4 py-2 rounded-lg ${
                !showOrderForm ? 'bg-hazel-green text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              Low Stock Items
            </button>
          </div>
        </div>

        {showOrderForm ? (
          <>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-8'>
              <div>
                <label className='block text-sm font-medium text-gray-600 mb-2'>Supplier</label>
                <Select onValueChange={setSupplierId}>
                  <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                    <SelectValue placeholder="Select supplier" />
                  </SelectTrigger>
                  <SelectContent className='bg-white'>
                    {suppliers.length > 0 ? (
                      suppliers.map(supplier => (
                        <SelectItem key={supplier.id} value={supplier.id}>
                          {supplier.businessName}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem disabled>No suppliers available</SelectItem>
                    )}
                  </SelectContent>
                </Select>
                {errors.supplier && <p className="text-red-500 text-sm">{errors.supplier}</p>}
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-600 mb-2'>Invoice No</label>
                <input
                  type='text'
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  className='mt-1 focus:ring-sage focus:border-sage block w-full shadow-sm border border-gray-300 rounded-md py-2 px-3 placeholder-gray-400'
                  placeholder='Enter invoice number'
                />
                {errors.invoiceNumber && <p className="text-red-500 text-sm">{errors.invoiceNumber}</p>}
              </div>
            </div>

            <div className='border border-gray-200 rounded-lg shadow-lg p-4 mb-8 bg-white'>
              <h2 className='text-lg font-semibold mb-4'>Line Items</h2>
              <div className='overflow-x-auto'>
                <table className='min-w-full bg-white shadow-md rounded-lg overflow-hidden'>
                  <thead>
                    <tr className='bg-gray-100'>
                      <th className='px-4 md:px-6 py-3 text-left text-sm font-medium text-gray-700'>Item Name</th>
                      <th className='px-4 md:px-6 py-3 text-left text-sm font-medium text-gray-700'>Unit Price</th>
                      <th className='px-4 md:px-6 py-3 text-left text-sm font-medium text-gray-700'>Quantity</th>
                      <th className='px-4 md:px-6 py-3 text-left text-sm font-medium text-gray-700'>Discount</th>
                      <th className='px-4 md:px-6 py-3 text-left text-sm font-medium text-gray-700'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lineItems.map((item, index) => (
                      <tr key={item.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}>
                        <td className='px-4 md:px-6 py-4'>
                          <input
                            type="text"
                            value={item.item}
                            onChange={(e) => updateLineItem(index, 'item', e.target.value)}
                            className="w-full h-10 text-sm px-3 border border-gray-300 rounded-md focus:ring-sage focus:border-sage"
                            placeholder="Item Name"
                          />
                          {errors[`item-${index}`] && <p className="text-red-500 text-sm">{errors[`item-${index}`]}</p>}
                        </td>
                        <td className='px-4 md:px-6 py-4'>
                          <input
                            type="number"
                            value={item.unitPrice}
                            onChange={(e) => updateLineItem(index, 'unitPrice', Number(e.target.value))}
                            className="w-full h-10 text-sm px-3 border border-gray-300 rounded-md focus:ring-sage focus:border-sage"
                            placeholder="Unit Price"
                          />
                          {errors[`unitPrice-${index}`] && <p className="text-red-500 text-sm">{errors[`unitPrice-${index}`]}</p>}
                        </td>
                        <td className='px-4 md:px-6 py-4'>
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) => updateLineItem(index, 'quantity', Number(e.target.value))}
                            className="w-full h-10 text-sm px-3 border border-gray-300 rounded-md focus:ring-sage focus:border-sage"
                            placeholder="Quantity"
                          />
                          {errors[`quantity-${index}`] && <p className="text-red-500 text-sm">{errors[`quantity-${index}`]}</p>}
                        </td>
                        <td className='px-4 md:px-6 py-4'>
                          <input
                            type="number"
                            value={item.discount}
                            onChange={(e) => updateLineItem(index, 'discount', Number(e.target.value))}
                            className="w-full h-10 text-sm px-3 border border-gray-300 rounded-md focus:ring-sage focus:border-sage"
                            placeholder="Discount"
                          />
                          {errors[`discount-${index}`] && <p className="text-red-500 text-sm">{errors[`discount-${index}`]}</p>}
                        </td>
                        <td className='px-4 md:px-6 py-4'>
                          <button
                            type="button"
                            onClick={() => removeLineItem(item.id)}
                            className="text-red-500 hover:text-red-700 text-sm font-medium"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <button
                type='button'
                onClick={addLineItem}
                className='mt-4 inline-flex items-center rounded-md bg-sage text-white px-4 py-2 shadow-md hover:bg-hazel-green transition'
              >
                Add Item
              </button>
            </div>

            <div className='flex flex-col md:flex-row justify-between mb-4'>
              <h2 className='text-lg font-normal text-hazel-green mx-4'>Total: <strong className="font-bold text-green-gose">${total.toFixed(2)}</strong></h2>
              <button onClick={handleSubmit} className='flex items-center text-white rounded-lg px-4 py-2 bg-hazel-green shadow-md hover:bg-sage hover:shadow-lg transition-all duration-200'>
                Submit Order
              </button>
            </div>
          </> 
        ) : (
          <LowStockTable />
        )}
      </div>
    </div>
  );
}
