import api from './api';

// export const createDishOrder = (orderData) => api.post('/api/orders', orderData);
// 


export const createRoomServiceOrder = async (orderData) => {
    try {
      const response = await api.post('/api/orders/room-service-orders', {
        ...orderData,
        paid: false,  // Explicitly mark as unpaid
        status: 'PENDING'  // Add a status field
      });
      return response.data;
    } catch (error) {
      console.error('Error creating room service order:', error);
      throw error;
    }
  };

//   export const createDishOrder = (orderData) => api.post('/api/orders', orderData);
export const createDishOrder = async (orderData) => {
    try {
      console.log('Creating dish order with data:', orderData);
      const response = await api.post('/api/orders', {
        tableId: orderData.tableId,
        dishOrderProducts: orderData.dishOrderProducts,
        totalPrice: orderData.totalPrice,
        waiterId: orderData.waiterId
      });
      return response.data;
    } catch (error) {
      console.error('Error creating dish order:', error);
      throw error;
    }
  };

export const getDishOrders = () => api.get('/api/orders');

export const getDishOrderById = (id) => api.get(`/api/orders/${id}`);

export const updateDishOrder = (id, orderData) => api.put(`/api/orders/${id}`, orderData);

export const deleteDishOrder = (id) => api.delete(`/api/orders/${id}`);

export const completeDishOrder = (id, userId, amount) => api.post(`/api/orders/${id}/complete`, { userId, amount });

export const getUnpaidRoomServiceOrders = async (roomNumber) => {
  try {
    console.log('Fetching unpaid orders for room:', roomNumber);
    const response = await api.get(`/api/orders/room-service/${roomNumber}/unpaid`);
    console.log('Unpaid orders response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching unpaid room service orders:', error);
    throw error;
  }
};

export const checkTableAssignment = (tableId) => api.get(`/api/orders/table/${tableId}/assignment`);

export const getTableRoomServiceOrders = async (tableId) => {
  try {
    const response = await api.get(`/api/orders/table/${tableId}/room-service`);
    return response.data;
  } catch (error) {
    console.error('Error fetching room service orders for table:', error);
    throw error;
  }
};
