import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import TableMoreMenu from './table-more-menu';
import PermissionCheck from '../../utils/PermissionCheck';

export default function CustomTableRow({
  row,
  columns,
  entity,
  showMoreActions,
  actionsType,
  onDeleteRow
}) {
  const renderColumnValue = (column, item) => {
    if (column.renderValue) {
      const renderedValue = column.renderValue(item);
      // If it's already a React element, return it directly
      if (React.isValidElement(renderedValue)) {
        return renderedValue;
      }
      // Otherwise, convert to string or return a default value
      return String(renderedValue || '');
    }
    // If no renderValue function, just return the value as string
    return String(item[column.key] || '');
  };

  return (
    <TableRow className='w-full' key={row.id} hover>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          className='flex flex-col'
          sx={{ p: 2, textAlign: column.textAlign || 'left' }}
          padding='none'
        >
          {renderColumnValue(column, row)}
        </TableCell>
      ))}
      {showMoreActions && (
        <>
          {actionsType === 'dots' ? (
            <TableCell>
              <TableMoreMenu
                rowId={row.id}
                entity={entity}
                handleDelete={onDeleteRow}
              />
            </TableCell>
          ) : actionsType === 'delete-only' ? (
            <PermissionCheck action={`delete_${entity}`}>
              <TableCell>
                <IconTrash
                  className='text-sage cursor-pointer'
                  size={20}
                  onClick={onDeleteRow}
                />
              </TableCell>
            </PermissionCheck>
          ) : null}
        </>
      )}
    </TableRow>
  );
}

CustomTableRow.propTypes = {
  row: PropTypes.object,
  onDeleteRow: PropTypes.func
};
