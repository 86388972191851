import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight, ClipboardCheck, AlertTriangle } from 'lucide-react';

interface CleaningTasksProps {
  roomNumber: string;
  cleaningReport?: {
    roomChecklist: Array<{
      category: string;
      items: Array<{
        name: string;
        checked: boolean;
        status: string;
      }>;
    }>;
    issuesFound: string[];
    issuesDescription: string;
    cleaningDate: string;
    roomCondition: string;
    cleanlinessLevel: string;
    cleaner: {
      name: string;
      surname: string;
    };
  };
}

export default function CleaningTasks({ roomNumber, cleaningReport }: CleaningTasksProps) {
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  useEffect(() => {
    if (cleaningReport?.roomChecklist) {
      setExpandedCategories(cleaningReport.roomChecklist.map(cat => cat.category));
    }
  }, [cleaningReport]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 6px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 3px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  if (!cleaningReport) {
    return (
      <div className="flex items-center justify-center h-64 bg-seashell rounded-lg">
        <p className="text-bluish-grey">Loading cleaning report data...</p>
      </div>
    );
  }

  const roomChecklist = cleaningReport.roomChecklist || [];

  // Calculate statistics
  const totalItems = roomChecklist.reduce((sum, category) => sum + category.items.length, 0);
  const checkedItems = roomChecklist.reduce(
    (sum, category) => sum + category.items.filter(item => item.checked).length,
    0
  );
  const completionPercentage = totalItems > 0 ? Math.round((checkedItems / totalItems) * 100) : 0;

  return (
    <div className="max-w-7xl mx-auto p-4 bg-tealish-blue min-h-5/6">
      {/* Header Stats */}
      <div className="bg-white rounded-lg p-3 mb-3 shadow-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <ClipboardCheck className="text-hazel-green h-4 w-4" />
            <h2 className="text-base font-semibold text-hazel-green">Room {roomNumber} Inspection</h2>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <p className="text-xs text-bluish-grey">Tasks Completed</p>
              <p className="text-sm font-semibold text-hazel-green">{checkedItems}/{totalItems}</p>
            </div>
            <div className="text-right">
              <p className="text-xs text-bluish-grey">Issues Found</p>
              <p className="text-sm font-semibold text-salmon-pink">{cleaningReport.issuesFound?.length || 0}</p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="w-full bg-grey-goose rounded-full h-1.5">
            <div
              className="bg-hazel-green h-1.5 rounded-full transition-all duration-300"
              style={{ width: `${completionPercentage}%` }}
            />
          </div>
        </div>
      </div>

      {/* Cleaner Info and Room Condition */}
      <div className="bg-mint-cream rounded-lg p-3 mb-3 shadow-sm">
        <div className="grid grid-cols-2 gap-2">
          <div>
            <h4 className="text-xs font-medium text-hazel-green mb-0.5">Cleaned By</h4>
            <p className="text-xs text-bluish-grey">
              {cleaningReport.cleaner?.name} {cleaningReport.cleaner?.surname}
            </p>
          </div>
          <div>
            <h4 className="text-xs font-medium text-hazel-green mb-0.5">Cleaning Date</h4>
            <p className="text-xs text-bluish-grey">
              {new Date(cleaningReport.cleaningDate).toLocaleDateString()}
            </p>
          </div>
          <div>
            <h4 className="text-xs font-medium text-hazel-green mb-0.5">Room Condition</h4>
            <p className="text-xs text-bluish-grey capitalize">{cleaningReport.roomCondition}</p>
          </div>
          <div>
            <h4 className="text-xs font-medium text-hazel-green mb-0.5">Cleanliness Level</h4>
            <p className="text-xs text-bluish-grey capitalize">{cleaningReport.cleanlinessLevel}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {/* Checklist Section */}
        <div className="bg-white rounded-lg p-3 shadow-sm">
          <h3 className="text-sm font-semibold text-bluish-grey mb-2">Cleaning Checklist</h3>
          <div className="space-y-1.5 max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
            {roomChecklist.map((category, index) => {
              const categoryItemsChecked = category.items.filter(item => item.checked).length;
              const categoryProgress = Math.round((categoryItemsChecked / category.items.length) * 100);
              const isExpanded = expandedCategories.includes(category.category);

              return (
                <div key={index} className="border border-grey-goose rounded overflow-hidden">
                  <button
                    onClick={() => {
                      setExpandedCategories(prev =>
                        prev.includes(category.category)
                          ? prev.filter(c => c !== category.category)
                          : [...prev, category.category]
                      );
                    }}
                    className="w-full px-2 py-1.5 flex items-center justify-between bg-seashell hover:bg-mint-cream transition-colors"
                  >
                    <div className="flex items-center gap-1.5">
                      {isExpanded ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                      <span className="font-medium text-xs">{category.category}</span>
                      <span className="text-[10px] text-bluish-grey">
                        ({categoryItemsChecked}/{category.items.length})
                      </span>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <div className="w-12 h-1 bg-grey-goose rounded-full">
                        <div
                          className="h-1 bg-hazel-green rounded-full transition-all duration-300"
                          style={{ width: `${categoryProgress}%` }}
                        />
                      </div>
                      <span className="text-[10px] text-bluish-grey">{categoryProgress}%</span>
                    </div>
                  </button>

                  {isExpanded && (
                    <div className="py-1.5 px-2 space-y-1">
                      {category.items.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="flex items-center justify-between py-0.5 px-1.5 bg-seashell rounded"
                        >
                          <div className="flex items-center gap-1.5">
                            <input
                              type="checkbox"
                              checked={item.checked}
                              readOnly
                              className="h-3 w-3 text-hazel-green border-grey-goose rounded"
                            />
                            <span className="text-xs">{item.name}</span>
                          </div>
                          <span
                            className={`text-[10px] px-1.5 py-0.5 rounded-full ${
                              item.status === 'Good'
                                ? 'bg-mint-cream text-hazel-green'
                                : 'bg-dawn-pink text-salmon-pink'
                            }`}
                          >
                            {item.status}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {/* Issues Section */}
        <div className="bg-white rounded-lg p-3 shadow-sm">
          <h3 className="text-sm font-semibold text-bluish-grey mb-2 flex items-center gap-1.5">
            <AlertTriangle className="h-4 w-4 text-salmon-pink" />
            Issues Found
          </h3>

          <div className="space-y-3">
            <div className="mb-2">
              {cleaningReport.issuesFound?.map((issue, index) => (
                <span
                  key={index}
                  className="inline-block px-2 py-0.5 bg-dawn-pink text-salmon-pink rounded-full text-[10px] mr-1.5 mb-1.5"
                >
                  {issue}
                </span>
              ))}
            </div>

            <div>
              <h4 className="text-xs font-medium text-bluish-grey mb-1">Description</h4>
              <div className="bg-seashell p-2 rounded-lg">
                <p className="text-xs text-bluish-grey">
                  {cleaningReport.issuesDescription || 'No description provided'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}