import React, { useState, useEffect, useCallback } from 'react';
import Table from "./table/Table";
import { deleteTable, mergeTables, unmergeTable, getTablesByEnvironment } from '../../api/restaurant';
import { getAssignedTables } from '../../api/waiter';

const TableGrid = ({ environment, waiterId }) => {
    const [assignedTables, setAssignedTables] = useState([]);
    const [tablesDisplay, setTablesDisplay] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch tables for the current environment
    const fetchTables = useCallback(async () => {
        if (!environment?.id) return;
        try {
            setLoading(true);
            const response = await getTablesByEnvironment(environment.id);
            // Remove the isAvailable filter and just ensure uniqueness
            const uniqueTables = Array.from(new Map(
                response.data.map(table => [table.id, table])
            ).values());
            setTablesDisplay(uniqueTables);
        } catch (error) {
            console.error('Failed to fetch tables:', error);
        } finally {
            setLoading(false);
        }
    }, [environment?.id]);

    // Fetch tables when environment changes
    useEffect(() => {
        fetchTables();
    }, [fetchTables, environment?.id]);

    // Fetch assigned tables for waiter
    useEffect(() => {
        if (waiterId) {
            getAssignedTables(waiterId)
                .then(response => {
                    const assignedTableIds = response.data.map(table => table.table.id);
                    setAssignedTables(assignedTableIds);
                })
                .catch(error => console.error('Failed to fetch assigned tables:', error));
        }
    }, [waiterId]);

    const handleDelete = async (id) => {
        try {
            await deleteTable(id);
            // Refresh tables after deletion
            fetchTables();
        } catch (error) {
            console.error('Failed to delete table:', error);
        }
    };

    const handleMerge = async (table, data) => {
        const droppedTable = tablesDisplay.find((t) => t.id === table.id);
        const overlappedTable = tablesDisplay.find((t) => t.id !== table.id && isOverlap(data, t));

        if (droppedTable && overlappedTable) {
            try {
                await mergeTables(droppedTable.id, overlappedTable.id);
                // Refresh tables after merge
                fetchTables();
            } catch (error) {
                console.error("Failed to merge tables:", error);
            }
        }
    };

    const handleUnmerge = async (mergedTableId) => {
        try {
            await unmergeTable(mergedTableId);
            // Refresh tables after unmerge
            fetchTables();
        } catch (error) {
            console.error("Error in unmerging table:", error);
        }
    };

    const isOverlap = (data, table) => {
        const draggedRect = data.node.getBoundingClientRect();
        const tableElement = document.querySelector(`[data-table-id='${table.id}']`);

        if (!tableElement) {
            console.warn(`Element with data-table-id ${table.id} not found`);
            return false;
        }

        const tableRect = tableElement.getBoundingClientRect();

        return !(
            draggedRect.right < tableRect.left ||
            draggedRect.left > tableRect.right ||
            draggedRect.bottom < tableRect.top ||
            draggedRect.top > tableRect.bottom
        );
    };

    return (
        <div className="p-4">
            <div className="grid grid-cols-4 gap-8">
                {tablesDisplay.map((table) => (
                    <Table 
                        key={`${table.id}-${table.isAvailable}`} 
                        table={table} 
                        onDelete={handleDelete} 
                        onMerge={handleMerge} 
                        onUnmerge={handleUnmerge} 
                        isAssigned={assignedTables.includes(table.id)} 
                    />
                ))}
            </div>
        </div>
    );
};

export default TableGrid;