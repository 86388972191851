import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './table.css';
import Draggable from "react-draggable";
import { IconRepeat, IconTrash, IconLink, IconUnlink, IconInfoCircle } from '@tabler/icons-react';
import { deleteTable } from '../../../api/restaurant';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Table = ({ table, onDelete, onMerge, onUnmerge, isAssigned }) => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); 
  const [showPopup, setShowPopup] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const tableRef = useRef(null);
  const dragStartPosRef = useRef({ x: 0, y: 0 });
  const isMerged = table.mergedFromIds && JSON.parse(table.mergedFromIds).length > 1;

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 768;
      setIsSmallScreen(smallScreen);
      // Always hide popup on larger screens
      if (!smallScreen) {
        setShowPopup(false);
      }
    };

    // Initial check
    handleResize();
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePopupToggle = (e) => {
    e.stopPropagation();
    if (isSmallScreen) {
      setShowPopup(!showPopup);
    }
  };

  const getPopupPosition = () => {
    if (!isSmallScreen) return null;
    if (!tableRef.current) return { top: 0, left: 0 };
    const rect = tableRef.current.getBoundingClientRect();
    return {
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX + 8,
    };
  };

  const handleTableClick = (e) => {
    const tableWrapper = e.target.closest('#table-wrapper');
    const managementSection = e.target.closest('.table-management');
    const infoButton = e.target.closest('.info-button');
    
    if (infoButton) {
      handlePopupToggle(e);
      return;
    }

    if (tableWrapper && !managementSection) {
      navigate(`/orders/new-order/${table.id}`);
    }
  };

  const renderChairs = () => {
    const chairs = [];
    const capacity = table.capacity || 6;
    const sideCapacity = Math.floor(capacity / 2);

    for (let i = 0; i < capacity; i++) {
      const position = i < sideCapacity ? 'left' : 'right';
      const size = i === 0 || i === capacity - 1 ? 'chair-lg' : 'chair';
      chairs.push(
        <div
          key={i}
          className={`chair ${size} ${position} ${table.isAvailable ? 'available' : 'occupied opacity-70'}`}
        ></div>
      );
    }
    return chairs;
  };

  const renderPlates = () => {
    return [...Array(table.capacity)].map((_, i) => (
      <div key={i} className="plate">
        <div className="knife"></div>
        <div className="fork"></div>
      </div>
    ));
  };

  const onStart = (e, data) => {
    dragStartPosRef.current = { x: data.x, y: data.y };
    setIsDragging(false);
  };

  const onDrag = (e, data) => {
    const dx = data.x - dragStartPosRef.current.x;
    const dy = data.y - dragStartPosRef.current.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance > 5) {
      setIsDragging(true);
    }
  };

  const onStop = (e, data) => {
    if (isDragging) {
      setTimeout(() => onMerge(table, data), 50);
    } else {
      handleTableClick(e);
    }
    setIsDragging(false);
  };

  const renderPopup = () => {
    if (!isSmallScreen || !showPopup) return null;

    return ReactDOM.createPortal(
      <div
        className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setShowPopup(false);
          }
        }}
      >
        <div
          className="bg-white rounded-lg shadow-xl border max-w-[90vw] w-[400px] m-4 animate-fadeIn"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-4">
            <div className="space-y-3">
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <p className="font-medium text-lg text-gray-800">Table {table.tableNumber || table.id}</p>
                  {/* Close button */}
                  <button
                    className="p-1 rounded-full hover:bg-gray-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPopup(false);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                
                <div className="space-y-1.5 text-sm">
                  {/* Seats/Capacity */}
                  <p className="text-gray-600">
                    Seats: {table.seats || table.capacity || 'N/A'}
                  </p>
                  
                  {/* Table Status */}
                  <p className={`font-medium ${
                    table.isStaffTable ? 'text-purple-600' :
                    isMerged ? 'text-blue-600' :
                    table.isAvailable ? 'text-green-600' : 'text-red-600'
                  }`}>
                    Status: {
                      table.isStaffTable ? 'Staff Table' :
                      isMerged ? 'Merged Table' :
                      table.isAvailable ? 'Available' : 'Occupied'
                    }
                  </p>

                  {/* Merged Tables Info */}
                  {isMerged && table.mergedFromIds && (
                    <p className="text-gray-600">
                      Merged Tables: {JSON.parse(table.mergedFromIds).join(', ')}
                    </p>
                  )}
                </div>
              </div>

              {/* Actions Section */}
              <div className="flex items-center space-x-4 pt-2 border-t">
                <Link 
                  to="/restaurant/table-reservation"
                  className="p-1.5 rounded-full hover:bg-blue-50"
                >
                  <IconRepeat
                    size={18}
                    className="text-gray-500 hover:text-blue-500 transition-colors duration-150"
                    onClick={(e) => e.stopPropagation()}
                  />
                </Link>
                <button
                  className={`p-1.5 rounded-full hover:bg-red-50 ${
                    isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  <IconTrash
                    size={18}
                    className={`${
                      isDeleting ? 'text-gray-400' : 'text-gray-500 hover:text-red-500'
                    } transition-colors duration-150`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (isDeleting) return;
    setIsDeleting(true);
    onDelete(table.id);
    setIsDeleting(false);
  };

  return (
    <Draggable onStart={onStart} onDrag={onDrag} onStop={onStop}>
      <div 
        ref={tableRef}
        className={`table-container relative ${isDragging ? 'dragging' : ''} ${isMerged ? 'merged-table' : ''}`} 
        style={{ padding: '0.5rem' }}
      >
        {/* Flex container for dynamic positioning */}
        <div className="flex flex-col items-center scale-90">
          {/* Responsive Badge container positioned at the top */}
          {isMerged && (
            <div className="mb-2 flex items-center gap-2 p-1 bg-blue-100 text-blue-800 text-xs sm:text-sm rounded-full shadow-md">
              <IconLink size={16} className="text-blue-500" />
              <span>Merged</span>
              <button 
                className="bg-red-200 text-red-600 rounded-full px-1 sm:px-2 py-0.5 text-xs ml-2 flex items-center gap-1" 
                onClick={(e) => {
                  e.stopPropagation();
                  onUnmerge(table.id);
                }}
              >
                <IconUnlink size={14} /> Unmerge
              </button>
            </div>
          )}
          
          <div
            id="table-wrapper"
            data-table-id={table.id}
            onClick={handleTableClick}
            className={`scale-90 transform ${table.isStaffTable ? 'staff-table' : ''} 
            ${isAssigned ? 'opacity-100' : 'opacity-70'} relative`}
          >
            {/* Info Button - Only visible on small screens */}
            {isSmallScreen && (
              <button
                className="info-button absolute -top-2 -right-2 w-8 h-8 bg-white rounded-full shadow-md border border-gray-200 flex items-center justify-center z-10"
                onClick={handlePopupToggle}
              >
                <IconInfoCircle 
                  size={20} 
                  className={`transition-colors duration-200 ${
                    showPopup ? 'text-blue-500' : 'text-gray-500'
                  }`}
                />
              </button>
            )}

            {renderChairs()}
            <div className="the-table">
              {renderPlates()}
            </div>
          </div>
        </div>
        
        {renderPopup()}

        {/* Desktop Management Section - Only visible on larger screens */}
        <div className="hidden sm:block">
          <div
            className={`table-management p-3 border rounded-lg mt-1 shadow-sm transition-transform duration-200 ${
              table.isAvailable ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200'
            } flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-x-3 sm:w-full`}
          >
            {/* Table Information */}
            <div className="sm:w-1/2 sm:flex sm:flex-col sm:items-start sm:space-y-1">
              <p className="font-medium text-gray-800 sm:text-sm">Table {table.number}</p>
              <p className={`text-xs font-semibold ${
                table.isAvailable ? 'text-green-600' : 'text-red-600'
              } sm:text-sm`}>
                {isMerged
                  ? `Merged Table (${table.capacity})`
                  : table.isStaffTable
                  ? 'Staff Table'
                  : table.isAvailable
                  ? 'Available'
                  : 'Occupied'}
              </p>
            </div>
            
            {/* Action Icons */}
            <div className="sm:w-1/2 sm:flex sm:items-center sm:justify-end sm:space-x-2">
              <Link to="/restaurant/table-reservation">
                <IconRepeat
                  size={18}
                  className="text-gray-500 hover:text-blue-500 transition-colors duration-150"
                  onClick={(e) => e.stopPropagation()}
                />
              </Link>
              <IconTrash
                size={18}
                className={`cursor-pointer ${
                  isDeleting ? 'text-gray-400' : 'text-gray-500 hover:text-red-500'
                } transition-colors duration-150`}
                onClick={handleDelete}
                disabled={isDeleting}
                style={{ opacity: isDeleting ? 0.5 : 1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default Table;