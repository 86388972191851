import React, { useState, useEffect } from 'react';
import { getAllAdminUsers } from '../../api/dashboard';
import DefaultAvatar from '../../images/user-36-05.jpg';

function DashboardCard10() {
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        setLoading(true);
        const response = await getAllAdminUsers();
        // The API returns { statusCode: 200, message: adminUsers }
        const users = Array.isArray(response) ? response : [];
        
        // Transform the data to match our table structure
        const transformedUsers = users.map(user => ({
          id: user.id,
          image: user.image || DefaultAvatar,
          name: `${user.name} ${user.surname || ''}`.trim(),
          email: user.email,
          jobRole: user.jobRole || 'N/A',
          phoneNumber: user.phoneNumber || 'N/A'
        }));
        setAdminUsers(transformedUsers);
      } catch (error) {
        console.error("Failed to fetch admin users", error);
        setError('Failed to load admin users');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminUsers();
  }, []);

  if (loading) {
    return (
      <div className="col-span-full xl:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        <div className="flex items-center justify-center h-40">
          <div className="text-gray-500 dark:text-gray-400">Loading users...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="col-span-full xl:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        <div className="flex items-center justify-center h-40">
          <div className="text-red-500">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-full xl:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
      <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
        <h2 className="font-semibold text-gray-800 dark:text-gray-100">Staff Members</h2>
      </header>      
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Role</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Phone</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100 dark:divide-gray-700/60">
              {adminUsers.length === 0 ? (
                <tr>
                  <td colSpan="4" className="p-4 text-center text-gray-500 dark:text-gray-400">
                    No staff members found
                  </td>
                </tr>
              ) : (
                adminUsers.map(user => (
                  <tr key={user.id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                          <img 
                            className="rounded-full" 
                            src={user.image} 
                            width="40" 
                            height="40" 
                            alt={user.name}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = DefaultAvatar;
                            }}
                          />
                        </div>
                        <div className="font-medium text-gray-800 dark:text-gray-100">{user.name}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{user.email}</div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left font-medium text-green-500">{user.jobRole}</div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-sm text-center">{user.phoneNumber}</div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard10;
