import React from 'react';
import { IconX } from '@tabler/icons-react';

const RoomServiceOrdersSidebar = ({ show, onClose, orders = [] }) => {
    // Ensure orders is always an array
    const safeOrders = Array.isArray(orders) ? orders : [];

    return (
        <div className={`fixed top-0 right-0 w-96 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
            <div className="flex justify-between items-center p-4 border-b bg-gray-50">
                <h2 className="text-lg font-bold text-gray-800">Room Service Orders</h2>
                <button onClick={onClose} className="text-gray-600 hover:text-gray-800 p-1 rounded-full hover:bg-gray-200 transition-colors">
                    <IconX size={20} />
                </button>
            </div>
            
            <div className="overflow-y-auto h-[calc(100%-64px)] p-4">
                {safeOrders.length > 0 ? (
                    safeOrders.map((order) => {
                        // Separate items by type
                        const barItems = (order.items || []).filter(item => item.type === 'barItem');
                        const dishItems = (order.items || []).filter(item => item.type === 'dishItem');

                        return (
                            <div key={order.id} className="mb-4 p-4 bg-white rounded-lg shadow border border-gray-100">
                                {/* Header with Room and Table Info */}
                                <div className="flex justify-between items-start mb-3">
                                    <div className="flex flex-col">
                                        <div className="flex items-center gap-2">
                                            <span className="text-sm font-semibold text-gray-800">
                                                Room {order.roomNumber || 'N/A'}
                                            </span>
                                            <span className="text-sm text-gray-600">
                                                • Table {order.tableId || 'N/A'}
                                            </span>
                                        </div>
                                        <span className={`mt-1 px-2 py-1 text-xs rounded-full w-fit ${
                                            order.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' :
                                            order.status === 'IN_PROGRESS' ? 'bg-blue-100 text-blue-800' :
                                            order.status === 'COMPLETED' ? 'bg-green-100 text-green-800' :
                                            'bg-gray-100 text-gray-800'
                                        }`}>
                                            {order.status}
                                        </span>
                                    </div>
                                    <span className="text-sm font-medium text-gray-600">
                                        ${(order.totalPrice || 0).toFixed(2)}
                                    </span>
                                </div>
                                
                                {/* Bar Items Section */}
                                {barItems.length > 0 && (
                                    <div className="mb-3">
                                        <h3 className="text-sm font-semibold text-gray-700 mb-2">Bar Items</h3>
                                        <div className="space-y-2 pl-2">
                                            {barItems.map((item) => (
                                                <div key={item.id} className="flex justify-between items-center text-sm">
                                                    <div className="flex-1">
                                                        <span className="text-gray-700">{item.name}</span>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-gray-600">x{item.quantity}</span>
                                                        <span className="text-gray-600">${(item.price || 0).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {/* Dish Items Section */}
                                {dishItems.length > 0 && (
                                    <div className="mb-3">
                                        <h3 className="text-sm font-semibold text-gray-700 mb-2">Kitchen Items</h3>
                                        <div className="space-y-2 pl-2">
                                            {dishItems.map((item) => (
                                                <div key={item.id} className="flex justify-between items-center text-sm">
                                                    <div className="flex-1">
                                                        <span className="text-gray-700">{item.name}</span>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-gray-600">x{item.quantity}</span>
                                                        <span className="text-gray-600">${(item.price || 0).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                
                                {/* Footer with timestamp */}
                                <div className="mt-3 pt-3 border-t border-gray-100">
                                    <div className="text-xs text-gray-500">
                                        Ordered at: {order.createdAt ? new Date(order.createdAt).toLocaleString() : 'N/A'}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="flex flex-col items-center justify-center h-full text-gray-500">
                        <p>No room service orders</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RoomServiceOrdersSidebar;