import api from "./api"

export const getMenu = () => api.get('/api/menus');
export const createOrUpdateMenu = (data) => api.post('/api/menus', data);
export const addDishToMenu = (data) => api.post('/api/menus/dishes', data);
export const updateDish = (dishId, data) => api.put(`/api/menus/dishes/${dishId}`, data);

export const getDishSectionsWithMenu = async (menuId) => {
    try {
        if (!menuId) {
            console.log('DEBUG: Fetching default dish sections');
            const response = await api.get('/api/menus/dishSections');
            console.log('DEBUG: Default dish sections response:', response.data);
            return response;
        }
        console.log('DEBUG: Fetching dish sections for menu:', menuId);
        const response = await api.get(`/api/menus/${menuId}/dishSections`);
        console.log('DEBUG: Dish sections response:', response.data);
        return response;
    } catch (error) {
        console.error('DEBUG: API Error:', error.response?.data || error.message);
        throw error;
    }
};