import React, { useEffect, useState } from "react";
import { getPaginatedPoolReservations } from "../../api/poolsInterface";
import { Clock, Search, Filter, Calendar, User, CreditCard } from 'lucide-react';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';
import IndoorPoolDetailsModal from '../../components/modals/IndoorPoolDetailsModal';
import TicketManagementModal from '../../components/modals/TicketManagementModal';
import PoolRestaurantOrderModal from '../../components/modals/PoolRestaurantOrderModal';
import { useTicket } from '../../context/TicketContext';
import dayjs from 'dayjs';

function HotelPoolHistory() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isRestaurantOrderModalOpen, setIsRestaurantOrderModalOpen] = useState(false);
  const { updateTicketInfo } = useTicket();
  const limit = 10;

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const response = await getPaginatedPoolReservations(page, limit);
      const data = response.data ? response.data : [];
      console.log('Component - Fetched Paginated Reservations:', data);
      setReservations(data.reservations);
      setTotalPages(Math.ceil(data.totalCount / limit));
      setTotalEntries(data.totalCount);
    } catch (error) {
      setError('Failed to fetch reservations.');
      console.error('Component - Failed to fetch reservations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();
  }, [page]);

  const handleFirstPage = () => setPage(1);
  const handlePreviousPage = () => setPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setPage((prev) => Math.min(prev + 1, totalPages));
  const handleLastPage = () => setPage(totalPages);

  const startEntry = (page - 1) * limit + 1;
  const endEntry = Math.min(page * limit, totalEntries);

  const filteredReservations = reservations
    .filter(reservation => {
      if (filterStatus === "all") return true;
      return reservation.status === filterStatus;
    })
    .filter(reservation => {
      const searchStr = searchTerm.toLowerCase();
      return (
        reservation.id.toLowerCase().includes(searchStr) ||
        reservation.ticketType.toLowerCase().includes(searchStr) ||
        (reservation.name && reservation.name.toLowerCase().includes(searchStr)) ||
        (reservation.surname && reservation.surname.toLowerCase().includes(searchStr))
      );
    });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleViewReservation = (reservation) => {
    setSelectedReservation(reservation);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedReservation(null);
  };

  const handleTicketClick = async (reservation) => {
    console.log('Ticket clicked:', reservation);
    setSelectedReservation(reservation);
    updateTicketInfo(reservation);
    setIsTicketModalOpen(true);
  };

  const handleTicketModalClose = () => {
    setIsTicketModalOpen(false);
    fetchReservations(); // Refresh the data after ticket management
  };

  const handleRestaurantOrderClick = (reservation) => {
    setSelectedReservation(reservation);
    setIsRestaurantOrderModalOpen(true);
  };

  const handleRestaurantOrderClose = () => {
    setIsRestaurantOrderModalOpen(false);
    fetchReservations(); // Refresh the data after order is complete
  };

  const handleOrderComplete = async () => {
    await fetchReservations(); // Refresh the data
    handleRestaurantOrderClose();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-tealish-blue to-seashell">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-hazel-green mb-2">Indoor Pool Reservations History</h1>
          <p className="text-bluish-grey">Track and manage all pool reservations</p>
        </div>

        <div className="mb-6 flex flex-col sm:flex-row gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search reservations..."
              className="w-full pl-10 pr-4 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="relative">
            <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <select
              className="pl-10 pr-4 py-2 rounded-xl border-2 border-mercury focus:border-hazel-green focus:ring-0 transition-colors bg-white/50"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Status</option>
              <option value="PENDING_CHECKIN">Pending Check-in</option>
              <option value="VERIFIED">Verified</option>
              <option value="CHECKED_IN">Checked In</option>
              <option value="CHECKED_OUT">Checked Out</option>
            </select>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gradient-to-r from-hazel-green/10 to-sage/10">
                <tr>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4" />
                      Guest Info
                    </div>
                  </th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4" />
                      Time Slot
                    </div>
                  </th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">
                    <div className="flex items-center gap-2">
                      <CreditCard className="h-4 w-4" />
                      Payment
                    </div>
                  </th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Status</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-bluish-grey">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {filteredReservations.map((reservation) => (
                  <tr key={reservation.id} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4">
                      <div className="flex flex-col">
                        <span className="font-medium text-gray-900">
                          {reservation.name} {reservation.surname}
                        </span>
                        <span className="text-sm text-gray-500">{reservation.phoneNumber}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-900">
                          {reservation.startTime && formatDate(reservation.startTime)}
                        </span>
                        <span className="text-sm text-gray-500">
                          to {reservation.endTime && formatDate(reservation.endTime)}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col">
                        <span className="font-medium text-gray-900">€{reservation.price}</span>
                        <span className="text-sm text-gray-500">{reservation.ticketType}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${reservation.status === 'PENDING_CHECKIN' && 'bg-yellow-100 text-yellow-800'}
                        ${reservation.status === 'VERIFIED' && 'bg-blue-100 text-blue-800'}
                        ${reservation.status === 'CHECKED_IN' && 'bg-green-100 text-green-800'}
                        ${reservation.status === 'CHECKED_OUT' && 'bg-gray-100 text-gray-800'}
                      `}>
                        {reservation.status.replace('_', ' ')}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center space-x-3">
                        <button 
                          onClick={() => handleViewReservation(reservation)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          View
                        </button>
                        <button 
                          onClick={() => handleTicketClick(reservation)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Ticket
                        </button>
                        {/* <button 
                          onClick={() => handleRestaurantOrderClick(reservation)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Order
                        </button> */}
                        <button className="text-red-600 hover:text-red-900">Cancel</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-gray-50 px-6 py-4 flex items-center justify-between">
            <div className="text-sm text-gray-500">
              Showing {startEntry} to {endEntry} of {totalEntries} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleFirstPage}
                disabled={page === 1}
                className="px-3 py-1 rounded-lg bg-white border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
              >
                First
              </button>
              <button
                onClick={handlePreviousPage}
                disabled={page === 1}
                className="px-3 py-1 rounded-lg bg-white border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <span className="px-3 py-1 text-sm text-gray-700">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={page === totalPages}
                className="px-3 py-1 rounded-lg bg-white border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
              <button
                onClick={handleLastPage}
                disabled={page === totalPages}
                className="px-3 py-1 rounded-lg bg-white border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
              >
                Last
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Indoor Pool Details Modal */}
      <IndoorPoolDetailsModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        reservations={selectedReservation ? [selectedReservation] : []}
        date={selectedReservation ? dayjs(selectedReservation.startTime).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
        onTicketClick={handleTicketClick}
        onUpdate={fetchReservations}
      />

      {/* Ticket Management Modal */}
      <TicketManagementModal
        isOpen={isTicketModalOpen}
        onClose={handleTicketModalClose}
        reservation={selectedReservation}
        onUpdate={fetchReservations}
      />

      {/* Restaurant Order Modal */}
      {isRestaurantOrderModalOpen && selectedReservation && (
        <PoolRestaurantOrderModal
          isOpen={isRestaurantOrderModalOpen}
          onClose={handleRestaurantOrderClose}
          poolTicketReference={selectedReservation.ticketReference}
          customerName={`${selectedReservation.name} ${selectedReservation.surname}`}
          onOrderComplete={handleOrderComplete}
        />
      )}
    </div>
  );
}

export default HotelPoolHistory;
