import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useSelector } from 'react-redux';

import BookingDetails from './tabs/BookingDetails.tsx';
import CreateCase from './tabs/CreateCase.tsx';
import AddPayment from './tabs/AddPayment.tsx';
import CreateTask from './tabs/CreateTask.tsx';
import RestaurantOrder from './tabs/RestaurantOrder.tsx';
import TaskList from './tabs/TaskList.tsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  fromDate: string;
  toDate: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  adults: number;
  children: number;
  totalPrice: number;
  paidPrice: number;
}

const tabs = [
  { id: 'booking', label: 'Booking Details' },
  { id: 'case', label: 'Create New Case' },
  { id: 'task', label: 'Create New Task' },
  { id: 'payment', label: 'Add Payment' },
  { id: 'restaurant', label: 'Restaurant Order' },
];

export default function ReservationModal({ 
  isOpen,
  onClose,
  roomNumber,
  fromDate,
  toDate,
  name,
  surname,
  email,
  phoneNumber,
  adults,
  children,
  totalPrice,
  paidPrice,
}: ModalProps) {
  const [activeTab, setActiveTab] = useState('booking');
  const { user } = useSelector((state: { user: any }) => state.user);
  const isPastruseRole = user?.jobRole?.toLowerCase() === 'pastruese';

  const getTabs = () => {
    const baseTabs = [
      { id: 'booking', label: 'Booking Details' },
    ];

    if (isPastruseRole) {
      baseTabs.push({ id: 'tasks', label: 'My Tasks' });
    } else {
      baseTabs.push(
        { id: 'case', label: 'Create New Case' },
        { id: 'task', label: 'Create New Task' },
        { id: 'payment', label: 'Add Payment' },
        { id: 'restaurant', label: 'Restaurant Order' }
      );
    }

    return baseTabs;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-3xl rounded-lg shadow-xl">
        <div className="p-3 border-b border-grey-goose flex justify-between items-center">
          <h2 className="text-lg font-semibold text-hazel-green">
            Room - {roomNumber} - Reservation Details - 
            <span className="text-watermelon">Reserved</span>
          </h2>
          <button
            onClick={onClose}
            className="text-bluish-grey hover:text-hazel-green transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <div className="border-b border-b border-grey-goose">
          <nav className="flex">
            {getTabs().map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === tab.id
                    ? 'text-hazel-green border-b-2 border-hazel-green bg-seashell'
                    : 'text-bluish-grey hover:text-hazel-green hover:bg-tealish-blue'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-4 rounded-lg bg-seashell max-h-[calc(100vh-12rem)] overflow-y-auto">
          {activeTab === 'booking' && (
            <BookingDetails
              fromDate={fromDate}
              toDate={toDate}
              name={name}
              surname={surname}
              email={email}
              phoneNumber={phoneNumber}
              adults={adults}
              children={children}
              totalPrice={totalPrice}
              paidPrice={paidPrice}
            />
          )}
          {activeTab === 'case' && !isPastruseRole && <CreateCase roomNumber={roomNumber} />}
          {activeTab === 'task' && !isPastruseRole && <CreateTask roomNumber={roomNumber} />}
          {activeTab === 'payment' && !isPastruseRole && <AddPayment />}
          {activeTab === 'restaurant' && !isPastruseRole && <RestaurantOrder roomNumber={roomNumber} />}
          {activeTab === 'tasks' && isPastruseRole && <TaskList roomNumber={roomNumber} />}
        </div>
      </div>
    </div>
  );
}