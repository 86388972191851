import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBeer } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import BarProductForm from './BarProductForm';
import Loading from '../../components/loader';
import { getBarProductById } from '../../api/barProduct';

function EditBarProduct() {
  const { id } = useParams();
  const [barProduct, setBarProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBarProduct = async () => {
      try {
        const response = await getBarProductById(id);
        const barProductData = response.data;
        
        // Format the data to include products from BarProductRecipe
        const formattedBarProduct = {
          id: barProductData.id,
          name: barProductData.name,
          type: barProductData.type,
          price: barProductData.price,
          description: barProductData.description,
          imageUrl: barProductData.imageUrl,
          menuId: barProductData.menuId,
          products: barProductData.BarProductRecipe?.length > 0 
            ? barProductData.BarProductRecipe.map(recipe => ({
                id: recipe.id,
                quantity: recipe.quantity,
                unit: recipe.unit
              }))
            : []
        };
        
        console.log('Fetched bar product data:', barProductData);
        console.log('Formatted bar product:', formattedBarProduct);
        setBarProduct(formattedBarProduct);
      } catch (error) {
        console.error('Failed to fetch bar product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBarProduct();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBeer}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Edit Bar Product'
      />
      <BarProductForm isEdit={true} barProduct={barProduct} />
    </div>
  );
}

export default EditBarProduct;
