import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTitle from '../../components/page-title';
import VenueMenuForm from './VenueMenuForm';
import { getMenu } from '../../api/venueMenus';
import { getDishSections } from '../../api/dishSection';
import { getBarProducts } from '../../api/barProduct';
import Loading from '../../components/loader';

function EditVenueMenuForm() {
  const { id } = useParams();
  const [initialMenu, setInitialMenu] = useState(null);
  const [dishSections, setDishSections] = useState([]);
  const [barProducts, setBarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const [menuData, dishSectionsData, barProductsData] = await Promise.all([
          getMenu(id),
          getDishSections(),
          getBarProducts()
        ]);
        setInitialMenu(menuData.data);
        setDishSections(dishSectionsData.data || []);
        setBarProducts(barProductsData.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchMenuData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon icon="fa-solid fa-utensils" className='text-hazel-green mr-2'/>
        }
        title='Edit Menu'
      />
      <VenueMenuForm 
        isEdit={true} 
        initialMenu={initialMenu} 
        dishSections={dishSections} 
        barProducts={barProducts} 
      />
    </div>
  );
}

export default EditVenueMenuForm;
