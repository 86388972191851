import React, { useState, useRef } from 'react';
import Draggable from "react-draggable";
import { IconRepeat, IconTrash, IconPencil, IconCirclePlus, IconX, IconLink, IconUnlink } from '@tabler/icons-react';
import { deleteTable } from '../../api/restaurantInterface';
import { useNavigate } from 'react-router-dom';

function Table({ table, onDelete, onMerge, onUnmerge, isAssigned, position, onMove, containerWidth, hasRoomService }) {
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const dragStartPosRef = useRef({ x: 0, y: 0 });
    const isMerged = table.mergedFromIds && JSON.parse(table.mergedFromIds).length > 1;

    // Utility functions remain the same
    const getResponsiveClasses = () => {
        if (containerWidth < 640) {
            return 'w-20 h-20'; // 80px for mobile
        }
        return 'w-24 h-24'; // 96px for desktop
    };

    const getTableColor = () => {
        if (table.isStaffTable) return 'bg-purple-500';
        if (isMerged) return 'bg-blue-500';
        return table.isAvailable ? 'bg-green-500' : 'bg-red-500';
    };

    const handleTableClick = (e) => {
        if (isDragging) return;
        const tableWrapper = e.target.closest('#table-wrapper');
        const managementSection = e.target.closest('.table-management');
        if (tableWrapper && !managementSection) {
            navigate(`/restaurant-interface/order/${table.id}`);
        }
    };

    const onStart = () => {
        setIsDragging(false);
        dragStartPosRef.current = { x: 0, y: 0 };
    };

    const onDrag = (e, data) => {
        const dx = data.x - dragStartPosRef.current.x;
        const dy = data.y - dragStartPosRef.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance > 5) {
            setIsDragging(true);
        }
    };

    const onStop = (e, data) => {
        if (isDragging) {
            setTimeout(() => {
                onMerge(table, data);
                onMove?.(table.id, { x: data.x, y: data.y });
            }, 50);
        } else {
            handleTableClick(e);
        }
        setIsDragging(false);
    };

    return (
        <Draggable
            defaultPosition={position}
            onStart={onStart}
            onDrag={onDrag}
            onStop={onStop}
            bounds="parent"
            grid={[10, 10]} // Smaller grid for more precise positioning
        >
            <div className={`absolute ${isDragging ? 'z-50 dragging' : 'z-0'} ${isMerged ? 'merged-table' : ''}`}>
                {isMerged && (
                    <div className="mb-1 xs:mb-2 flex items-center gap-1 xs:gap-2 p-0.5 xs:p-1 bg-blue-100 text-blue-800 text-[10px] xs:text-xs sm:text-sm rounded-full shadow-md">
                        <IconLink size={12} className="text-blue-500 hidden xs:block" />
                        <span>Merged</span>
                        <button 
                            className="bg-red-200 text-red-600 rounded-full px-1 sm:px-2 py-0.5 text-[10px] xs:text-xs ml-1 xs:ml-2 flex items-center gap-1" 
                            onClick={(e) => {
                                e.stopPropagation();
                                onUnmerge(table.id);
                            }}
                        >
                            <IconUnlink size={12} className="hidden xs:block" /> 
                            <span>Unmerge</span>
                        </button>
                    </div>
                )}
                <div 
                    id="table-wrapper"
                    data-table-id={table.id}
                    className={`${getResponsiveClasses()} ${getTableColor()} 
                        rounded-lg shadow-md 
                        flex items-center justify-center 
                        cursor-move relative 
                        transition-all duration-200
                        hover:shadow-lg
                        ${isAssigned ? 'opacity-100' : 'opacity-80'}
                        transform hover:scale-105`}
                    style={{
                        position: 'absolute',
                        left: position?.x || 0,
                        top: position?.y || 0,
                        touchAction: 'none'
                    }}
                >
                    <div className="transform rotate-90 flex flex-col items-center justify-between p-1 xs:p-2 sm:p-3">
                        <div className="text-[10px] xs:text-xs sm:text-sm text-white font-semibold truncate max-w-full flex items-center gap-1">
                            Table {table.number}
                            {isMerged && ' (M)'}
                            {hasRoomService && (
                                <span className="inline-flex items-center justify-center bg-yellow-400 text-black text-[8px] xs:text-[10px] px-1 rounded-full">
                                    DH
                                </span>
                            )}
                        </div>
                        <div className="text-xl xs:text-2xl sm:text-4xl text-white font-bold">{table.number}</div>
                        <div className="w-full flex justify-between items-center text-[10px] xs:text-xs sm:text-sm">
                            <span className="text-white truncate">Seats: {table.capacity}</span>
                            {isMerged && (
                                <IconRepeat
                                    size={14}
                                    className="text-white cursor-pointer hover:text-gray-200"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onUnmerge(table.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default Table;