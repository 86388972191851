import React, { useEffect, useState } from 'react';
import { getTasksByRoom, updateTaskStatus } from '../../../../../api/task';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { TaskStatus, TaskPriority } from '../../../../../types/task';
import { toast } from 'react-toastify';
import { Clock, Calendar, User, Info, CheckCircle2, AlertCircle } from 'lucide-react';

interface Task {
  id: string;
  title: string;
  description: string;
  roomNumber: string;
  source: string;
  priority: TaskPriority;
  status: TaskStatus;
  dueDate: string;
  assignedTo: {
    id: string;
    name: string;
    surname: string;
    jobRole: string;
  } | null;
  createdBy: {
    id: string;
    name: string;
    surname: string;
    jobRole: string;
  };
}

interface TaskListProps {
  roomNumber: string;
}

const TaskList: React.FC<TaskListProps> = ({ roomNumber }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state: { user: any }) => state.user);

  const fetchTasks = async () => {
    try {
      const response = await getTasksByRoom(roomNumber);
      if (response.data.statusCode === 200) {
        const filteredTasks = response.data.message.filter((task: Task) => {
          if (user?.jobRole?.toLowerCase() === 'pastruese') {
            return task.assignedTo?.id === user.id;
          }
          return true;
        });
        setTasks(filteredTasks);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      toast.error('Failed to fetch tasks');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [roomNumber, user]);

  const handleUpdateStatus = async (taskId: string, newStatus: TaskStatus) => {
    try {
      const response = await updateTaskStatus(taskId, newStatus);
      if (response.data.statusCode === 200) {
        toast.success('Task status updated successfully');
        fetchTasks();
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      toast.error('Failed to update task status');
    }
  };

  const getPriorityColor = (priority: TaskPriority) => {
    switch (priority) {
      case 'HIGH':
        return 'bg-dawn-pink text-watermelon ring-1 ring-watermelon ring-opacity-20';
      case 'MEDIUM':
        return 'bg-seashell text-sage ring-1 ring-sage ring-opacity-20';
      case 'LOW':
        return 'bg-tealish-blue text-bluish-grey ring-1 ring-bluish-grey ring-opacity-20';
      default:
        return 'bg-grey-goose text-bluish-grey ring-1 ring-bluish-grey ring-opacity-20';
    }
  };

  const getStatusColor = (status: TaskStatus) => {
    switch (status) {
      case 'PENDING':
        return 'bg-seashell text-sage ring-1 ring-sage ring-opacity-20';
      case 'IN_PROGRESS':
        return 'bg-tealish-blue text-bluish-grey ring-1 ring-bluish-grey ring-opacity-20';
      case 'COMPLETED':
        return 'bg-hazel-green bg-opacity-10 text-hazel-green ring-1 ring-hazel-green ring-opacity-20';
      case 'CANCELLED':
        return 'bg-dawn-pink text-watermelon ring-1 ring-watermelon ring-opacity-20';
      default:
        return 'bg-grey-goose text-bluish-grey ring-1 ring-bluish-grey ring-opacity-20';
    }
  };

  const getStatusIcon = (status: TaskStatus) => {
    switch (status) {
      case 'COMPLETED':
        return <CheckCircle2 className="w-4 h-4" />;
      case 'CANCELLED':
        return <AlertCircle className="w-4 h-4" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hazel-green"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl font-semibold text-hazel-green">Room {roomNumber} Tasks</h2>
          <p className="text-sm text-bluish-grey mt-1">
            {tasks.length === 0 ? 'No tasks assigned' : `${tasks.length} task${tasks.length === 1 ? '' : 's'} assigned`}
          </p>
        </div>
      </div>

      {tasks.length === 0 ? (
        <div className="bg-seashell rounded-lg p-8 text-center">
          <div className="mx-auto w-16 h-16 bg-grey-goose bg-opacity-20 rounded-full flex items-center justify-center mb-4">
            <Info className="w-8 h-8 text-bluish-grey" />
          </div>
          <p className="text-bluish-grey font-medium">No tasks found for this room</p>
          <p className="text-sm text-bluish-grey mt-1">Tasks will appear here when they are assigned</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {tasks.map((task) => (
            <div
              key={task.id}
              className="bg-white rounded-xl shadow-sm border border-grey-goose hover:shadow-md transition-all duration-200 overflow-hidden group"
            >
              <div className="p-5">
                <div className="flex items-start justify-between mb-4">
                  <div className="flex-grow">
                    <div className="flex items-center gap-3 mb-2">
                      <h3 className="text-lg font-medium text-hazel-green group-hover:text-sage transition-colors">
                        {task.title}
                      </h3>
                      <div className="flex gap-2">
                        <span className={`px-2.5 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1 ${getPriorityColor(task.priority)}`}>
                          {task.priority}
                        </span>
                        <span className={`px-2.5 py-1 rounded-full text-xs font-medium inline-flex items-center gap-1 ${getStatusColor(task.status)}`}>
                          {getStatusIcon(task.status)}
                          {task.status}
                        </span>
                      </div>
                    </div>
                    {task.description && (
                      <p className="text-bluish-grey text-sm mb-4">{task.description}</p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="space-y-3">
                    <div className="flex items-center gap-2 text-sm">
                      <Calendar className="w-4 h-4 text-hazel-green" />
                      <span className="text-bluish-grey">
                        Due: <span className="font-medium">{format(new Date(task.dueDate), 'PPP')}</span>
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-sm">
                      <Info className="w-4 h-4 text-hazel-green" />
                      <span className="text-bluish-grey">
                        Source: <span className="font-medium">{task.source}</span>
                      </span>
                    </div>
                  </div>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2 text-sm">
                      <User className="w-4 h-4 text-hazel-green" />
                      <span className="text-bluish-grey">
                        Assigned to:{' '}
                        <span className="font-medium">
                          {task.assignedTo ? `${task.assignedTo.name} ${task.assignedTo.surname}` : 'Unassigned'}
                        </span>
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-sm">
                      <Clock className="w-4 h-4 text-hazel-green" />
                      <span className="text-bluish-grey">
                        Created by:{' '}
                        <span className="font-medium">
                          {`${task.createdBy.name} ${task.createdBy.surname}`}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                {user?.jobRole?.toLowerCase() === 'pastruese' && task.assignedTo?.id === user.id && (
                  <div className="mt-4 pt-4 border-t border-grey-goose flex justify-end gap-3">
                    {task.status === 'PENDING' && (
                      <button
                        className="px-4 py-2 bg-tealish-blue text-bluish-grey rounded-lg hover:bg-seashell transition-colors text-sm font-medium inline-flex items-center gap-2"
                        onClick={() => handleUpdateStatus(task.id, 'IN_PROGRESS')}
                      >
                        <Clock className="w-4 h-4" />
                        Start Task
                      </button>
                    )}
                    {task.status === 'IN_PROGRESS' && (
                      <button
                        className="px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors text-sm font-medium inline-flex items-center gap-2"
                        onClick={() => handleUpdateStatus(task.id, 'COMPLETED')}
                      >
                        <CheckCircle2 className="w-4 h-4" />
                        Complete Task
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskList;
