import { Search } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { TransactionAccordion } from './TransactionAccordion.tsx';
import { getTransactionsByClient, getTransactionsBySupplier } from '../../api/accounting';
import { toast } from 'react-toastify';

interface Transaction {
  id: string;
  transactionNumber: string;
  transactionDate: string;
  amount: number;
  paymentType: string;
  transactionType: string;
  isPaid: boolean;
  description: string | null;
  dueDate?: string | null;
  TransactionProduct: any[];
  client: any;
  supplier: any;
}

interface TransactionsTabProps {
  clientId?: string;
  supplierId?: string;
}

export const TransactionsTab: React.FC<TransactionsTabProps> = ({ clientId, supplierId }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        let response;
        
        if (clientId) {
          console.log('Fetching transactions for client:', clientId);
          response = await getTransactionsByClient(clientId, searchQuery);
        } else if (supplierId) {
          console.log('Fetching transactions for supplier:', supplierId);
          response = await getTransactionsBySupplier(supplierId, searchQuery);
        }

        console.log('Raw API response:', response);

        if (response?.data) {
          const transactionsData = Array.isArray(response.data) ? response.data : [];
          console.log('Transactions data:', transactionsData);
          setTransactions(transactionsData);
        } else {
          console.warn('No transactions data received');
          setTransactions([]);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
        toast.error('Failed to load transactions');
        setTransactions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [clientId, supplierId, searchQuery]);

  const formatDate = (dateString: string) => {
    try {
      return new Date(dateString).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const formatCurrency = (amount: number) => {
    try {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(amount);
    } catch (error) {
      console.error('Error formatting currency:', error);
      return '€0.00';
    }
  };

  const getTransactionStatus = (transaction: Transaction) => {
    if (transaction.isPaid) {
      return 'Paid';
    }
    if (transaction.dueDate && new Date(transaction.dueDate) < new Date()) {
      return 'Overdue';
    }
    return 'Pending';
  };

  const filterTransactions = (type: string) => {
    if (!Array.isArray(transactions)) {
      console.error('Transactions is not an array:', transactions);
      return [];
    }

    try {
      return transactions
        .filter(t => t && t.transactionType === type)
        .map(t => ({
          id: t.id,
          date: formatDate(t.transactionDate),
          transactionNumber: t.transactionNumber,
          description: t.description || 'No description',
          amount: formatCurrency(t.amount),
          paymentType: t.paymentType,
          status: getTransactionStatus(t),
          products: t.TransactionProduct || []
        }));
    } catch (error) {
      console.error('Error filtering transactions:', error);
      return [];
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hazel-green"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Search bar and filters */}
      <div className="bg-white p-4 rounded-lg border border-mercury sticky top-0 z-20">
        <div className="flex items-center gap-4">
          <div className="relative flex-1 max-w-md">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search transactions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
        </div>
      </div>

      {/* Transactions content */}
      <div className="space-y-4">
        {transactions.length === 0 ? (
          <div className="text-center text-gray-500 py-8 bg-white rounded-lg border border-mercury">
            No transactions found
          </div>
        ) : (
          <>
            <TransactionAccordion
              title="Payments"
              data={filterTransactions('PAYMENT')}
              columns={['DATE', 'TRANSACTION #', 'DESCRIPTION', 'AMOUNT', 'PAYMENT TYPE', 'STATUS']}
            />

            <TransactionAccordion
              title="Receipts"
              data={filterTransactions('RECEIPT')}
              columns={['DATE', 'TRANSACTION #', 'DESCRIPTION', 'AMOUNT', 'PAYMENT TYPE', 'STATUS']}
            />
          </>
        )}
      </div>
    </div>
  );
};