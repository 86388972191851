import React, { useState, useEffect, useCallback } from "react";
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../utils/PermissionCheck';
import { FunctionButton } from "./FunctionButtons";
import TableGrid from './TableGrid';
import Loading from '../../components/loader';
import ReservationList from './ReservationList';
import { Link } from 'react-router-dom';
import { IconPlus, IconLayersIntersect2 } from "@tabler/icons-react";
import { getEnvironments, unmergeAllTables } from "../../api/restaurant";

export default function MainRestaurantPage() {
    const [loading, setLoading] = useState(false);
    const [activeButtonReservation, setActiveButtonReservation] = useState("buttonReservation1");
    const [activeButtonFloor, setActiveButtonFloor] = useState(null);
    const [environments, setEnvironments] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const user = useSelector((state) => state.user.user);
    const waiterId = user?.id;

    const fetchEnvironments = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getEnvironments();
            setEnvironments(response.data);
            if (response.data.length > 0 && !activeButtonFloor) {
                setActiveButtonFloor(response.data[0].id);
            }
        } catch (err) {
            console.error('Failed to fetch environments:', err);
        } finally {
            setLoading(false);
        }
    }, [activeButtonFloor]);

    useEffect(() => {
        fetchEnvironments();
    }, [fetchEnvironments]);

    useEffect(() => {
        const intervalId = setInterval(fetchEnvironments, 30000);
        return () => clearInterval(intervalId);
    }, [fetchEnvironments]);

    if (loading) {
        return <Loading />;
    }

    const activeEnvironment = environments.find(env => env.id === activeButtonFloor);

    return (
        <div className="p-4 sm:p-6">
            <div className="flex flex-col w-full items-start justify-center gap-4 sm:gap-6 my-4">
                <div className="w-full flex flex-col sm:flex-row justify-between items-center gap-2">
                    <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                        <FunctionButton
                            small={true}
                            green={true}
                            className="rounded-md px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base"
                            isActive={activeButtonReservation === "buttonReservation1"}
                            onClick={() => setActiveButtonReservation("buttonReservation1")}
                        >
                            Reservation Grid
                        </FunctionButton>

                        <FunctionButton
                            small={true}
                            green={true}
                            className="rounded-md px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base"
                            isActive={activeButtonReservation === "buttonReservation2"}
                            onClick={() => setActiveButtonReservation("buttonReservation2")}
                        >
                            Reservation List
                        </FunctionButton>
                    </div>

                    <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto justify-end">
                        <Link
                            to='/restaurant/add-table'
                            className='whitespace-nowrap flex justify-center items-center text-white rounded-md bg-sage px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm hover:bg-sage-dark transition-colors duration-200'
                        >
                            <IconPlus className='mr-2' size={16} />
                            New table
                        </Link>

                        <Link
                            to='/restaurant/add-environment'
                            className='whitespace-nowrap flex justify-center items-center text-white rounded-md bg-sage px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm hover:bg-sage-dark transition-colors duration-200'
                        >
                            <IconPlus className='mr-2' size={16} />
                            New environment
                        </Link>

                        <button
                            onClick={async () => {
                                try {
                                    await unmergeAllTables();
                                    window.location.reload();
                                } catch (error) {
                                    console.error("Error unmerging tables:", error);
                                }
                            }}
                            className='whitespace-nowrap flex justify-center items-center text-white rounded-md bg-sage px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm hover:bg-sage-dark transition-colors duration-200'
                        >
                            <IconLayersIntersect2 className='mr-2' size={16} />
                            Unmerge All Tables
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-2 sm:gap-4">
                    <h3 className="font-semibold text-md sm:text-lg text-gray-800">
                        Floors Plan
                    </h3>

                    <div className="flex flex-col sm:flex-row items-start justify-start gap-2">
                        {environments.map((environment) => (
                            <FunctionButton
                                key={environment.id}
                                small={true}
                                green={true}
                                className="rounded-md px-2 py-1 sm:px-3 sm:py-2 text-sm sm:text-base"
                                isActive={activeButtonFloor === environment.id}
                                onClick={() => setActiveButtonFloor(environment.id)}
                            >
                                {environment.name}
                            </FunctionButton>
                        ))}
                    </div>
                </div>

                {activeButtonReservation === "buttonReservation1" ? (
                    activeEnvironment ? (
                        <TableGrid environment={activeEnvironment} waiterId={waiterId} />
                    ) : (
                        <p>No environment selected.</p>
                    )
                ) : (
                    <ReservationList />
                )}
            </div>
        </div>
    );
}
