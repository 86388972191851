import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import TableGrid from './TableGrid';
import Loading from '../../components/loader';
import { AddButton } from '../../components/buttons';
import { getAllEnvironments } from '../../api/restaurantInterface';
import { SlidingBtnTabEnvironments } from "./SlidingBtnTabEnviroments";
import { useHeaderContext } from "../../components/layout/RestaurantLayout";

export default function TablePage() {
    const [loading, setLoading] = useState(false);
    const [environments, setEnvironments] = useState([]);
    const [activeEnvironment, setActiveEnvironment] = useState(null);

    useEffect(() => {
        const fetchEnvironments = async () => {
            setLoading(true);
            try {
                const response = await getAllEnvironments();
                setEnvironments(response.data);
                if (response.data.length > 0) {
                    setActiveEnvironment(response.data[0]);
                }
            } catch (err) {
                console.error('Failed to fetch environments:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchEnvironments();
    }, []);

    const handleTabChange = (environmentId) => {
        const selectedEnvironment = environments.find(env => env.id === environmentId);
        setActiveEnvironment(selectedEnvironment);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col h-full bg-gray-50">
            {/* Only Environment Tabs */}
            <div className="sticky top-0 z-10 bg-white shadow-sm">
                <div className="px-2 py-2 sm:px-4 sm:py-3">
                    {environments.length > 0 && (
                        <div className="flex justify-center">
                            <SlidingBtnTabEnvironments
                                environments={environments}
                                onTabChange={handleTabChange}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 min-h-0">
                {activeEnvironment ? (
                    <TableGrid environment={activeEnvironment} />
                ) : (
                    <div className="flex items-center justify-center h-full">
                        <p className="text-gray-500 text-sm sm:text-base">
                            No environment selected
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

