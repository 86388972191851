import React, { useState, useEffect, useCallback } from 'react';
import { IconToolsKitchen2, IconCoffee, IconShoppingCart } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import MenuSection from '../hotelInterfaceComponents/pms/reservationDetailsModal/tabs/MenuSection.tsx';
import {
  getCategoryOrderProducts,
  createCategoryOrderProduct,
  deleteCategoryOrderProduct,
  updateCategoryOrderProduct
} from '../../api/categoryOrderProduct';
import { getBarProducts } from '../../api/barProduct';
import { getDishSections } from '../../api/dishSection';
import { createPoolOrder } from '../../api/poolOrders';

function PoolRestaurantOrderModal({ isOpen, onClose, poolTicketReference, customerName, onOrderComplete }) {
  const user = useSelector((state) => state.user.user);
  const [activeSection, setActiveSection] = useState('kitchen');
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [barProducts, setBarProducts] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [groupedDishSections, setGroupedDishSections] = useState({});

  // Group bar products by category
  const groupBarProductsByCategory = useCallback((products) => {
    return products.reduce((acc, product) => {
      const category = product.category || 'Other';
      if (!acc[category]) acc[category] = [];
      acc[category].push(product);
      return acc;
    }, {});
  }, []);

  // Fetch menu data
  const fetchMenuData = useCallback(async () => {
    try {
      const [categoriesResponse, barProductsResponse, dishSectionsResponse] = await Promise.all([
        getCategoryOrderProducts(),
        getBarProducts(),
        getDishSections()
      ]);

      setCategories(categoriesResponse.data);
      setBarProducts(barProductsResponse.data);
      setDishSections(dishSectionsResponse.data);

      // Group dish sections by type
      const grouped = dishSectionsResponse.data.reduce((acc, item) => {
        const type = item.type || 'Other';
        if (!acc[type]) acc[type] = [];
        acc[type].push(item);
        return acc;
      }, {});
      setGroupedDishSections(grouped);
    } catch (error) {
      console.error('Error fetching menu data:', error);
      toast.error('Failed to load menu data');
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchMenuData();
    }
  }, [isOpen, fetchMenuData]);

  const addToOrder = (item) => {
    setOrderItems((prevItems) => {
      const existingItem = prevItems.find((i) => i.id === item.id);
      if (existingItem) {
        return prevItems.map((i) =>
          i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
        );
      }
      return [...prevItems, { ...item, quantity: 1 }];
    });
  };

  const removeFromOrder = (itemId) => {
    setOrderItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const updateQuantity = (itemId, newQuantity) => {
    if (newQuantity < 1) {
      removeFromOrder(itemId);
      return;
    }
    setOrderItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const handleSubmitOrder = async () => {
    if (!poolTicketReference) {
      toast.error('Pool ticket reference is required');
      return;
    }

    if (orderItems.length === 0) {
      toast.error('Please add items to your order');
      return;
    }

    setLoading(true);
    try {
      const isIndoorPool = poolTicketReference.startsWith('IN');
      const orderDetails = {
        reservationId: poolTicketReference,
        guestName: customerName,
        waiterId: user?.id,
        isIndoor: isIndoorPool,
        location: isIndoorPool ? 'Indoor Pool' : 'Outdoor Pool',
        totalAmount: orderItems.reduce((total, item) => 
          total + (parseFloat(item.price) * parseInt(item.quantity.toString(), 10)), 0),
        items: orderItems.map(item => ({
          id: item.id,
          quantity: item.quantity,
          price: item.price,
          name: item.name || item.title,
          category: item.category,
          type: item.type === 'kitchen' ? 'dish' : 'barItem'
        }))
      };

      console.log('Submitting pool order with details:', orderDetails);
      console.log('Pool ticket reference:', poolTicketReference);
      console.log('Customer name:', customerName);

      const response = await createPoolOrder(orderDetails);
      console.log('Pool order response:', response);
      
      setOrderItems([]);
      toast.success('Order submitted successfully');
      
      // Call onOrderComplete if provided
      if (onOrderComplete) {
        await onOrderComplete();
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error submitting pool order:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Failed to submit order';
      console.error('Full error details:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const totalAmount = orderItems.reduce((sum, item) => 
    sum + (item.price * item.quantity), 0
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black opacity-30" onClick={onClose} />

        <div className="relative bg-seashell rounded-lg w-full max-w-4xl mx-4 p-6 shadow-xl">
          <div className="flex justify-between items-center mb-6 border-b border-grey-goose pb-4">
            <h2 className="text-lg font-medium text-bluish-grey">
              Restaurant Order - {customerName}
            </h2>
            <button
              onClick={onClose}
              className="text-bluish-grey hover:text-sage transition-colors"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="space-y-6">
            <div className="flex space-x-4 mb-6">
              <button
                onClick={() => setActiveSection('kitchen')}
                className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
                  activeSection === 'kitchen'
                    ? 'bg-hazel-green text-white'
                    : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
                }`}
              >
                <IconToolsKitchen2 className="mr-2" size={20} />
                Kitchen
              </button>
              <button
                onClick={() => setActiveSection('bar')}
                className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
                  activeSection === 'bar'
                    ? 'bg-hazel-green text-white'
                    : 'bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white'
                }`}
              >
                <IconCoffee className="mr-2" size={20} />
                Bar
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Menu Items */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-bluish-grey">
                  {activeSection === 'kitchen' ? 'Kitchen Menu' : 'Bar Menu'}
                </h3>
                
                <div className="space-y-2 max-h-96 overflow-y-auto pr-4">
                  {activeSection === 'kitchen' ? (
                    Object.entries(groupedDishSections).map(([type, items]) => (
                      <MenuSection
                        key={type}
                        title={type}
                        items={items.map(item => ({
                          id: item.id,
                          name: item.name,
                          price: item.price,
                          imageUrl: item.imageUrl,
                          type: 'kitchen'
                        }))}
                        isExpanded={expandedCategory === type}
                        onToggle={() => setExpandedCategory(
                          expandedCategory === type ? null : type
                        )}
                        onItemClick={(item) => addToOrder({ ...item, type: 'kitchen' })}
                      />
                    ))
                  ) : (
                    <div className="space-y-2">
                      {categories
                        .filter(cat => cat.categoryType === 'BAR')
                        .map(category => (
                          <MenuSection
                            key={category.id}
                            title={category.title}
                            items={barProducts
                              .filter(product => product.categoryOrderProductId === category.id)
                              .map(item => ({
                                id: item.id,
                                name: item.name,
                                price: item.price,
                                type: 'barItem'
                              }))}
                            isExpanded={expandedCategory === category.id}
                            onToggle={() => setExpandedCategory(
                              expandedCategory === category.id ? null : category.id
                            )}
                            onItemClick={(item) => addToOrder({ ...item, type: 'barItem' })}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Order Summary */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-bluish-grey flex items-center">
                  <IconShoppingCart className="mr-2" size={20} />
                  Order Summary
                </h3>
                
                <div className="space-y-2 max-h-96 overflow-y-auto pr-4">
                  {orderItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center p-3 bg-white rounded-lg shadow-sm"
                    >
                      <div className="flex-1">
                        <h4 className="text-bluish-grey font-medium">{item.name}</h4>
                        <p className="text-sage text-sm">${item.price}</p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => updateQuantity(item.id, item.quantity - 1)}
                          className="text-bluish-grey hover:text-sage transition-colors"
                        >
                          -
                        </button>
                        <span className="text-bluish-grey px-2">{item.quantity}</span>
                        <button
                          onClick={() => updateQuantity(item.id, item.quantity + 1)}
                          className="text-bluish-grey hover:text-sage transition-colors"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="border-t border-grey-goose pt-4 mt-4">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-bluish-grey font-medium">Total Amount:</span>
                    <span className="text-hazel-green font-semibold">${totalAmount.toFixed(2)}</span>
                  </div>
                  <button
                    onClick={handleSubmitOrder}
                    disabled={loading || orderItems.length === 0}
                    className={`w-full py-2 px-4 rounded-lg transition-colors ${
                      loading || orderItems.length === 0
                        ? 'bg-grey-goose text-bluish-grey cursor-not-allowed'
                        : 'bg-hazel-green text-white hover:bg-sage'
                    }`}
                  >
                    {loading ? 'Submitting...' : 'Submit Order'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoolRestaurantOrderModal;
