import React, { useState } from 'react';
import { MapPin, Menu, X } from 'lucide-react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import store from '../../store';
import { setUser } from '../../store/slices/user-slice';

import {
  IconLogout,
  IconSettings,
  IconChevronDown
} from '@tabler/icons-react';
import SocialIcons from '../../views/hotelInterface/common/SocialIcons';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { user } = useSelector((state) => state.user);
  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const logout = () => {
    localStorage.clear();
    store.dispatch(setUser(null));
    navigate('/');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="bg-white shadow-sm px-4 sm:px-6 py-4">
      <div className="flex items-center justify-between">
        {/* Logo Section */}
        <div className="flex items-center space-x-2">
          <MapPin className="text-salmon-pink" size={24} />
          <span className="text-2xl font-semibold">
            <span className="text-watermelon">Lake</span>
            <span className="text-bluish-grey">Side</span>
          </span>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="lg:hidden text-bluish-grey hover:text-watermelon"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center space-x-8">
          <CustomNavLink to="/hotelInterface" label={t('navbar.dashboard')} />
          <CustomNavLink to="/hotelInterface/pms-management" label={t('navbar.pms')} />
          <CustomNavLink to="/hotelInterface/roomsList" label={t('navbar.manageProperties')} />
          <CustomNavLink to="/hotelInterface/todoList" label={t('navbar.roomOperations')} />
          <CustomNavLink to="/hotelInterface/damages" label={t('navbar.damages')} />
          {user?.jobRole?.toLowerCase() === 'hoteli' && (
            <CustomNavLink to="/hotelInterface/indoor-pool" label={t('navbar.indoorPool')} />
          )}
          {/* Bookings Dropdown */}
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="text-bluish-grey hover:text-watermelon transition-colors flex items-center"
            >
              {t('navbar.bookings')}
              <IconChevronDown className={`ml-1 transform transition-transform ${dropdownOpen ? 'rotate-180' : ''}`} size={16} />
            </button>
            {dropdownOpen && (
              <div className="absolute mt-2 bg-white shadow-lg rounded-md w-48 py-2 z-10">
                <DropdownLink to="/hotelInterface/checkedIn" label={t('navbar.checkedIn')} />
                <DropdownLink to="/hotelInterface/checkedOut" label={t('navbar.checkedOut')} />
                <DropdownLink to="/hotelInterface/reservationsList" label={t('navbar.reservations')} />
                <DropdownLink to="/hotelInterface/cancelledBookings" label={t('navbar.cancelledBookings')} />
              </div>
            )}
          </div>
        </div>

        {/* User Profile Section - Desktop */}
        <div className="hidden lg:flex items-center">
          <div className="relative">
            <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setShowDropDown(!showDropDown)}>
              <img src="/images/profile-placeholder.png" alt="profile" className="w-10 h-10" />
              <span className="text-bluish-grey hidden sm:block">{user.name} {user.surname}</span>
              <IconChevronDown className="text-heather" />
            </div>
            
            {showDropDown && (
              <div className="absolute right-0 mt-2 w-60 bg-white border border-light-gray rounded-md shadow-lg z-50">
                <Link to="/account-settings" className="flex p-3 border-b border-light-gray hover:bg-gray-50" onClick={() => setShowDropDown(false)}>
                  <IconSettings className="mr-2" />
                  <span>Settings</span>
                </Link>
                <button onClick={logout} className="flex w-full p-3 hover:bg-gray-50">
                  <IconLogout className="mr-2" />
                  <span>Logout</span>
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden absolute top-16 left-0 right-0 bg-white shadow-lg z-50">
            <div className="flex flex-col p-4">
              <MobileNavLink to="/hotelInterface" label={t('navbar.dashboard')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/pms-management" label={t('navbar.pms')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/roomsList" label={t('navbar.manageProperties')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/todoList" label={t('navbar.roomOperations')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/damages" label={t('navbar.damages')} onClick={toggleMobileMenu} />
              {user?.jobRole?.toLowerCase() === 'hoteli' && (
                <MobileNavLink to="/hotelInterface/indoor-pool" label={t('navbar.indoorPool')} onClick={toggleMobileMenu} />
              )}
              <MobileNavLink to="/hotelInterface/checkedIn" label={t('navbar.checkedIn')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/checkedOut" label={t('navbar.checkedOut')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/reservationsList" label={t('navbar.reservations')} onClick={toggleMobileMenu} />
              <MobileNavLink to="/hotelInterface/cancelledBookings" label={t('navbar.cancelledBookings')} onClick={toggleMobileMenu} />
              <div className="border-t border-gray-200 mt-4 pt-4">
                <MobileNavLink to="/account-settings" label={t('navbar.accountSettings')} onClick={toggleMobileMenu} />
                <button onClick={logout} className="w-full text-left py-2 px-4 text-bluish-grey hover:text-watermelon">
                  {t('navbar.logout')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// Custom NavLink component
const CustomNavLink = ({ to, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${isActive ? 'text-watermelon' : 'text-bluish-grey'} hover:text-watermelon transition-colors`
    }
  >
    {label}
  </NavLink>
);

// Mobile NavLink component
const MobileNavLink = ({ to, label, onClick }) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={({ isActive }) =>
      `${isActive ? 'text-watermelon' : 'text-bluish-grey'} block px-4 py-2 hover:text-watermelon transition-colors`
    }
  >
    {label}
  </NavLink>
);

// Dropdown Link component
const DropdownLink = ({ to, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${isActive ? 'bg-gray-100' : ''} block px-4 py-2 text-bluish-grey hover:text-watermelon`
    }
  >
    {label}
  </NavLink>
);

export default Navbar;
