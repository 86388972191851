import React, {
  BaseSyntheticEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { verifyPasscode } from '../../api/passcode';
import { loginWithPasscode as loginWithPasscodeApi } from '../../api/passcode';
import { SubmitButton } from '../../components/buttons';
import useAuth from '../../hooks/useAuth';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles


function Passcode({ userId }) {
  const [arrayValue, setArrayValue] = useState<(string | number)[]>(["", "", "", ""]);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(0);
  const inputRefs = useRef<Array<HTMLInputElement> | []>([]);
  const { loginWithPasscode } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const keyCode = parseInt(e.key);
    if (!(keyCode >= 0 && keyCode <= 9) && e.key !== "Backspace" && !(e.metaKey && e.key === "v")) {
      e.preventDefault();
    }
  };

  const onChange = (e: BaseSyntheticEvent, index: number) => {
    setArrayValue((preValue: (string | number)[]) => {
      const newArray = [...preValue];

      if (parseInt(e.target.value)) {
        newArray[index] = parseInt(e.target.value);
      } else {
        newArray[index] = e.target.value;
      }

      return newArray;
    });
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      if (index === 0) {
        setCurrentFocusedIndex(0);
      } else {
        setCurrentFocusedIndex(index - 1);
        if (inputRefs && inputRefs.current && index === currentFocusedIndex) {
          inputRefs.current[index - 1].focus();
        }
      }
    } else {
      if (parseInt(e.key) && index < arrayValue.length - 1) {
        setCurrentFocusedIndex(index + 1);
        if (inputRefs && inputRefs.current && index === currentFocusedIndex) {
          inputRefs.current[index + 1].focus();
        }
      }
    }
  };

  const onFocus = (e: BaseSyntheticEvent, index: number) => {
    setCurrentFocusedIndex(index);
  };


  const verifyPasscodeHandler = async () => {
    const passcode = arrayValue.join("");
    const urlSegment = location.pathname;  
    try {
      console.log(`Verifying passcode: ${passcode}`);
      console.log(`Current URL Segment: ${urlSegment}`);
      const response = await loginWithPasscodeApi(passcode, urlSegment);
      console.log('Full response:', response);

      if (response.data.statusCode === 200 && response.data.redirectUrl) {
        console.log(`Redirecting to additional authentication at: ${response.data.redirectUrl}`);
        navigate(response.data.redirectUrl); // Redirect to additional auth page
        return; // Exit after navigating
    }

    if (response.status === 200) {
        toast.success("Passcode verified successfully");
        const userData = response.data.data;
        console.log('Received userData:', userData);
        await loginWithPasscode(userData); 

        const layout = userData.layout; 
        console.log(`Redirecting to layout: ${layout}`);
        navigate(layout); 
      }
    } catch (error) {

      if (error.response) {
        toast.error("Invalid passcode or error during login"); // Use toast for error
        console.error('Error during passcode verification:', error);
        setArrayValue(["", "", "", ""]); // Reset the input fields
        setCurrentFocusedIndex(0); // Reset focus to the first input
        inputRefs.current[0]?.focus();
      }
    }
  };



  useEffect(() => {
    const handlePaste = async () => {
      const pastePermission = await navigator.permissions.query({ name: "clipboard-read" as PermissionName });

      if (pastePermission.state === "denied") {
        throw new Error("Not allowed to read clipboard");
      }

      const clipboardContent = await navigator.clipboard.readText();
      try {
        let newArray: Array<number | string> = clipboardContent.split("");
        newArray = newArray.map((num) => Number(num));

        const lastIndex = arrayValue.length - 1;
        if (currentFocusedIndex > 0) {
          const remainingPlaces = lastIndex - currentFocusedIndex;
          const partialArray = newArray.slice(0, remainingPlaces + 1);
          setArrayValue([
            ...arrayValue.slice(0, currentFocusedIndex),
            ...partialArray
          ]);
        } else {
          setArrayValue([
            ...newArray,
            ...arrayValue.slice(newArray.length - 1, lastIndex)
          ]);
        }

        if (newArray.length < arrayValue.length && currentFocusedIndex === 0) {
          setCurrentFocusedIndex(newArray.length - 1);
          inputRefs.current[newArray.length - 1].focus();
        } else {
          setCurrentFocusedIndex(arrayValue.length - 1);
          inputRefs.current[arrayValue.length - 1].focus();
        }
      } catch (err) {
        console.error(err);
      }
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [arrayValue, currentFocusedIndex]);

  return (
    <>
      <br />
      <div className="flex flex-row gap-2 sm:gap-4 items-center justify-center text-center">
        {arrayValue.map((value: string | number, index: number) => (
          <input
            className="text-center p-2 sm:p-3 text-xl sm:text-2xl border-2 border-gray-800 rounded-lg font-semibold w-12 sm:w-16 h-12 sm:h-16"
            key={`index-${index}`}
            ref={(el) => el && (inputRefs.current[index] = el)}
            inputMode="numeric"
            maxLength={1}
            type="password"
            value={arrayValue[index] ? "*" : ""}
            onChange={(e) => onChange(e, index)}
            onKeyUp={(e) => onKeyUp(e, index)}
            onKeyDown={(e) => onKeyDown(e)}
            onFocus={(e) => onFocus(e, index)}
            style={{ fontFamily: 'monospace' }}
          />
        ))}
      </div>

      <button
        onClick={verifyPasscodeHandler}
        className="my-4 sm:my-6 mb-2 px-3 sm:px-4 bg-sage text-white rounded-md py-2 sm:py-3 font-semibold text-sm sm:text-md transition-all duration-300 hover:bg-hazel-green ease-in-out"
      >
        Verify Passcode
      </button>

      <div>
        <p className="font-semibold text-xs sm:text-sm text-gray-800">
          Don't have a passcode?
          <Link className="font-bold text-hazel-green" to={"/passcode/create-passcode"}>
            <strong> Create passcode</strong>
          </Link>
        </p>
      </div>
    </>
  );
};

export default Passcode;