import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { verifyTicket, checkInTicket, checkOutTicket } from '../../api/pools';
import { useTicket } from '../../context/TicketContext';

function TicketManagementModal({ isOpen, onClose, reservation, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const [showCheckInButton, setShowCheckInButton] = useState(false);
  const { updateTicketInfo } = useTicket();

  useEffect(() => {
    console.log('TicketManagementModal mounted/updated:', { 
      isOpen, 
      hasReservation: !!reservation,
      reservationData: reservation 
    });
  }, [isOpen, reservation]);

  // Early return if no reservation
  if (!reservation || !reservation.ticketReference) {
    console.log('TicketManagementModal: Invalid reservation data');
    return null;
  }

  const handleVerify = async () => {
    setLoading(true);
    try {
      console.log('Starting verification...');
      const response = await verifyTicket(reservation.ticketReference);
      console.log('Verification response:', response);

      if (response.data) {
        // Update the local state first
        const verifiedTicketInfo = {
          reservation: {
            ...response.data,
            status: 'VERIFIED'
          }
        };
        
        console.log('Setting verified ticket info:', verifiedTicketInfo);
        
        // Update the context
        updateTicketInfo(verifiedTicketInfo);
        
        // Update the parent component's state
        if (onUpdate) {
          await onUpdate();
        }
        
        // Show success message
        toast.success('Ticket verified successfully!');
        
        // Dispatch event with full ticket info
        window.dispatchEvent(new CustomEvent('ticketVerified', { 
          detail: { 
            ticketReference: reservation.ticketReference,
            status: 'VERIFIED',
            ticketInfo: verifiedTicketInfo
          } 
        }));
        
        // Keep modal open to show check-in button
        setShowCheckInButton(true);
      }
    } catch (error) {
      console.error('Verification error:', error);
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage || 'Failed to verify ticket');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckIn = async () => {
    setLoading(true);
    try {
      const response = await checkInTicket(reservation.ticketReference);
      console.log('Check-in response:', response);

      if (response.data) {
        const checkedInTicketInfo = {
          reservation: {
            ...response.data,
            status: 'CHECKED_IN'
          }
        };
        
        console.log('Setting checked-in ticket info:', checkedInTicketInfo);
        
        // Update the context
        updateTicketInfo(checkedInTicketInfo);
        
        // Update the parent component's state
        if (onUpdate) {
          await onUpdate();
        }
        
        // Show success message
        toast.success('Check-in successful!');
        
        // Close modal after check-in
        onClose();
        
        // Dispatch check-in event
        window.dispatchEvent(new CustomEvent('ticketCheckedIn', { 
          detail: { 
            ticketReference: reservation.ticketReference,
            status: 'CHECKED_IN',
            ticketInfo: checkedInTicketInfo
          } 
        }));
      }
    } catch (error) {
      console.error('Check-in error:', error);
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage || 'Failed to check in');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckOut = async () => {
    setLoading(true);
    try {
      await checkOutTicket(reservation.ticketReference);
      toast.success('Check-out successful!');
      if (onUpdate) onUpdate();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to check out');
      console.error('Check-out error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'CHECKED_IN':
        return 'bg-[#E8F5E9] text-hazel-green';
      case 'CHECKED_OUT':
        return 'bg-heather text-bluish-grey';
      case 'VERIFIED':
        return 'bg-tealish-blue text-bluish-grey';
      default:
        return 'bg-dawn-pink text-salmon-pink';
    }
  };

  return (
    <div 
      className={`fixed inset-0 z-[1000] overflow-y-auto ${!isOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}
      style={{ 
        transition: 'opacity 0.3s ease-in-out',
        visibility: isOpen ? 'visible' : 'hidden'
      }}
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div 
          className="fixed inset-0 bg-bluish-grey/20 transition-opacity backdrop-blur-sm"
          style={{ opacity: isOpen ? 1 : 0 }}
          onClick={onClose}
        />

        <div className={`relative bg-seashell rounded-xl w-full max-w-md mx-4 p-6 shadow-lg transform transition-all duration-300 ${isOpen ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
          <div className="flex justify-between items-center mb-6 border-b border-grey-goose pb-4">
            <h2 className="text-xl font-semibold text-hazel-green">
              Manage Ticket
            </h2>
            <button
              onClick={onClose}
              className="text-bluish-grey hover:text-hazel-green transition-colors p-1 rounded-full hover:bg-grey-goose/20"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="space-y-6">
            <div className="bg-white/80 p-5 rounded-xl shadow-sm space-y-4 border border-grey-goose/30">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-bluish-grey">Status</span>
                <span className={`px-3 py-1.5 rounded-full text-sm font-medium ${getStatusColor(reservation.status)}`}>
                  {reservation.status}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-bluish-grey">Reference</span>
                <span className="text-sm font-semibold text-hazel-green">{reservation.ticketReference}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-bluish-grey">Name</span>
                <span className="text-sm font-medium text-sage">{reservation.name} {reservation.surname}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-bluish-grey">Phone</span>
                <span className="text-sm font-medium text-sage">{reservation.phoneNumber}</span>
              </div>
            </div>

            <div className="flex flex-col gap-3 mt-6">
              {reservation.status === 'PENDING_CHECKIN' && (
                <button
                  onClick={handleVerify}
                  disabled={loading}
                  className="w-full px-4 py-3 bg-tealish-blue text-bluish-grey rounded-lg hover:bg-tealish-blue/90 transition-colors disabled:opacity-50 font-medium shadow-sm border border-grey-goose/30"
                >
                  Verify Ticket
                </button>
              )}

              {(reservation.status === 'VERIFIED' || showCheckInButton) && (
                <button
                  onClick={handleCheckIn}
                  disabled={loading}
                  className="w-full px-4 py-3 bg-hazel-green text-white rounded-lg hover:bg-hazel-green/90 transition-colors disabled:opacity-50 font-medium shadow-sm"
                >
                  Check In
                </button>
              )}

              {reservation.status === 'CHECKED_IN' && (
                <button
                  onClick={handleCheckOut}
                  disabled={loading}
                  className="w-full px-4 py-3 bg-heather text-white rounded-lg hover:bg-heather/90 transition-colors disabled:opacity-50 font-medium shadow-sm"
                >
                  Check Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketManagementModal;
