import React, { useState, useEffect } from 'react';
import { X, MessageSquareWarning } from 'lucide-react';
import { toast } from 'react-toastify';
import { differenceInCalendarDays } from 'date-fns';

import PaymentManagement from './tabs/PaymentManagement.tsx';
import CleaningTasks from './tabs/CleaningTasks.tsx';
import ExtraCharges from './tabs/ExtraCharges.tsx';
import FinalReport from './tabs/FinalReport.tsx';
import { getLatestCleaningReport } from '../../../../api/cleaning.js';
import { createCheckout, updateExtraCharges } from '../../../../api/checkout';
import { getUnpaidRoomServiceOrders } from '../../../../api/orders';
import { createInvoice } from '../../../../api/invoiceSale.js';
import { createTransaction } from '../../../../api/accounting.js';
import { adjustAccountValue } from '../../../../api/account.js';
import api from '../../../../api/api.js';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  fromDate: string;
  toDate: string;
  name: string;
  surname: string;
  totalPrice: number;
  paidPrice: number;
  cleaningReport?: {
    id: string;
    roomChecklist: any[];
    minibarChecklist: any[];
    inventoryChecklist: any[];
    cleaningDate: string;
    roomCondition: string;
    cleanlinessLevel: string;
    issuesFound: string[];
    issuesDescription: string;
    additionalNotes: string;
    cleaner: {
      name: string;
      surname: string;
    };
  };
}

interface RoomServiceCharge {
  id: string;
  totalAmount: number;
  orderItems: any[];
  createdAt: string;
}

const tabs = [
  { id: 'payment', label: 'Payment Management' },
  { id: 'cleaning', label: 'Cleaning Tasks' },
  { id: 'charges', label: 'Extra Charges' },
  { id: 'report', label: 'Final Report' },
];

export default function CheckoutModal({
  isOpen,
  onClose,
  roomNumber,
  fromDate,
  toDate,
  name,
  surname,
  totalPrice,
  paidPrice,
  cleaningReport,
}: CheckoutModalProps) {
  const [activeTab, setActiveTab] = useState('payment');
  const [totalExtraCharges, setTotalExtraCharges] = useState(0);
  const [extraCharges, setExtraCharges] = useState(0);
  const [customCharges, setCustomCharges] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [roomServiceCharges, setRoomServiceCharges] = useState<RoomServiceCharge[]>([]);
  const [cleaningReportData, setCleaningReportData] = useState(null);
  const [isCheckoutCompleted, setIsCheckoutCompleted] = useState(false);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [existingCheckout, setExistingCheckout] = useState(null);
  const [finalTotalAmount, setFinalTotalAmount] = useState(totalPrice);
  const [nightlyRate, setNightlyRate] = useState(0);
  const [numberOfNights, setNumberOfNights] = useState(0);

  useEffect(() => {
    console.log("CheckOutModal - Received cleaning report-----------------:", cleaningReport);
  }, [cleaningReport]);

  useEffect(() => {
    const checkExistingCheckout = async () => {
      try {
        const response = await api.get(`/api/checkout/room/${roomNumber}`);
        if (response.data.message) {
          setExistingCheckout(response.data.message);
          setIsCheckoutCompleted(true);
        }
      } catch (error) {
        console.error('Error checking existing checkout:', error);
      }
    };

    if (roomNumber) {
      checkExistingCheckout();
    }
  }, [roomNumber]);

  useEffect(() => {
    if (cleaningReport) {
      const transformedReport = {
        ...cleaningReport,
        minibarChecklist: cleaningReport.minibarChecklist.map(item => ({
          ...item,
          quantity: 1,
          status: item.checked ? 'Available' : 'Missing'
        })) || [],
        inventoryChecklist: cleaningReport.inventoryChecklist.map(item => ({
          ...item,
          quantity: 1,
          status: item.checked ? 'Available' : 'Missing'
        })) || [],
        roomChecklist: cleaningReport.roomChecklist.map(category => ({
          ...category,
          items: category.items.map(item => ({
            ...item,
            status: item.checked ? 'Good' : 'Needs Attention'
          }))
        }))
      };

      console.log("Transformed cleaning report:", transformedReport);
      setCleaningReportData(transformedReport);
    }
  }, [cleaningReport]);

  useEffect(() => {
    const fetchInvoiceCount = async () => {
      try {
        const response = await api.get('/api/invoice-sale/count');
        setInvoiceCount(response.data.count);
      } catch (error) {
        console.error('Error fetching invoice count:', error);
      }
    };
    fetchInvoiceCount();
  }, []);

  useEffect(() => {
    const fetchRoomServiceCharges = async () => {
      try {
        console.log('Fetching room service charges for room:', roomNumber);
        const orders = await getUnpaidRoomServiceOrders(roomNumber);
        console.log('Received room service charges:', orders);
        
        // Filter orders to only include those within the stay period
        const validOrders = orders.filter(order => {
          const orderDate = new Date(order.createdAt);
          const stayStart = new Date(fromDate);
          const stayEnd = new Date(toDate);
          return orderDate >= stayStart && orderDate <= stayEnd;
        });

        setRoomServiceCharges(validOrders);
      } catch (error) {
        console.error('Error fetching room service charges:', error);
        toast.error('Failed to fetch room service charges. Please try again.');
        setRoomServiceCharges([]);
      }
    };

    if (roomNumber) {
      fetchRoomServiceCharges();
    }
  }, [roomNumber, fromDate, toDate]);

  useEffect(() => {
    const nights = differenceInCalendarDays(new Date(toDate), new Date(fromDate));
    setNumberOfNights(nights);
    const rate = totalPrice / nights;
    setNightlyRate(rate);
  }, [fromDate, toDate, totalPrice]);

  useEffect(() => {
    const roomTotal = nightlyRate * numberOfNights;
    const newTotal = roomTotal + totalExtraCharges;
    console.log('Calculating final total:', {
      nightlyRate,
      numberOfNights,
      roomTotal,
      totalExtraCharges,
      newTotal
    });
    setFinalTotalAmount(newTotal);
  }, [nightlyRate, numberOfNights, totalExtraCharges]);

  useEffect(() => {
    console.log("CheckOutModal - Received cleaning report:", cleaningReport);
  }, [cleaningReport]);

  useEffect(() => {
    console.log("CheckOutModal - Received props:", {
      roomNumber,
      fromDate,
      toDate,
      name,
      surname,
      totalPrice,
      paidPrice,
      cleaningReport
    });
  }, [roomNumber, fromDate, toDate, name, surname, totalPrice, paidPrice, cleaningReport]);

  const calculateTotalPrice = () => {
    const roomTotal = nightlyRate * numberOfNights;
    const extraChargesTotal = totalExtraCharges;
    const roomServiceTotal = roomServiceCharges.reduce((sum, charge) => sum + charge.totalAmount, 0);
    
    const total = roomTotal + extraChargesTotal + roomServiceTotal;
    console.log('Total price breakdown:', {
      roomTotal,
      extraChargesTotal,
      roomServiceTotal,
      total
    });
    return total;
  };

  const generateInvoiceNumber = () => {
    return `INV-Room-${String(invoiceCount + 1).padStart(3, '0')}`;
  };

  const calculateNightsStayed = () => {
    const checkIn = new Date(fromDate);
    const checkOut = new Date(toDate);
    const timeDiff = checkOut.getTime() - checkIn.getTime();
    const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return nights > 0 ? nights : 1; 
  };

  const calculateFinalRoomPrice = () => {
    const nightsStayed = calculateNightsStayed();
    const pricePerNight = totalPrice || 0; 
    return pricePerNight * nightsStayed;
  };

  const finalRoomPrice = calculateFinalRoomPrice();

  useEffect(() => {
    if (cleaningReport) {
      const minibarTotal = cleaningReport.minibarChecklist
        ?.filter((item: any) => !item.checked)
        .reduce((sum: number, item: any) => sum + ((item.quantity || 0) * 5), 0) || 0;

      const inventoryTotal = cleaningReport.inventoryChecklist
        ?.filter((item: any) => !item.checked)
        .reduce((sum: number, item: any) => sum + ((item.quantity || 0) * 10), 0) || 0;

      setTotalExtraCharges(minibarTotal + inventoryTotal);
    }
  }, [cleaningReport]);

  const handleChargesUpdate = (charges: number, customChargesList: any[]) => {
    setExtraCharges(charges);
    setCustomCharges(customChargesList);
    updateExtraCharges(roomNumber, {
      extraCharges: charges,
      customCharges: customChargesList
    }).catch(error => {
      console.error('Error saving charges:', error);
    });
  };

  const handleSubmit = async () => {
    if (isCheckoutCompleted) {
      toast.error('This room has already been checked out.');
      return;
    }

    setIsSubmitting(true);
    try {
      let clientId;
      try {
        const response = await api.post('/api/accounting/clients/check-or-create', {
          name: `${name} ${surname}`,
          phone: '',  
          email: '',  
          address: '' 
        });
        clientId = response.data.id;
      } catch (error) {
        console.error('Error checking or creating client:', error);
        toast.error('Failed to check or create client.');
        return;
      }

      const totalAmount = calculateTotalPrice();

      // Generate invoice number using current timestamp and room number
      const timestamp = new Date().getTime();
      const invoiceNumber = `INV-${roomNumber}-${timestamp}`;
      
      // Create invoice with generated number
      const invoiceData = {
        invoiceNumber,
        clientId,
        invoiceDate: new Date(),
        reference: invoiceNumber,
        terms: 'Payment due upon receipt',
        notes: `Hotel Room ${roomNumber} Checkout Invoice`,
        paymentMethod: paymentMethod || 'CASH',
        status: 'Completed',
        totalAmount,
        items: [
          {
            details: `Room ${roomNumber} Stay`,
            quantity: calculateNightsStayed(),
            rate: totalPrice,
            amount: calculateFinalRoomPrice(),
            discount: 0,
            tax: 0
          },
          ...(totalExtraCharges > 0 ? [{
            details: 'Extra Charges (Minibar & Inventory)',
            quantity: 1,
            rate: totalExtraCharges,
            amount: totalExtraCharges,
            discount: 0,
            tax: 0
          }] : []),
          ...roomServiceCharges.map(charge => ({
            details: 'Room Service Order',
            quantity: 1,
            rate: charge.totalAmount,
            amount: charge.totalAmount,
            discount: 0,
            tax: 0
          }))
        ]
      };

      // Create invoice first
      try {
        await createInvoice(invoiceData);
      } catch (error) {
        console.error('Error creating invoice:', error);
        toast.error('Failed to create invoice');
        return;
      }
      
      const transactionData = {
        acceptedBy: "Lake Side Hotel",
        amount: totalAmount,
        paymentType: paymentMethod || 'CASH',
        transactionType: "PAYMENT",
        clientId,
        accountId: 12, 
        description: `Room ${roomNumber} Checkout - ${name} ${surname}`,
        TransactionProduct: [
          {
            item: `Room ${roomNumber}`,
            type: 'Accommodation',
            unit: 'Night',
            quantity: calculateNightsStayed(),
            unitPrice: totalPrice,
            soldPrice: calculateFinalRoomPrice(),
          },
          ...(totalExtraCharges > 0 ? [{
            item: 'Extra Charges',
            type: 'Service',
            unit: 'Package',
            quantity: 1,
            unitPrice: totalExtraCharges,
            soldPrice: totalExtraCharges,
          }] : []),
          ...roomServiceCharges.map(charge => ({
            item: 'Room Service',
            type: 'Service',
            unit: 'Order',
            quantity: 1,
            unitPrice: charge.totalAmount,
            soldPrice: charge.totalAmount,
          }))
        ]
      };

      // Prepare checkout data with all required fields
      const checkoutData = {
        roomNumber,
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        guestName: `${name} ${surname}`,
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        paidAmount: parseFloat(totalAmount.toFixed(2)),
        extraCharges: parseFloat(extraCharges.toFixed(2)) || 0,
        customCharges: customCharges || [],
        paymentMethod: paymentMethod || 'CASH',
        paymentNote: paymentNote || '',
        cleaningReportId: cleaningReport?.id || null,
        completed: true
      };

      // Execute remaining operations
      try {
        await Promise.all([
          createTransaction(transactionData),
          adjustAccountValue(12, totalAmount), 
          createCheckout(checkoutData)
        ]);

        setIsCheckoutCompleted(true);
        toast.success('Checkout completed successfully!');
        onClose();
      } catch (error) {
        console.error('Error completing checkout process:', error);
        toast.error('Failed to complete checkout process');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      toast.error(error.response?.data?.message || 'Failed to complete checkout');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderTabContent = () => {
    const calculatedTotalAmount = nightlyRate * numberOfNights;
    
    switch (activeTab) {
      case 'payment':
        return (
          <PaymentManagement
            roomNumber={roomNumber}
            totalAmount={calculatedTotalAmount}
            paidAmount={paidPrice}
            extraCharges={totalExtraCharges}
            roomServiceCharges={roomServiceCharges}
            onPaymentMethodChange={setPaymentMethod}
            onPaymentNoteChange={setPaymentNote}
          />
        );
      case 'cleaning':
        return (
          <CleaningTasks
            roomNumber={roomNumber}
            cleaningReport={cleaningReportData}
          />
        );
      case 'charges':
        return (
          <ExtraCharges
            roomNumber={roomNumber}
            fromDate={fromDate}
            cleaningReport={cleaningReportData}
            roomServiceCharges={roomServiceCharges}
            onChargesUpdate={setTotalExtraCharges}
          />
        );
      case 'report':
        return (
          <FinalReport
            roomNumber={roomNumber}
            fromDate={fromDate}
            toDate={toDate}
            guestName={`${name} ${surname}`}
            cleaningReport={cleaningReportData}
            totalAmount={calculatedTotalAmount}
            paidAmount={paidPrice}
            extraCharges={totalExtraCharges}
            roomServiceCharges={roomServiceCharges}
            customCharges={customCharges}
          />
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  const today = new Date();
  const checkoutDate = new Date(toDate);
  const isCheckoutDate = today.toDateString() === checkoutDate.toDateString();

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white w-full max-w-4xl rounded-lg shadow-xl" onClick={(e) => e.stopPropagation()}>
      <div className="p-4 border-b border-grey-goose flex justify-between items-center bg-gradient-to-r from-hazel-green/10 to-transparent">
        <h2 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
          Checkout Process - Room {roomNumber}
          <span className="text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded">
            Checking Out
          </span>
        </h2>

        {/* Warning message container */}
        {!isCheckoutDate && (
          <div className="flex flex-row items-center gap-2 text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded ml-auto">
            <MessageSquareWarning size={20} className="animate-pulse" />
            <span>Today's Not The Checkout Date</span>
          </div>
        )}

        <button
          onClick={onClose}
          className="text-bluish-grey hover:text-hazel-green transition-colors"
        >
          <X size={24} />
        </button>
      </div>

        <div className="border-b border-grey-goose bg-seashell">
          <nav className="flex px-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-3 text-sm font-medium transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
                )}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6 max-h-[calc(100vh-12rem)] overflow-y-auto">
          {isCheckoutCompleted ? (
            <div className="p-6 text-center">
              <MessageSquareWarning className="mx-auto h-12 w-12 text-yellow-500" />
              <h3 className="mt-2 text-lg font-semibold text-gray-900">Checkout Already Processed</h3>
              <p className="mt-1 text-gray-500">
                This room has already been checked out. No further action is needed.
              </p>
              <button
                onClick={onClose}
                className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
              >
                Close
              </button>
            </div>
          ) : (
            renderTabContent()
          )}
        </div>

        <div className="flex justify-between my-2 py-4 px-6 border-t border-grey-goose">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-bluish-grey hover:text-watermelon transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isSubmitting || isCheckoutCompleted}
            className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Processing...' : isCheckoutCompleted ? 'Checkout Completed' : 'Complete Checkout'}
          </button>
        </div>
      </div>
    </div>
  );
}