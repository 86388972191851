import React from 'react';
import LoadingCircle from '../loading-circle';

export default function SubmitButton({ isSubmitting, width, title }) {
  return (
    <button
      type='submit'
      className={`bg-sage ${width} text-white rounded-md py-3 z-50 hover:bg-opacity-90 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed`}
      disabled={isSubmitting}
    >
      {isSubmitting ? <LoadingCircle /> : title || 'Save'}
    </button>
  );
}
