import React from 'react';
import { CreditCard, Receipt, AlertCircle } from 'lucide-react';

interface PaymentManagementProps {
  roomNumber: string;
  totalAmount: number;
  paidAmount: number;
  extraCharges: number;
  onPaymentMethodChange: (method: string) => void;
  onPaymentNoteChange: (note: string) => void;
}

export default function PaymentManagement({
  roomNumber,
  totalAmount,
  paidAmount,
  extraCharges,
  onPaymentMethodChange,
  onPaymentNoteChange
}: PaymentManagementProps) {
  const remainingBalance = (totalAmount + extraCharges) - paidAmount;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gradient-to-br from-hazel-green to-sage p-4 rounded-lg text-white">
          <h3 className="text-sm font-medium opacity-90">Total Amount</h3>
          <p className="text-2xl font-bold">${(totalAmount + extraCharges).toFixed(2)}</p>
          <span className="text-xs opacity-75">
            (Room: ${totalAmount.toFixed(2)} + Extra: ${extraCharges.toFixed(2)})
          </span>
        </div>
        <div className="text-hazel-green bg-gradient-to-br from-bluish-grey to-tealish-blue p-4 rounded-lg">
          <h3 className="text-sm font-medium opacity-90">Paid Amount</h3>
          <p className="text-2xl font-bold">${paidAmount.toFixed(2)}</p>
        </div>
        <div className="text-hazel-green bg-gradient-to-br from-watermelon to-watermelon/70 p-4 rounded-lg">
          <h3 className="text-sm font-medium opacity-90">Remaining Balance</h3>
          <p className="text-2xl font-bold">${remainingBalance.toFixed(2)}</p>
        </div>
      </div>

      <div className="bg-seashell rounded-lg p-4">
        <h3 className="text-lg font-semibold text-hazel-green mb-2">Payment Details</h3>
        <div className="space-y-3"> 
          <div className="grid grid-cols-2 gap-3"> 
            <div>
              <label className="block text-xs font-medium text-bluish-grey mb-1"> 
                Payment Method
              </label>
              <select className="w-full px-2 py-1 rounded border border-grey-goose focus:ring-1 focus:ring-hazel-green text-sm" onChange={(e) => onPaymentMethodChange(e.target.value)}>
                <option value="card">Credit Card</option>
                <option value="cash">Cash</option>
                <option value="transfer">Bank Transfer</option>
              </select>
            </div>
            <div>
              <label className="block text-xs font-medium text-bluish-grey mb-1"> 
                Amount
              </label>
              <input
                type="number"
                defaultValue={remainingBalance}
                className="w-full px-2 py-1 rounded border border-grey-goose focus:ring-1 focus:ring-hazel-green text-sm"
              />
            </div>
          </div>

          <div>
            <label className="block text-xs font-medium text-bluish-grey mb-1"> 
              Payment Note
            </label>
            <textarea
              rows={2} 
              className="w-full px-2 py-1 rounded border border-grey-goose focus:ring-1 focus:ring-hazel-green text-sm" 
              placeholder="Add any payment-related notes here..."
              onChange={(e) => onPaymentNoteChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2 text-sm text-bluish-grey">
          <AlertCircle size={16} />
          <span>Payment will be processed for {roomNumber}</span>
        </div>
        <div className="space-x-3">
          <button className="px-4 py-2 text-sm bg-bluish-grey text-white rounded-md hover:bg-opacity-90 transition-colors inline-flex items-center gap-2">
            <Receipt size={16} />
            Generate Receipt
          </button>
          <button className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors inline-flex items-center gap-2">
            <CreditCard size={16} />
            Process Payment
          </button>
        </div>
      </div>
    </div>
  );
}