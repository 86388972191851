import React from 'react';

const StatisticCard = ({ 
  title, 
  value, 
  Icon, 
  bgColor = 'bg-seashell', 
  textColor = 'text-bluish-grey',
  isMainCard = false 
}) => {
  return (
    <div className={`
      ${bgColor} 
      p-6 
      rounded-xl 
      shadow-lg 
      hover:shadow-2xl 
      transition-all 
      duration-300 
      transform 
      hover:-translate-y-1 
      border 
      border-grey-goose
      ${isMainCard ? 'col-span-1' : ''}
    `}>
      <div className="flex justify-between items-start">
        <div className={textColor}>
          <h2 className={`${isMainCard ? 'text-xl' : 'text-lg'} font-medium opacity-90`}>
            {title}
          </h2>
          <p className={`
            ${isMainCard ? 'text-3xl' : 'text-2xl'} 
            font-bold 
            mt-2 
            ${textColor}
          `}>
            {value}
          </p>
        </div>
        <div className={`
          ${isMainCard ? 'p-4' : 'p-3'} 
          rounded-lg 
          ${bgColor} 
          bg-opacity-20
        `}>
          {Icon && <Icon 
            size={isMainCard ? 36 : 24} 
            className={textColor}
            strokeWidth={1.5}
          />}
        </div>
      </div>
      <div className="mt-4 pt-4 border-t border-grey-goose">
        <span className="text-sm text-bluish-grey">Updated just now</span>
      </div>
    </div>
  );
};

export default StatisticCard;
