import api from './api';

export const getRestaurants = () => api.get('/api/restaurant/restaurants');
// export const getRestaurants = () => api.get('/api/restaurant/restaurants?include=menus.dishes,menus.dishSections');

// export const getRestaurants = () => api.get('/api/restaurants', {
//     params: { include: { menus: { include: { dishes: true } } } }
//   });
export const getRestaurantById = (id) => api.get(`/api/restaurant/restaurants/${id}`);
export const createRestaurant = (values) => api.post('/api/restaurant/restaurants', values);
export const updateRestaurant = (id, values) => api.patch(`/api/restaurant/restaurants/${id}`, values);
export const deleteRestaurant = (id) => api.delete(`/api/restaurant/restaurants/${id}`);

export const getTables = () => api.get('/api/restaurant/tables');
export const getTableById = (id) => api.get(`/api/restaurant/tables/${id}`);
export const createTable = (values) => api.post('/api/restaurant/tables', values);
export const updateTable = (id, values) => api.patch(`/api/restaurant/tables/${id}`, values);
export const deleteTable = (id) => api.delete(`/api/restaurant/tables/${id}`);

export const mergeTables = (tableId1, tableId2) => {
    return api.post('/api/restaurant/tables/merge', { tableId1, tableId2 });
};

export const unmergeTable = (mergedTableId) => api.post('/api/restaurant/tables/unmerge', { mergedTableId });

export const unmergeAllTables = () => api.post('/api/restaurant/tables/unmerge-all');

export const getEnvironments = () => api.get('/api/restaurant/environments');
export const getEnvironmentById = (id) => api.get(`/api/restaurant/environments/${id}`);
export const createEnvironment = (values) => api.post('/api/restaurant/environments', values);
export const updateEnvironment = (id, values) => api.patch(`/api/restaurant/environments/${id}`, values);
export const deleteEnvironment = (id) => api.delete(`/api/restaurant/environments/${id}`);

export const getReservations = () => api.get('/api/restaurant/table-reservations');
export const getReservationById = (id) => api.get(`/api/restaurant/table-reservations/${id}`);
export const createReservation = (values) => api.post('/api/restaurant/table-reservations', values);
export const updateReservation = (id, values) => api.patch(`/api/restaurant/table-reservations/${id}`, values);
export const deleteReservation = (id) => api.delete(`/api/restaurant/table-reservations/${id}`);

export const getTablesByEnvironment = (enviromentId) => api.get(`/api/restaurant/tables-by-environment/${enviromentId}`);

export const checkTableAvailability = (tableId, date) => 
  api.get(`/api/restaurant/check-table-availability/${tableId}?date=${date}`);
