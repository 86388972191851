import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BookOpen, Mail, Send, X, Calendar, CheckCircle2, ArrowRight, Download } from 'lucide-react';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { GITHUB_CONFIG } from '../config/github.ts';
import '../styles/scrollbar.css';

interface Release {
  version: string;
  date: string;
  type: string;
  changes: string[];
  downloadUrl: string;
  size: string;
}

interface ReleaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  version: string;
  isElectron?: boolean;
}

const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.95, y: 10 },
  visible: { 
    opacity: 1, 
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.4,
      bounce: 0.15
    }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    y: 10,
    transition: {
      duration: 0.2
    }
  }
};

const tabVariants = {
  inactive: { opacity: 0.7, y: 2 },
  active: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20
    }
  }
};

const getStaticReleases = (version: string, isElectron: boolean) => {
  if (isElectron) {
    return [
      {
        version: version || '0.0.0',
        date: '11-11-2024',
        type: 'BETA Version Release',
        changes: [
          'Initial release of the Lake Side Hotel Management System Web App',
          'Integrated hotel, restaurant, administration and pool management modules',
          'Added secure login system with role-based access',
          'Implemented all the core functionalities requested, might have minor problems',
          'Desktop APP initialised'
        ],
      }
    ];
  }

  return [
    {
      version: '0.1.0',
      date: '11-11-2024',
      type: 'BETA Release',
      changes: [
        'Initial release of the Lake Side Hotel Management System Web App',
        'Integrated hotel, restaurant, administration and pool management modules',
        'Added secure login system with role-based access',
        'Implemented all the core functionalities requested, might have minor problems',
      ],
    }
  ];
};

export function ReleaseModal({ isOpen, onClose, version, isElectron = false }: ReleaseModalProps) {
  const [activeTab, setActiveTab] = useState('releases');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [githubReleases, setGithubReleases] = useState<Release[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const [isSending, setIsSending] = useState(false);

  // Get static releases for the releases tab
  const staticReleases = getStaticReleases(version, isElectron);

  useEffect(() => {
    const fetchGithubReleases = async () => {
      if (!isOpen) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const response = await axios.get(
          `https://api.github.com/repos/${GITHUB_CONFIG.REPO}/releases`,
          {
            headers: {
              'Authorization': `token ${GITHUB_CONFIG.ACCESS_TOKEN}`,
              'Accept': 'application/vnd.github.v3+json'
            }
          }
        );

        const releases = response.data.map((release: any) => {
          const exeAsset = release.assets.find((asset: any) => 
            asset.name.endsWith('.exe') || asset.name.includes('setup')
          );

          return {
            version: release.tag_name.replace('v', ''),
            date: formatDate(release.published_at),
            type: release.prerelease ? 'BETA Release' : 'Stable Release',
            changes: ['Click download to get the latest version'],
            downloadUrl: exeAsset ? exeAsset.browser_download_url : null,
            size: exeAsset ? formatBytes(exeAsset.size) : 'Size unknown'
          };
        });

        setGithubReleases(releases);
      } catch (err) {
        console.error('Error fetching releases:', err);
        setError('Failed to fetch releases. Please try again later.');
        setGithubReleases([]);
      } finally {
        setLoading(false);
      }
    };

    fetchGithubReleases();
  }, [isOpen]);

  const handleDownload = async (downloadUrl: string) => {
    if (!downloadUrl) {
      alert('Download URL not available');
      return;
    }

    setDownloading(true);
    try {
      window.open(downloadUrl, '_blank');
    } catch (error) {
      console.error('Error downloading:', error);
      alert('Failed to start download. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);

    try {
      await emailjs.send(
        'service_7zmlx4n', // Get from EmailJS
        'template_lijmvp8', // Get from EmailJS
        {
          from_email: email,
          message: message,
          to_email: 'enisgjocaj1@hotmail.com', // Your Gmail address
        },
        'MIw8IdqpUKfYdiIDU' // Get from EmailJS
      );

      alert('Message sent successfully!');
      setEmail('');
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/40 backdrop-blur-sm z-[999]"
          />

          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="fixed left-[50%] top-[50%] z-[1000] w-[98vw] max-w-[1400px] max-h-[85vh] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white shadow-xl overflow-hidden border border-grey-goose"
          >
            <motion.div 
              className="flex items-center justify-between px-6 py-4 border-b border-grey-goose bg-seashell sticky top-0 z-[5]"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <div className="flex items-center gap-4">
                <div className="p-2.5 bg-hazel-green/10 rounded-xl">
                  <BookOpen className="w-6 h-6 text-hazel-green" />
                </div>
                <div>
                  <h2 className="text-xl font-semibold text-hazel-green">
                    Release Notes
                  </h2>
                  <p className="text-bluish-grey text-sm mt-0.5">
                    Version {version || '1.0.1'}
                  </p>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 rounded-full hover:bg-grey-goose/20 transition-colors"
              >
                <X className="h-5 w-5 text-bluish-grey" />
              </button>
            </motion.div>

            <div className="border-b border-grey-goose bg-seashell">
              <div className="flex gap-8 px-6">
                {['releases', 'desktop', 'contact'].map((tab) => (
                  <motion.button
                    key={tab}
                    variants={tabVariants}
                    initial="inactive"
                    animate={activeTab === tab ? "active" : "inactive"}
                    onClick={() => setActiveTab(tab)}
                    className={`py-3 px-1 relative ${
                      activeTab === tab 
                        ? 'text-hazel-green font-medium' 
                        : 'text-bluish-grey hover:text-sage'
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    {activeTab === tab && (
                      <motion.div
                        layoutId="activeTab"
                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green"
                        initial={false}
                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                      />
                    )}
                  </motion.button>
                ))}
              </div>
            </div>

            <div className="p-6 h-[calc(85vh-140px)] overflow-auto bg-white">
              <AnimatePresence mode="wait">
                {activeTab === 'releases' ? (
                  <motion.div
                    key="releases"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="grid grid-cols-1 md:grid-cols-2 gap-5 max-w-[1200px] mx-auto"
                  >
                    {staticReleases.map((release, index) => (
                      <motion.div
                        key={release.version}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="group relative bg-seashell rounded-lg p-4 border border-grey-goose/50 hover:border-hazel-green/30 hover:shadow-md transition-all duration-300"
                      >
                        <div className="absolute inset-0 bg-gradient-to-b from-hazel-green/5 to-transparent opacity-0 group-hover:opacity-100 rounded-lg transition-opacity duration-300" />
                        <div className="relative">
                          <div className="flex items-center justify-between mb-3">
                            <h3 className="text-base font-semibold text-hazel-green">
                              v{release.version}
                            </h3>
                            <span className="text-xs px-2.5 py-0.5 bg-hazel-green/10 text-hazel-green rounded-full font-medium">
                              {release.type}
                            </span>
                          </div>
                          <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-1.5 text-xs text-bluish-grey">
                              <Calendar className="w-3.5 h-3.5" />
                              {release.date}
                            </div>
                          </div>
                          <ul className="space-y-2">
                            {release.changes.map((change, i) => (
                              <motion.li
                                key={i}
                                initial={{ opacity: 0, x: -5 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: (index * 0.1) + (i * 0.05) }}
                                className="flex items-start gap-2 group/item"
                              >
                                <CheckCircle2 className="w-4 h-4 text-sage mt-0.5 flex-shrink-0 group-hover/item:text-hazel-green transition-colors duration-200" />
                                <span className="text-sm text-bluish-grey group-hover/item:text-sage transition-colors duration-200">
                                  {change}
                                </span>
                              </motion.li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    ))}
                  </motion.div>
                ) : activeTab === 'desktop' ? (
                  <motion.div
                    key="desktop"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="max-w-2xl mx-auto px-4"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-semibold text-hazel-green mb-2">Get the Desktop App</h3>
                      <p className="text-bluish-grey text-lg">Download the Lake Side Hotel Management System desktop app for a more native experience.</p>
                    </div>
                    {loading ? (
                      <div className="text-center py-4">
                        <p className="text-bluish-grey">Loading available versions...</p>
                      </div>
                    ) : error ? (
                      <div className="text-center py-4">
                        <p className="text-red-500">{error}</p>
                      </div>
                    ) : githubReleases.length > 0 && (
                      <>
                        <button
                          onClick={() => handleDownload(githubReleases[0].downloadUrl)}
                          disabled={downloading}
                          className="w-full bg-hazel-green hover:bg-sage text-white py-4 px-6 rounded-lg text-base font-medium flex items-center justify-center gap-2 transition-all duration-200 disabled:opacity-50"
                        >
                          <Download className="w-5 h-5" />
                          {downloading ? 'Downloading...' : `Download Latest Version (${githubReleases[0].version})`}
                        </button>

                        {/* Version History Section */}
                        <div className="mt-12">
                          <h4 className="text-lg font-semibold text-hazel-green mb-4">Previous Versions</h4>
                          <div 
                            className="space-y-4 max-h-[300px] overflow-y-auto pr-2 custom-scrollbar"
                            style={{
                              scrollbarWidth: 'thin',
                              scrollbarColor: 'rgb(var(--color-hazel-green) / 0.3) transparent'
                            }}
                          >
                            {githubReleases.slice(1).map((release, index) => (
                              <motion.div
                                key={release.version}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                                className="bg-seashell rounded-lg p-4 border border-grey-goose/50 hover:border-hazel-green/30 transition-all duration-200"
                              >
                                <div className="flex items-center justify-between">
                                  <div>
                                    <div className="flex items-center gap-2">
                                      <h5 className="font-medium text-hazel-green">Version {release.version}</h5>
                                      <span className="text-xs px-2 py-0.5 bg-hazel-green/10 text-hazel-green rounded-full">
                                        {release.type}
                                      </span>
                                    </div>
                                    <div className="flex items-center gap-4 mt-1 text-sm text-bluish-grey">
                                      <div className="flex items-center gap-1">
                                        <Calendar className="w-4 h-4" />
                                        {release.date}
                                      </div>
                                      {release.size && (
                                        <div className="flex items-center gap-1">
                                          <Download className="w-4 h-4" />
                                          {release.size}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <button
                                    onClick={() => handleDownload(release.downloadUrl)}
                                    disabled={downloading}
                                    className="px-4 py-2 bg-white border border-hazel-green text-hazel-green rounded-lg text-sm hover:bg-hazel-green hover:text-white transition-all duration-200 disabled:opacity-50 flex items-center gap-2 whitespace-nowrap"
                                  >
                                    <Download className="w-4 h-4" />
                                    {downloading ? 'Downloading...' : 'Download'}
                                  </button>
                                </div>
                              </motion.div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </motion.div>
                ) : (
                  <motion.div
                    key="contact"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="max-w-2xl mx-auto px-4"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-semibold text-hazel-green mb-2">Get in Touch</h3>
                      <p className="text-bluish-grey text-lg">Have questions about our latest updates? We'd love to hear from you!</p>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-sage">
                          Email Address
                        </label>
                        <div className="relative">
                          <Mail className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-bluish-grey" />
                          <input
                            type="email"
                            placeholder="your@email.com"
                            className="w-full pl-12 pr-4 py-3 text-base text-bluish-grey rounded-lg border border-grey-goose bg-seashell focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all duration-200"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-sage">
                          Your Message
                        </label>
                        <textarea
                          placeholder="What would you like to tell us?"
                          className="w-full p-4 h-40 text-base text-bluish-grey rounded-lg border border-grey-goose bg-seashell focus:border-hazel-green focus:ring-2 focus:ring-hazel-green/20 transition-all duration-200 resize-none"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                      </div>

                      <motion.button
                        type="submit"
                        disabled={isSending}
                        whileHover={{ scale: isSending ? 1 : 1.01 }}
                        whileTap={{ scale: isSending ? 1 : 0.98 }}
                        className="w-full bg-hazel-green hover:bg-sage disabled:bg-gray-400 text-white py-4 px-6 rounded-lg text-base font-medium flex items-center justify-center gap-2 transition-all duration-200"
                      >
                        <Send className="w-5 h-5" />
                        {isSending ? 'Sending...' : 'Send Message'}
                      </motion.button>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}