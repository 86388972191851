import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getClients, deleteClient } from '../../../../../api/accounting';

import { useNavigate } from 'react-router-dom';

function ClientsList() {
  const [clientsList, setClientsList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // Fetch the list of clients from the API
  const getClientsList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getClients();
      setClientsList(response.data);  // Assuming the response contains the list of clients
      setTotalCount(response.data.length); // Assuming it returns total count
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, []);

  // Fetch clients on initial render
  useEffect(() => {
    getClientsList();
  }, [getClientsList]);

  // Handle client deletion
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteClient(id);
      setClientsList((prevState) => prevState.filter((client) => client.id !== id));
      setTotalCount(totalCount - 1);
    } catch (err) {
      setErrorMessage('Error deleting client');
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  const handleNameClick = (id) => {
    navigate(`/accounting/clients/details/${id}`); // Updated URL structure
  };

  return (
    <div className='p-10'>
      {/* Add Client Button */}
      <AddButton entity='clients' link='/accounting/clients/add-client' title='New Client' />

      {/* Clients Table */}
      <CustomTable
        rows={clientsList}
        columns={[
          {
            title: 'Name',
            renderValue: (item) => (
              <span onClick={() => handleNameClick(item.id)} className="text-hazel-green cursor-pointer">
                {item.name}
              </span>
            ),
          },
          {
            title: 'Email',
            renderValue: (item) => item.email
          },
          {
            title: 'Phone',
            renderValue: (item) => item.phone
          },
          {
            title: 'Address',
            renderValue: (item) => item.address
          },
          {
            title: 'Representative',
            renderValue: (item) => item.representative
          },
          {
            title: 'UBIN',
            renderValue: (item) => item.ubin
          }
        ]}
        entity='clients'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/clients'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default ClientsList;
